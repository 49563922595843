import { IMutationErrorType } from 'types/httpStatus.enum'

export const legalErrors: IMutationErrorType = {
  create: {
    u409: 'Dokument o danej sygnaturze już istnieje.',
  },
  update: {
    u406: 'Dokument o danej sygnaturze nie istnieje.',
    u409: 'Dokument o danej sygnaturze już istnieje.',
  },
}
