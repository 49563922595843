import { EyeIcon, PencilIcon } from '@heroicons/react/20/solid'
import { Button } from 'components/Atoms/Button'
import { Checkbox } from 'components/Atoms/Checkbox'
import { Typography } from 'components/Atoms/Typography'
import { Table } from 'components/Atoms/Table'
import { useEffect, useMemo, useState } from 'react'
import { Pagination } from 'components/Atoms/Pagination'
import { useNavigate } from 'react-router-dom'
import { path } from 'constants/path'
import useAuth from 'hooks/useAuth'
import { getBranchesList, getBranchesListPrefiltred } from 'fetchers/branchFetchers'
import { queryKeys } from 'constants/queryKeys'
import { useQuery } from '@tanstack/react-query'
import { getStructureFunctionList, getStructureFunctionStatus } from 'fetchers/structureFetcher'
import { Spinner } from 'components/Atoms/Spinner'
import BranchFunctionSearch from 'components/Atoms/BranchFunctionSearch/BranchFunctionSearch'
import { getUnitPositionsList } from 'fetchers/unitPositionFetchers'
import { IMemberFunctionsFull, IStructureFunction } from 'types/structure'
import { Card } from 'components/Atoms/Card'
import { getTermFlex } from 'tools/dictionaryHelper'
import { useForm } from 'react-hook-form'
import { getRegionsList } from 'fetchers/regionFetchers'
import { getStatusObject } from 'tools/mapData'
import { LoadingSection } from 'components/Atoms/LoadingSection'
import ErrorRoleModal from 'components/Atoms/ErrorRoleModal'
import { HoverIcon } from 'components/Atoms/HoverIcon'
import { TableName } from 'components/Atoms/TableName'

interface PositionLimits {
  [key: string]: number
}

export const BranchFunctions = () => {
  const [selectedBranch, setSelectedBranch] = useState<string | undefined>(undefined)
  const [selectedRegion, setSelectedRegion] = useState<string | null>(null)
  const navigate = useNavigate()
  const { userToken, userLoaded, isAllAdmin, isAllOK } = useAuth()
  const { control, setValue } = useForm()

  // CHECK USER ROLE
  const isAdmin = isAllAdmin()
  const isRegion = isAllOK()

  // GET REGIONS AND BRANCHES
  const {
    data: branches,
    isLoading: branchesLoading,
    error: branchesError,
  } = useQuery({
    queryKey: [userToken, queryKeys.branchesList, userLoaded, selectedRegion],
    queryFn: () => {
      if (!userLoaded || (isAdmin && !selectedRegion)) return []
      if (!isAdmin) return getBranchesList(1, 1000)
      return getBranchesListPrefiltred(1, 1000, `&filter[region][]=${selectedRegion}`)
    },
  })

  const branchOptions = branches?.items
    ? branches?.items?.map((item: any) => ({ label: item.name, value: item.id }))
    : []

  const {
    data: regions,
    isLoading: regionsLoading,
    error: regionsError,
  } = useQuery({
    queryKey: [userToken, queryKeys.regionList, userLoaded],
    queryFn: () => {
      if (!userLoaded) return []
      return getRegionsList(1, 1000)
    },
  })

  const regionOptions = regions?.items
    ? regions?.items?.map((item: any) => ({ label: item.name, value: item.id }))
    : []
  // END GET REGIONS AND BRANCHES

  // GET POSITIONS
  const {
    data: positions,
    isLoading: positionsLoading,
    error: positionsError,
  } = useQuery({
    queryKey: [userToken, queryKeys.positionsList, userLoaded],
    queryFn: () => {
      if (!userLoaded) return []
      return getUnitPositionsList(1, 1000)
    },
  })

  const { data: functionStatus } = useQuery({
    queryKey: [userToken, queryKeys.structureFunctionsStatus, userLoaded, selectedBranch],
    queryFn: () => {
      if (!selectedBranch) return { items: [] }
      return getStructureFunctionStatus(selectedBranch)
    },
  })

  const statusList = functionStatus?.items ? functionStatus?.items : []
  const positionLimits: PositionLimits = getStatusObject(statusList)
  const positionList = positions?.items
    ? positions?.items?.filter((item: IStructureFunction) => item.unitType === 'branch')
    : []
  // END GET POSITIONS

  // GET FUNCTIONS
  const {
    data: functions,
    isLoading: functionsLoading,
    error,
  } = useQuery({
    queryKey: [userToken, queryKeys.structureFunctions, selectedBranch, selectedRegion],
    queryFn: () => {
      if (selectedBranch) {
        return getStructureFunctionList(selectedBranch)
      } else if (selectedRegion) {
        return getStructureFunctionList(selectedRegion)
      }
      return []
    },
  })

  const functionsFullList = functions?.items ? functions?.items : []
  const functionsList = functionsFullList.flatMap((item: IMemberFunctionsFull) =>
    item.unitPositions.map((position: any) => ({
      member: `${item.firstName} ${item.lastName}`,
      memberId: item.id,
      startedAt: position.startedAt,
      endedAt: position.endedAt,
      calculatedTermEndAt: position.calculatedTermEndAt,
      name: position.unitPosition.name,
      id: position.id,
    })),
  )
  // END GET FUNCTIONS

  const schemeColumns = useMemo(
    () => [
      {
        Header: 'Nazwa funkcji',
        accessor: 'name',
        Cell: (row: any) => <TableName name={row.row.original.name} />,
      },
      {
        Header: 'Przypisanych członków',
        accessor: 'count',
        Cell: ({ row }: any) => {
          return row.original.id ? positionLimits[row.original.id] : '-'
        },
      },
      {
        Header: 'Limit',
        accessor: 'maxNumber',
        Cell: ({ row }: any) => {
          return <p>{!row.original.maxNumber ? '-' : row.original.maxNumber}</p>
        },
      },
      {
        Header: 'Kadencja',
        accessor: 'lengthOfTermUnit',
        Cell: ({ row }: any) => {
          const { lengthOfTermUnit, lengthOfTerm } = row.original
          return <p>{getTermFlex(lengthOfTerm, lengthOfTermUnit)}</p>
        },
      },
    ],
    [positionList],
  )
  const dataColumns = useMemo(
    () => [
      // {
      //   Header: '',
      //   accessor: 'Selected',
      //   Cell: (row: any) => <Checkbox />,
      // },
      {
        Header: 'Nazwa funkcji',
        accessor: 'name',
        Cell: (row: any) => <TableName name={row.row.original.name} />,
      },
      {
        Header: 'Członek',
        accessor: 'member',
      },
      {
        Header: 'Kadencja od',
        accessor: 'startedAt',
      },
      {
        Header: 'Kadencja do',
        accessor: 'endedAt',
      },
      {
        Header: 'Akcje',
        accessor: 'action',
        Cell: ({ row }: any) => {
          const editPath = `${selectedBranch}/${row.original.memberId}/${row.original.id}`
          return (
            <div className='flex'>
              <HoverIcon
                iconName='PencilIcon'
                title='Edytuj'
                onClick={() => navigate(`/branch-functions/${editPath}/update`)}
              />
            </div>
          )
        },
      },
    ],
    [selectedBranch],
  )

  useEffect(() => {
    if (selectedRegion !== '') {
      setSelectedBranch(undefined)
      setValue('branchId', '')
    }
  }, [selectedRegion, setValue])
  if (!userLoaded) return <LoadingSection />
  if (!isAdmin && !isRegion) return <ErrorRoleModal />

  return (
    <div>
      <Typography size='xl' weight='semibold'>
        Funkcje pełnione w oddziale
      </Typography>
      <div className='mt-2 max-w-3xl'>
        <BranchFunctionSearch
          isAdmin={isAdmin}
          control={control}
          branchOptions={branchOptions}
          regionOptions={regionOptions}
          branchesLoading={branchesLoading}
          regionsLoading={regionsLoading}
          selectedBranch={selectedBranch}
          setSelectedBranch={setSelectedBranch}
          setSelectedRegion={setSelectedRegion}
        />
      </div>
      <div className='my-5 flex'>
        <div className='mr-5'>
          <Button
            disabled={!selectedBranch}
            label='Dodaj funkcję'
            onClick={() => navigate(`${selectedBranch}/create`)}
          />
        </div>
      </div>
      <Card label='Funkcje dostępne w oddziale'>
        {positionsLoading ? (
          <Spinner />
        ) : (
          <section>
            <Table columns={schemeColumns} data={positionList} />
          </section>
        )}
      </Card>
      <Card label='Funkcje członków oddziału'>
        <section>
          {selectedBranch ? (
            <>
              <Table columns={dataColumns} data={functionsList} />
              {/* <Pagination /> */}
            </>
          ) : (
            <p className='text-center'>Wybierz oddział</p>
          )}
        </section>
      </Card>
    </div>
  )
}
