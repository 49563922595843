import { Typography } from './Atoms/Typography'

type Props = {
  children: React.ReactNode
  title?: string
}

export const DashboardSection = ({ children, title }: Props) => {
  return (
    <section className='mt-4'>
      {title && (
        <Typography weight='semibold' className='mt-6 px-3 text-center text-2xl underline'>
          {title}
        </Typography>
      )}
      <div className='grid-cold-1 grid gap-x-4 sm:grid-cols-2'>{children}</div>
    </section>
  )
}

export default DashboardSection
