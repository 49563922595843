import { Controller } from 'react-hook-form'
import { IOption } from 'types/form'
import { ReactNode, useEffect, useRef, useState } from 'react'
import { TextField } from '../TextField'
import { Spinner } from '../Spinner'
import { Button } from '../Button'
import useSearchModule from 'hooks/useSearchModule'
import { set } from 'lodash'
import { LabelName } from '../LabelName'

type Props = {
  shortenName?: boolean
  control: any
  name: any
  label: string
  register: any
  options: IOption[]
  defaultOption?: IOption
  externalChangeHandler?: (e: string) => void
  externalChangeValue?: (e: string) => void
  isLoading?: boolean
  searchMore?: () => void
  children?: ReactNode
  searchName?: string
  filterBox?: boolean
}

export const SearchAndFetchInput = ({
  shortenName,
  control,
  name,
  label,
  register,
  options,
  defaultOption,
  externalChangeHandler,
  externalChangeValue,
  isLoading,
  searchMore,
  children,
  searchName,
  filterBox,
}: Props) => {
  const { onRefreshHandler, setSearchString, setPerPage, filters } = useSearchModule({
    archive: false,
    searchName,
  })
  const [searchInput, setSearchInput] = useState<string>('')
  const [currentList, setCurrentList] = useState<IOption[]>([])
  const [listFilter, setListFilter] = useState<string>('')
  const ulRef = useRef<HTMLUListElement>(null)
  const handleScroll = (e: React.UIEvent<HTMLUListElement>) => {
    const element = e.target as HTMLUListElement
    if (element.scrollHeight - element.scrollTop - 10 < element.clientHeight) {
      searchMore && searchMore()
    }
  }
  const forceReload = () => {
    setPerPage(10)
    onRefreshHandler()
  }
  const setFilter = (e: string) => setListFilter(e)
  const resetInput = () => {
    setFilter('')
    setSearchInput('')
  }
  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      if (filterBox) {
        e.preventDefault()
        setFilter(e.currentTarget.value)
      } else {
        e.preventDefault()
        forceReload()
      }
    }
  }
  const searchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value)
    if (filterBox) {
      e.preventDefault()
      setFilter(e.target.value)
    } else {
      e.preventDefault()
      setSearchString(e.target.value)
    }
  }

  useEffect(() => {
    if (options.length) {
      setCurrentList(options)
    }
  }, [options])

  useEffect(() => {
    setCurrentList(options)
  }, [filters])

  const filterListData = currentList.filter((e) =>
    e.label.toLowerCase().includes(listFilter.toLowerCase()),
  )
  return (
    <div className='grid gap-2'>
      <TextField
        onKeyDown={handleKeyPress}
        label={label}
        type='text'
        value={searchInput}
        placeholder='Wyszukaj...'
        onChange={searchHandler}
      />
      <div className='flex justify-between'>
        <div className='flex gap-2'>
          {filterBox ? (
            <Button className='my-2' type='button' onClick={resetInput} label='Wyczyść' />
          ) : (
            <Button className='my-2' type='button' onClick={forceReload} label='Wyszukaj' />
          )}
          {isLoading && (
            <div className='flex items-center justify-center'>
              <Spinner />
            </div>
          )}
        </div>
        {children}
      </div>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultOption || {}}
        render={({ field: { onChange, value } }) => (
          <ul
            ref={ulRef}
            onScroll={handleScroll}
            className='relative h-56 overflow-y-scroll rounded-md border-2 border-solid border-gray-300'
          >
            {isLoading && currentList.length === 0 ? (
              <div className='flex h-full items-center justify-center'>
                <Spinner />
              </div>
            ) : (
              <>
                {filterListData.map((e: any) => {
                  const rKey = `r-${e.value}-${name}`
                  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
                    onChange({ value: e })
                  }
                  return (
                    <li
                      key={rKey}
                      className='flex gap-2 whitespace-pre-line border-b border-solid border-gray-300 px-3 py-2 text-sm'
                    >
                      <label htmlFor={rKey} className='flex cursor-pointer items-center'>
                        <input
                          {...register(name)}
                          type='radio'
                          id={rKey}
                          name={name}
                          onChange={() => {
                            handleRadioChange(e.value)
                            externalChangeValue && externalChangeValue(e?.value)
                            externalChangeHandler && externalChangeHandler(e?.label)
                          }}
                          checked={value?.value === e.value}
                          value={e.value}
                        />
                        {shortenName ? (
                          <LabelName name={e.label} />
                        ) : (
                          <span className='pl-2'>{e.label}</span>
                        )}
                      </label>
                    </li>
                  )
                })}
                {isLoading && (
                  <div className='flex items-center justify-center py-5'>
                    <Spinner />
                  </div>
                )}
              </>
            )}
          </ul>
        )}
      />
    </div>
  )
}

export default SearchAndFetchInput
