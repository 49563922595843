import { Card } from 'components/Atoms/Card'
import { editMemberSchema } from 'schemas/memberSchema'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useMemo } from 'react'
import { LabelTypography } from 'components/Atoms/LabelTypography'
import { IContext, ISingleUserData } from 'types/userlist'
import { useAppSelector } from 'app/hooks'
import { flatten } from 'lodash'
import { roleById } from 'tools/dictionaryHelper'
import { Table } from 'components/Atoms/Table'
import { wipMessage } from 'tools/ToastHelpers'
import { LoadingSection } from 'components/Atoms/LoadingSection'
import { useNavigate } from 'react-router-dom'
import { TrashIcon } from '@heroicons/react/24/outline'

interface IProps {
  handleVisibleEditField: () => void
  deleteHandler: (id: string) => void
  data: ISingleUserData
}
interface IUnit {
  id: string
  name: string
}
interface IRole {
  id: string
  name: string
  unit: IUnit[]
}
interface IForm {
  fullName: string
  phoneNumber: string
  email: string
  role: IRole[]
}

const defaultValues = {
  fullName: '',
  phoneNumber: '',
  email: '',
  role: [],
}

const getUnitType = (role: string) => {
  if (role.includes('REGION')) return 'Okręg'
  if (role.includes('BRANCH')) return 'Oddział'
  if (role.includes('SECTION')) return 'Ognisko'
  return 'Zarząd Główny'
}

export const InfoUser: React.FC<IProps> = ({ deleteHandler, handleVisibleEditField, data }) => {
  const {
    handleSubmit,
    formState: { errors },
  } = useForm<IForm>({
    defaultValues,
    resolver: yupResolver(editMemberSchema),
  })
  const navigate = useNavigate()
  const rolesData = useAppSelector((state) => state.rolesReducer.roles)
  const fRoles = flatten(Object.values(rolesData))

  const onSubmit = (data: IForm) => {
    handleVisibleEditField()
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Rola',
        accessor: 'role',
      },
      {
        Header: 'Jednostka',
        accessor: 'unitType',
      },
      {
        Header: 'Jednostka',
        accessor: 'unit',
      },
    ],
    [],
  )
  const userRoles = data?.contexts?.map((e: IContext) => {
    return {
      role: roleById(e?.role, fRoles),
      unit: e?.unit?.name,
      unitType: getUnitType(e?.role),
      roleId: e?.role,
      unitId: e?.unit?.id,
    }
  })

  return data ? (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card
        label='Dane użytkownika'
        actionsButton={[
          // {
          //     label: 'Archiwizuj',
          //     handleClick: handleSubmit(onSubmit),
          // },
          {
            label: 'Edytuj',
            handleClick: handleVisibleEditField,
          },
        ]}
      >
        <div className='mb-5 grid grid-cols-4 gap-5'>
          <div>
            <LabelTypography label='Imię i nazwisko :' text={data?.displayName} />
          </div>
          <div>
            <LabelTypography label='Email' text={data?.email} />
          </div>
          <div>
            <LabelTypography label='Numer telefonu :' text={data?.phone} />
          </div>
        </div>
      </Card>
      <Card label='Przypisane role'>
        <Table columns={columns} data={userRoles} />
      </Card>
    </form>
  ) : (
    <LoadingSection />
  )
}
