import { useEffect, useRef, useState } from 'react'
import { HoverIcon } from '../Atoms/HoverIcon'
import { useQuery } from '@tanstack/react-query'
import useAuth from 'hooks/useAuth'
import { getMembersList } from 'fetchers/membersFetchers'
import { queryKeys } from 'constants/queryKeys'
import { errorQuery } from 'tools/queryHelpers'
import { IMemberGlobalSearch, IMemberListing } from 'types/member'
import GlobalSearchTable from 'components/Atoms/GlobalSearchTable/GlobalSearchTable'
let searchTimeout: NodeJS.Timeout | null = null

export const GlobalSearchBar = () => {
  const [searchValue, setSearchValue] = useState('')
  const [query, setQuery] = useState('')
  const [inputFocused, setInputFocused] = useState(false)
  const { userToken } = useAuth()
  const globalSearchRef = useRef<HTMLDivElement | null>(null)

  // FETCHER
  const filter = query ? `&filter[searchExtended]=${query}` : ''
  const { data, isLoading } = useQuery({
    queryKey: [userToken, queryKeys.membersList, 1, 4, filter],
    queryFn: () => {
      if (filter.length === 0) return null
      return getMembersList(1, 4, filter)
    },
    retry: errorQuery,
  })
  const list: IMemberGlobalSearch[] = data?.items
    ? data.items.map((item: IMemberListing) => {
        const branch = item?.branch?.name || ''
        const region = item?.region?.name ? ` (${item.region.name})` : ''
        return {
          id: item.id,
          name: `${item.firstName} ${item.lastName}`,
          unit: branch + region,
          card: item.card?.number || '',
          // date: item.date || '',
        }
      })
    : []
  const total = data?.pagination?.countFiltered || 0
  // END FETCHER

  const handleInputFocus = () => {
    setInputFocused(true)
  }

  const initSearch = () => {
    setQuery(searchValue)
    setInputFocused(true)
  }
  const clearHandler = () => {
    setQuery('')
    setSearchValue('')
  }

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      initSearch()
    } else {
      if (searchTimeout) {
        clearTimeout(searchTimeout)
      }
      searchTimeout = setTimeout(initSearch, 500) // 5000 milliseconds = 5 seconds
    }
  }

  const handleClickOutside = (event: any) => {
    const isOutsideInput = !event.target.closest('#gSearch')
    const isOutsideContainer =
      globalSearchRef.current && !globalSearchRef.current.contains(event.target)

    if (isOutsideInput && isOutsideContainer) {
      setInputFocused(false)
    }
  }

  const isLoadingData = query && isLoading
  const noResults = query && !isLoadingData && list.length === 0
  const findResults = query && !isLoadingData && list.length > 0

  useEffect(() => {
    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])
  return (
    <div ref={globalSearchRef}>
      <div className='relative flex rounded-md shadow-sm'>
        <input
          onKeyDown={handleKeyPress}
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          placeholder={'Wyszukaj...'}
          id={'gSearch'}
          name={'gS'}
          className={
            'block w-full rounded-s-md border-gray-300 text-sm shadow-sm focus:border-indigo-500 focus:ring-indigo-500'
          }
          aria-invalid='true'
          onFocus={handleInputFocus}
        />
        <button
          onClick={initSearch}
          className={
            'block rounded-e-md border border-s-0 border-gray-300 px-2 shadow-sm hover:bg-gray-200'
          }
        >
          <HoverIcon iconName={'MagnifyingGlassIcon'} title={'Wyszukaj'} noMargin />
        </button>
        {query && inputFocused && (
          <div className='absolute -bottom-1 min-w-full translate-y-full rounded-md border border-gray-300 bg-white px-4 py-2 shadow-sm'>
            {isLoadingData && <p>Wyszukiwanie...</p>}

            {noResults && <p>Brak wyników</p>}
            {findResults && (
              <GlobalSearchTable
                query={query}
                total={total}
                data={list}
                clearHandler={clearHandler}
              />
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default GlobalSearchBar
