import { publicBaseApiURL } from 'api'
import { endpoint } from 'constants/endpoints'

export const getPositionsList = async (page: number | 'all', count: number) => {
  const res = await publicBaseApiURL.get(
    `${endpoint.membership.position.list}?page=${page}&perPage=${count}`,
    {},
  )
  return res?.data
}

export const getPosition = async (id: string) => {
  const res = await publicBaseApiURL.get(endpoint.membership.position.show(id), {})
  return res?.data
}

export const createPosition = async (data: any) => {
  const res = await publicBaseApiURL.post(endpoint.membership.position.create, data)
  return res?.data
}

export const updatePosition = async (id: string, data: any) => {
  const res = await publicBaseApiURL.put(endpoint.membership.position.update(id), data)
  return res?.data
}
