import { Button } from 'components/Atoms/Button'
import { Card } from 'components/Atoms/Card'
import { Modal } from 'components/Atoms/Modal'
import { Typography } from 'components/Atoms/Typography'
import { TMigration } from 'types/member'

type Props = {
  sendHandler: (type: TMigration) => void
  closeModal: () => void
  isSending?: boolean
  type?: TMigration
}

export const BranchMigrationModal = ({ sendHandler, closeModal, isSending, type }: Props) => {
  const headerData = {
    accept: 'Zatwierdź zgłoszenie',
    reject: 'Odrzuć zgłoszenie',
    cancel: 'Wycofaj zgłoszenie',
  }
  const bodyData = {
    accept: 'Czy chcesz zatwierdzić zgłoszenie?',
    reject: 'Czy chcesz odrzucić zgłoszenie?',
    cancel: 'Czy chcesz wycofać zgłoszenie?',
  }
  const header = type ? headerData[type] : ''
  const body = type ? bodyData[type] : ''

  const sendDataHandler = () => {
    if (type) {
      sendHandler(type)
    }
  }
  return (
    <Modal isOpen={!!type} handleClose={closeModal}>
      <Card label={header}>
        <div className='bg-white px-4 py-2 text-center'>
          <Typography size='xl'>{body}</Typography>
          <div className='mt-4 flex justify-center gap-5'>
            <Button label={'Anuluj'} variant='secondary' onClick={closeModal} />
            <Button disabled={isSending} label={'Potwierdź'} onClick={sendDataHandler} />
          </div>
        </div>
      </Card>
    </Modal>
  )
}

export default BranchMigrationModal
