import React, { useState } from 'react'
import { Card } from 'components/Atoms/Card'
import { Button } from 'components/Atoms/Button'
import { Table } from 'components/Atoms/Table'
import { JobElement } from 'types/declaration'
import MemberEmploymentModal from './MemberEmploymentModal'
import { HoverIcon } from 'components/Atoms/HoverIcon'
import useAuth from 'hooks/useAuth'
import MembershipEducationSection from './MembershipEducationSection'
import { IMemberGeneral } from 'types/member'

type Props = {
  initJobs?: JobElement[]
  isSending?: boolean
  educationUpdateData?: IMemberGeneral
  employmentModalData?: JobElement
  refetch: () => void
  setEmploymentModalData: React.Dispatch<React.SetStateAction<JobElement | undefined>>
  submitHandler: (data: JobElement) => void
}

export const MemberEmployment = ({
  refetch,
  initJobs = [],
  isSending,
  educationUpdateData,
  employmentModalData,
  setEmploymentModalData,
  submitHandler,
}: Props) => {
  const { isAllAdmin, isAllOD } = useAuth()
  const isEditAllow = isAllAdmin() || isAllOD()

  const defaultOption = {
    position: {
      label: '',
      value: '',
    },
    institution: {
      label: '',
      value: '',
    },
    subject: {
      label: '',
      value: '',
    },
    isMain: false,
  }
  const openModal = (data: JobElement) => setEmploymentModalData(data)
  const closeModal = () => setEmploymentModalData(undefined)
  const columns = React.useMemo(
    () => [
      {
        Header: 'Miejsce zatrudnienia',
        accessor: 'institution',
        Cell: ({ row }: any) => row.original?.institution?.label,
      },
      {
        Header: 'Stanowisko',
        accessor: 'position',
        Cell: ({ row }: any) => row.original?.position?.label,
      },
      {
        Header: 'Przedmiot',
        accessor: 'subject',
        Cell: ({ row }: any) => row.original?.subject?.label || '-',
      },
      {
        Header: 'Akcje',
        accessor: 'action',
        Cell: ({ row }: any) => (
          <div className='flex'>
            {isEditAllow ? (
              <HoverIcon
                iconName='PencilIcon'
                title='Edytuj'
                onClick={() => openModal(row.original)}
              />
            ) : (
              <p>-</p>
            )}
          </div>
        ),
      },
    ],
    [initJobs, isEditAllow],
  )

  return (
    <Card label='Edukacja i zatrudnienie'>
      <MembershipEducationSection
        refetch={refetch}
        isEditAllow={isEditAllow}
        initData={educationUpdateData}
      />
      <Table maxColumnWidth='300px' columns={columns} data={initJobs} />
      {isEditAllow && (
        <div className='mt-5 flex justify-end'>
          <Button label='Dodaj' onClick={() => openModal(defaultOption)} />
        </div>
      )}
      {employmentModalData && (
        <MemberEmploymentModal
          job={employmentModalData}
          isOpen={!!employmentModalData}
          isSending={isSending}
          submitHandler={submitHandler}
          closeModal={closeModal}
        />
      )}
    </Card>
  )
}
