import { CopyMemberForm } from 'components/Members/CopyMemberForm'
import React from 'react'

// type Props = {

// }

const CopyMember = (): JSX.Element => {
  return <CopyMemberForm />
}

export default CopyMember
