import { IMutationErrorType } from 'types/httpStatus.enum'

export const sectionErrors: IMutationErrorType = {
  archive: {
    u404: 'Sekcja nie istnieje.',
    u422: 'Sekcja ma aktywnych członków.',
  },
  createClub: {
    u409: 'Do danego oddziału można przypisać tylko jedno ognisko.',
  },
  createGroup: {
    u409: 'Sekcja już istnieje.',
  },
  clubMerge: {
    u404: 'Ognisko nie istnieje.',
  },
  update: {
    u404: 'Sekcja nie istnieje.',
  },
}
