import { Controller } from 'react-hook-form'
import { Select } from '../Select'
import { IOption } from 'types/form'
import { types } from 'util'

interface IClean {
  message?: string
  type?: any
  types?: any
  shouldFocus?: boolean
}
type IChange = (value: string | number) => void

type Props = {
  defaultOption?: IOption
  name: string
  label: string
  control: any
  options: IOption[]
  error?: string
  setError: (type: any, error: IClean) => void
  externalHandler?: any
}

export const ControllerGroup = ({
  externalHandler,
  name,
  label,
  control,
  options,
  error,
  setError,
  defaultOption,
}: Props) => {
  const selectHandler = (value: string | number, onChange: IChange) => {
    onChange(value)
    externalHandler && externalHandler(value)
    setError(name, { message: '' })
  }
  return (
    <div>
      <p className='mb-1 block text-sm font-medium text-gray-700'>{label}</p>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange } }) => (
          <Select
            defaultOption={defaultOption}
            handleSelect={({ value }) => selectHandler(value, onChange)}
            options={options}
            selectLabel='---'
            error={error}
            withEmpty
          />
        )}
      />
      <p className='mt-2 text-sm text-red-600'>{error}</p>
    </div>
  )
}

export default ControllerGroup
