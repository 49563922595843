import { secureBaseApiURL } from 'api'
import { endpoint } from 'constants/endpoints'

export const getMemberExportDictionary = async () => {
  const res = await secureBaseApiURL.get(endpoint.export.member.exportFieldsDict)
  return res?.data
}

export const getMemberExportList = async (page: number, count: number, filters?: string) => {
  const res = await secureBaseApiURL.get(
    endpoint.export.member.exportList(page, count, filters ? filters : ''),
  )
  return res?.data
}

export const createExport = async (data: any) => {
  const res = await secureBaseApiURL.post(endpoint.export.member.createExport, data)
  return res?.data
}

export const getExportSingle = async (id: string) => {
  const res = await secureBaseApiURL.get(endpoint.export.member.exportSingleDetails(id))
  return res?.data
}

export const getExportPassword = async (id: string) => {
  const res = await secureBaseApiURL.get(endpoint.export.exportPassword(id))
  return res?.data
}

export const getExportFile = async (id: string, filename: string) => {
  const res = await secureBaseApiURL.get(endpoint.export.exportFile(id), {
    responseType: 'arraybuffer',
  })
  const blob = new Blob([res.data], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  })
  const url = window.URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.href = url
  a.download = `${filename}.zip`
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
  window.URL.revokeObjectURL(url)
}
