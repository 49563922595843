import { Card } from 'components/Atoms/Card'
import { LabelTypography } from 'components/Atoms/LabelTypography'
import { useNavigate } from 'react-router-dom'
import { IListElement } from 'types/form'
import { ISectionClub } from 'types/section'

type Props = {
  data: ISectionClub
  showModalHandler: (id: IListElement) => void
  allowArchive: boolean
}

export const SectionBaseInfo = ({ data, allowArchive, showModalHandler }: Props) => {
  const navigate = useNavigate()
  const institutions =
    data?.institutions?.length > 0 ? data.institutions.map((item: any) => item.name) : ['-']

  const subtypePath = data?.subtype === 'club' ? 'club' : 'group'
  const showSecondary = data?.contact?.phoneSecondary || data?.contact?.emailSecondary
  const actionButtons = !data?.archived
    ? [
        {
          label: 'Archiwizuj',
          handleClick: () => showModalHandler(data),
          disabled: allowArchive ? false : true,
        },
        {
          label: 'Edytuj',
          handleClick: () => navigate(`/section/${subtypePath}/${data.id}/update`),
        },
      ]
    : []

  return (
    <Card label='Podgląd szczegółów ogniska' actionsButton={actionButtons}>
      <div className='grid grid-cols-4'>
        <LabelTypography label='Nazwa ogniska' text={data?.name} />
        <LabelTypography label='Telefon' text={data?.contact?.phone} />
        <LabelTypography label='E-mail kontaktowy' text={data?.contact?.email} />
      </div>
      {showSecondary && (
        <div className='my-5 grid grid-cols-4'>
          {data?.contact?.phoneSecondary && (
            <LabelTypography label='Telefon dodatkowy' text={data?.contact?.phoneSecondary} />
          )}
          {data?.contact?.emailSecondary && (
            <LabelTypography label='E-mail dodatkowy' text={data?.contact?.emailSecondary} />
          )}
        </div>
      )}
      <div className='my-5 grid grid-cols-4'>
        <LabelTypography label='Data rozpoczęcia kadencji' text='2020-04-05' />
        <LabelTypography label='Data zakończenia kadencji' text='2022-04-05' />
        <LabelTypography label='Nr uchwały' text={data?.document?.name} />
      </div>

      <LabelTypography label='Powiązane placówki' text={institutions} />
    </Card>
  )
}

export default SectionBaseInfo
