import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { branchErrors } from 'error-data/branch'
import { postArchiveBranch } from 'fetchers/branchFetchers'
import useArchive from 'hooks/useArchive'
import { useNavigate } from 'react-router-dom'
import { successToast } from 'tools/ToastHelpers'
import { mutationErrorHandler } from 'tools/errorHandler'

type Props = {
  refetch?: () => Promise<any>
  navigatePath?: string
}

export const useArchiveBranch = ({ refetch, navigatePath }: Props) => {
  const navigate = useNavigate()

  const mutation = useMutation({
    mutationFn: (id: string) => postArchiveBranch(id),
    onSuccess: () => {
      if (refetch) refetch()
      if (navigatePath) navigate(navigatePath)
      successToast('Oddział został zarchiwizowany')
    },
    onError: (error: AxiosError) => {
      console.error(error)
      mutationErrorHandler(error, branchErrors.archive, 'Nie udało się zarchiwizować oddziału')
    },
  })

  const archiveHandler = async (id: string) => {
    try {
      mutation.mutate(id)
    } catch (error) {
      console.error(error)
      mutationErrorHandler(null, branchErrors.archive, 'Nie udało się zarchiwizować oddziału')
    }
  }

  const { showModalHandler, ArchiveModal } = useArchive({
    headerLabel: 'Archiwizuj oddział',
    executeArchive: archiveHandler,
  })

  return {
    showModalHandler,
    ArchiveModal,
  }
}
