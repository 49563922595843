import { IMutationErrorType } from 'types/httpStatus.enum'

export const declarationErrors: IMutationErrorType = {
  accept: {
    u422: 'Nieprawidłowe dane. Deklaracje zawierają błędy.',
  },
  reject: {
    u422: 'Jednej ze wskazanych deklaracji nie można odrzucić.',
  },
}
