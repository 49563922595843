import { Button } from 'components/Atoms/Button'
import { Modal } from 'components/Atoms/Modal'
import { Typography } from 'components/Atoms/Typography'
import { queryKeys } from 'constants/queryKeys'
import { getSectionsListPrefiltred } from 'fetchers/sectionFetchers'
import useAuth from 'hooks/useAuth'
import { useState } from 'react'
import { setupSearchFilters, setupSearchMultiFilters } from 'tools/queryHelpers'
import { useQuery } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'
import SearchAndFetchInput from 'components/Atoms/SearchAndFetchInput/SearchAndFetchInput'
import useSearch from 'hooks/useSearch'
import { TextareaField } from 'components/Atoms/TextareaField'
import { errorRequires } from 'tools/ToastHelpers'
import { yupResolver } from '@hookform/resolvers/yup'
import { moveSectionSchema } from 'schemas/unitChangeSchema'
import { Card } from 'components/Atoms/Card'

type Props = {
  isOpen: boolean
  isMove?: boolean
  branchId: string
  closeHandler: () => void
  sendHandler: (id: string, comment?: string) => void
}

export const MemberSectionAddModal = ({
  isOpen,
  isMove,
  branchId,
  closeHandler,
  sendHandler,
}: Props) => {
  const [selected, setSelected] = useState('')
  const { param } = useSearch({ simpleParams: ['name'] })
  const [sectionPerPage, setSectionPerPage] = useState(10)
  const {
    control,
    register,
    getValues,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      comment: '',
      document: '',
    },
    resolver: yupResolver(moveSectionSchema),
  })
  const { userToken } = useAuth()
  const sectionFilters =
    setupSearchFilters([{ name: param?.single?.name || '' }]) +
    setupSearchMultiFilters([{ branch: [branchId] }])
  const {
    data: sections,
    isLoading: sectionsLoading,
    error: sectionsError,
  } = useQuery({
    queryKey: [userToken, queryKeys.sectionsList, sectionFilters],
    queryFn: () => getSectionsListPrefiltred(1, sectionPerPage, sectionFilters),
  })
  const sectionsList = (sections?.items || []).map((e: { name: string; id: string }) => {
    return {
      label: e.name,
      value: e.id,
    }
  })

  const searchMoreHandler = () => {
    const count =
      sections?.pagination?.countFiltered > 0
        ? sections?.pagination?.countFiltered
        : sections?.pagination?.count
    if (!sectionsLoading && sections?.items?.length < count)
      setSectionPerPage((prev: number) => prev + 10)
  }
  const onSubmit = () => {
    const comment = getValues('comment')
    sendHandler(selected, comment)
    closeHandler()
  }
  const onValidationFailure = () => errorRequires(errors)
  const addModule = !isMove && sectionsList.length > 0 && isOpen
  const moveModule = isMove && sectionsList.length > 1 && isOpen
  const showInput = addModule || moveModule
  return (
    <Modal isOpen={isOpen} handleClose={closeHandler}>
      <Card label={isMove ? 'Przenieś członka' : 'Przypisz nową sekcję/ognisko'}>
        <div className='p-6'>
          <form onSubmit={handleSubmit(onSubmit, onValidationFailure)} className='w-96'>
            {showInput ? (
              <SearchAndFetchInput
                shortenName
                externalChangeValue={setSelected}
                searchMore={searchMoreHandler}
                isLoading={sectionsLoading}
                name={'document'}
                label={'Wyszukaj'}
                register={register}
                options={sectionsList}
                control={control}
              />
            ) : (
              <Typography className='text-red-500' size='sm'>
                {isMove
                  ? 'W oddziale jest tylko jedna sekcja/ognisko'
                  : 'W oddziale nie ma sekcji/ogniska'}
              </Typography>
            )}
            <div className='mt-5'>
              <TextareaField
                error={errors.comment?.message}
                {...register('comment')}
                label='Powód przeniesienia'
                name='comment'
              />
            </div>
            <div className='mt-5 flex items-center justify-center gap-5'>
              <Button label='Anuluj' variant='secondary' onClick={closeHandler} />
              {addModule && <Button type='submit' label='Dodaj' />}
              {moveModule && <Button disabled={!selected} type='submit' label='Przenieś' />}
            </div>
          </form>
        </div>
      </Card>
    </Modal>
  )
}

export default MemberSectionAddModal
