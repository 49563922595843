import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { successToast, universalError } from 'tools/ToastHelpers'
import { path } from 'constants/path'
import { queryKeys } from 'constants/queryKeys'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { IUnitPosition, IUnitPositionData } from 'types/unitPosition'
import UnitPositionsForm from 'components/UnitPositons/UnitPositionsForm'
import { createUnitPosition, getTermLength } from 'fetchers/unitPositionFetchers'
import { useAppSelector } from 'app/hooks'
import { getStructureTypesList } from 'fetchers/structureFetcher'
import { dataToSelect } from 'tools/mapData'
import { mutationErrorHandler } from 'tools/errorHandler'
import { unitPositionErrors } from 'error-data/unit'

export const UnitPositionsCreate = () => {
  const [isSending, setIsSending] = useState(false)
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { userToken } = useAppSelector((state: any) => state.authReducer)
  const listPath = path.structure.unitPositions.list

  const {
    data: termOfOffice,
    isLoading: isLoadingTOF,
    error: errorTOF,
  } = useQuery({
    queryKey: [userToken, queryKeys.termOfOffice],
    queryFn: () => getTermLength(),
  })
  const {
    data: structureTypes,
    isLoading: isLoadingST,
    error: errorST,
  } = useQuery({
    queryKey: [userToken, queryKeys.baseStructures],
    queryFn: () => getStructureTypesList(1, 100),
  })

  const termOfOfficeData = dataToSelect(termOfOffice?.items)
  const structureTypesData = dataToSelect(structureTypes?.structure)

  const mutation = useMutation({
    mutationFn: (data: IUnitPosition) => createUnitPosition(data),
    onSuccess: () => {
      successToast('Dodano nową funkcję')
      queryClient.invalidateQueries({ queryKey: [queryKeys.unitPositionsList] })
      navigate(listPath)
    },
    onError: (error: any) => {
      setIsSending(false)
      console.error(error)
      mutationErrorHandler(error, unitPositionErrors.create, 'Nie udało się dodać nowej funkcji.')
    },
  })

  const onSubmit = async (data: IUnitPositionData) => {
    try {
      const { id, ...formData } = data
      setIsSending(true)
      mutation.mutate(formData)
    } catch (error) {
      setIsSending(false)
      universalError(error)
      mutationErrorHandler(null, null, 'Nie udało się dodać nowej funkcji.')
    }
  }

  const cancelHandler = () => navigate(listPath)
  const initData: IUnitPosition = {
    name: '',
    unitType: '',
    maxNumber: undefined,
    lengthOfTerm: 1,
    lengthOfTermUnit: 'year',
  }
  return (
    <UnitPositionsForm
      termOfOfficeData={termOfOfficeData}
      structureTypesData={structureTypesData}
      initData={initData}
      onSubmit={onSubmit}
      isSending={isSending}
      onCancelHandler={cancelHandler}
    />
  )
}

export default UnitPositionsCreate
