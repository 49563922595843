export const declarationInit = {
  branch: '',
  firstName: '',
  secondName: '',
  lastName: '',
  pesel: '',
  sex: 'female',
  phoneNumber: '',
  email: '',
  zipCode: '',
  city: '',
  street: '',
  houseNr: '',
  apartmentNr: '',
  education: '',
  educationSimple: '',
  redacted: false,
  status: 'awaiting',
  jobs: [
    {
      institution: {
        value: '',
        label: '',
      },
      position: {
        value: '',
        label: '',
      },
      isMain: true,
      subject: {
        value: '',
        label: '',
      },
    },
  ],
  jobsSimple: [],
}
