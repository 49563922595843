import { PencilIcon, EyeIcon, FolderIcon } from '@heroicons/react/24/outline'
import { HoverIcon } from 'components/Atoms/HoverIcon'
import useAuth from 'hooks/useAuth'
import { useNavigate } from 'react-router-dom'
import { IListElement } from 'types/form'

type Props = {
  route: string
  data: any
  showModalHandler: (data: IListElement) => void
}

export const InstitutionActionCell = ({ route, data, showModalHandler }: Props) => {
  const navigate = useNavigate()
  const showHandler = () => navigate(`/${route}/${data.id}`)
  const editHandler = () => navigate(`/${route}/${data.id}/update`)
  const { isAllAdmin, isAllOD, isAllOK } = useAuth()
  const isAllow = isAllAdmin() || isAllOD() || isAllOK()
  return (
    <div className='flex'>
      {isAllow && (
        <>
          <HoverIcon iconName='EyeIcon' title='Zobacz' onClick={showHandler} />
          <HoverIcon iconName='PencilIcon' title='Edytuj' onClick={editHandler} />
          <HoverIcon
            iconName='FolderIcon'
            title='Archiwizuj'
            onClick={() => showModalHandler(data)}
          />
        </>
      )}
    </div>
  )
}

export default InstitutionActionCell
