import { secureBaseApiURL } from 'api'
import { endpoint } from 'constants/endpoints'

export const getDocumentList = async (page: number, count: number, filters?: string) => {
  const res = await secureBaseApiURL.get(
    endpoint.document.list(page, count, filters ? filters : ''),
  )
  return res?.data
}

export const getDocument = async (id: string) => {
  const res = await secureBaseApiURL.get(endpoint.document.details(id))
  return res?.data
}

export const getDocumentTemplateList = async (page: number, count: number, filters?: string) => {
  const res = await secureBaseApiURL.get(
    endpoint.document.templateList(page, count, filters ? filters : ''),
  )
  return res?.data
}

export const createDocument = async (data: any) => {
  const res = await secureBaseApiURL.post(endpoint.document.create, data)
  return res?.data
}

export const archiveDocument = async (id: string) => {
  const res = await secureBaseApiURL.post(endpoint.document.archive(id))
  return res?.data
}

export const getDocumentFile = async (id: string, name: string) => {
  const res = await secureBaseApiURL.get(endpoint.document.getFile(id), {
    responseType: 'arraybuffer',
  })
  const blob = new Blob([res.data], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  })
  const url = window.URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.href = url
  a.download = `${name}.zip`
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
  window.URL.revokeObjectURL(url)
}

export const getDocumentFilePassword = async (id: string) => {
  const res = await secureBaseApiURL.get(endpoint.document.getPassword(id))
  return res?.data
}
