import { publicBaseApiURL } from 'api'
import { endpoint } from 'constants/endpoints'

export const getTrainingList = async (page: number, count: number, filters?: string) => {
  const res = await publicBaseApiURL.get(endpoint.training.list(page, count, filters), {})
  return res?.data
}

export const createTraining = async (data: any) => {
  const res = await publicBaseApiURL.post(endpoint.training.create, data)
  return res?.data
}

export const updateTraining = async (id: string, data: any) => {
  const res = await publicBaseApiURL.put(endpoint.training.updateDetails(id), data)
  return res?.data
}

export const archiveTraining = async (id: string) => {
  const res = await publicBaseApiURL.post(endpoint.training.archive(id))
  return res?.data
}

export const getTrainingDetails = async (id: string) => {
  const res = await publicBaseApiURL.get(endpoint.training.details(id), {})
  return res?.data
}

export const getTrainingMembersList = async (id: string) => {
  const res = await publicBaseApiURL.get(endpoint.training.trainingMemberList(id), {})
  return res?.data
}

export const addMemberToTraining = async (id: string, memberId: string) => {
  const res = await publicBaseApiURL.post(endpoint.training.addMember(id, memberId))
  return res?.data
}

export const removeMemberFromTraining = async (id: string, memberId: string) => {
  const res = await publicBaseApiURL.delete(endpoint.training.removeMember(id, memberId))
  return res?.data
}

export const addTrainigFile = async (file: any) => {
  const formData = new FormData()
  formData.append('file', file)

  try {
    const response = await fetch('https://api.dromader.nxtm.pl/api/training/file/create', {
      method: 'POST',
      headers: {
        'X-Auth-Token': `Bearer ${localStorage.getItem('token')}`,
      },
      body: formData,
    })
    if (!response.ok) {
      throw new Error('Network response was not ok ' + response.statusText)
    }
    const data = await response.json()
    console.log('Success:', data)
    return data
  } catch (error) {
    console.error('Error:', error)
    throw error
  }
}

export const getTrainigFile = async (id: string) => {
  const res = await publicBaseApiURL.get(endpoint.training.getFile(id), {
    responseType: 'blob',
  })
  return res?.data
}
