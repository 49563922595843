// import { toast } from 'react-toastify'
import { Toaster, toast } from 'sonner'

export const errorRequires = (errors: any) => {
  console.error('errors', errors)
  toast.warning('Wypełnij wymagane pola.')
}

export const universalError = (errors: any) => {
  console.error('errors', errors)
  toast.warning('Wystąpił błąd.')
}

export const wipMessage = () => {
  toast.info('Funkcjonalność w przygotowaniu.')
}

export const successToast = (message: string) => {
  toast.success(message)
}

export const errorToast = (message: string) => {
  toast.warning(message)
}
