import { IMutationErrorType } from 'types/httpStatus.enum'

export const documentErrors: IMutationErrorType = {
  get: {
    message: 'Nie udało się utworzyć dokumentu.',
  },
}

export const documentArchiveErrors: IMutationErrorType = {
  create: {
    message: 'Nie udało się zarchiwizować dokumentu.',
  },
}

export const documentGetFileErrors: IMutationErrorType = {  
  get: {
    message: 'Nie udało się pobrać pliku dokumentu.',
  },
}
