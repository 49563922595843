import React, { useEffect, useState } from 'react'
import { Card } from 'components/Atoms/Card'
import { LabelTypography } from 'components/Atoms/LabelTypography'
import { TextField } from 'components/Atoms/TextField'
import { useForm } from 'react-hook-form'
import { IRegion } from 'types/region'
import { path } from 'constants/path'
import { useNavigate } from 'react-router-dom'
import { IListElement } from 'types/form'
import { all } from 'axios'
interface IProps {
  data: IRegion
  showModalHandler: (id: IListElement) => void
  allowArchive: boolean
}
export const BranchBaseInfo: React.FC<IProps> = ({ data, allowArchive, showModalHandler }) => {
  const navigate = useNavigate()
  const address = data?.address
    ? `${data?.address?.streetName ?? ''} ${data?.address?.houseNr ?? ''} ${
        data?.address?.apartmentNr ?? ''
      } ${data?.address?.postalCode ?? ''} ${data?.address?.city ?? ''}`
    : ''
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: '',
      president: '',
      phone: '',
      email: '',
      address: '',
      location: '',
      time: '',
    },
  })

  return (
    <Card
      label='Dane ogólne'
      actionsButton={[
        {
          label: 'Edytuj',
          handleClick: () => navigate('update'),
        },
        {
          label: 'Archiwizuj',
          handleClick: () => showModalHandler(data),
          disabled: allowArchive ? false : true,
        },
      ]}
    >
      <div className='mb-5 grid grid-cols-4 gap-5'>
        <LabelTypography label='Nazwa oddziału' text={data?.name} />
        <LabelTypography label='Prezes oddziału' text={data?.regionPresident} />
        <LabelTypography label='Telefon' text={data?.contact.phone} />
        {data?.contact.phoneSecondary && (
          <LabelTypography label='Telefon dodatkowy' text={data?.contact?.phoneSecondary} />
        )}
        <LabelTypography label='Email' text={data?.contact?.email} />
        {data?.contact.emailSecondary && (
          <LabelTypography label='Telefon dodatkowy' text={data?.contact?.emailSecondary} />
        )}
      </div>
      <div className='grid grid-cols-4 gap-5'>
        <LabelTypography label='Adres' text={address} />
        <LabelTypography label='Lokalizacja' text={data?.locationDescription} />
        <LabelTypography label='Godziny otwarcia' text={data?.workHours} />
      </div>
    </Card>
  )
}
