import { FormEvent, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { IFilters } from 'types/form'

type Props = {
  archive?: boolean
  searchName?: string
}

export const useSearchModule = ({ archive, searchName = 'name' }: Props) => {
  // SEARCH STATES
  const [queryParams, setQueryParams] = useSearchParams()
  const [perPage, setPerPage] = useState<number>(10)
  const [search, setSearch] = useState('')
  const [city, setCity] = useState('')

  // QUERY STATES
  const filters: IFilters[] = []
  const currentPage = Number(queryParams.get('page'))
  const nameParam = queryParams.get('name')
  const cityParam = queryParams.get('city')
  if (archive) filters.push({ archived: true })
  if (nameParam?.length) filters.push({ [searchName]: nameParam })
  if (cityParam?.length) filters.push({ city: cityParam })

  const prepareQueryParams = () => {
    const query = {}
    if (archive) Object.assign(query, { archived: true })
    if (search.length) Object.assign(query, { [searchName]: search })
    if (city.length) Object.assign(query, { city: city })
    return query
  }

  // SEARCH HANDLERS
  const setSearchInput = (e: FormEvent<HTMLInputElement>) => {
    setSearch(e.currentTarget.value)
  }
  const setSearchString = (e: string) => {
    setSearch(e)
  }
  const setCityInput = (e: FormEvent<HTMLInputElement>) => {
    setCity(e.currentTarget.value)
  }

  // QUERY HANDLERS
  const onPageChange = (newPage: any) => {
    setQueryParams({ page: newPage, ...prepareQueryParams() })
  }
  const onSearchHandler = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setPerPage(10)
    setQueryParams({ page: '1', ...prepareQueryParams() })
  }

  const onRefreshHandler = () => {
    setQueryParams({ ...prepareQueryParams() })
  }

  useEffect(() => {
    if (nameParam) setSearch(nameParam)
  }, [nameParam])
  return {
    filters,
    currentPage: currentPage > 0 ? currentPage : 1,
    perPage: perPage,
    search,
    setSearch: setSearchInput,
    setSearchString,
    setPerPage,
    city,
    setCity: setCityInput,
    onPageChange,
    onSearchHandler,
    onRefreshHandler,
  }
}

export default useSearchModule
