import { IUnitPosition, IUnitPositionUpdate } from 'types/structure'
import { Modal } from './Modal'
import { Card } from './Card'
import BranchFunctionUpdateForm from 'components/BranchFunctions/BranchFunctionUpdateForm'

type Props = {
  functionData: IUnitPosition
  isOpen: boolean
  isSending?: boolean
  closeModal: () => void
  submitHandler: (data: IUnitPositionUpdate) => void
}

export const MemberFunctionModal = ({
  functionData,
  isOpen,
  isSending,
  closeModal,
  submitHandler,
}: Props) => {
  const initData = {
    startedAt: functionData?.startedAt ? new Date(functionData?.startedAt) : undefined,
    endedAt: functionData?.endedAt ? new Date(functionData?.endedAt) : undefined,
  }
  const submitData = (data: IUnitPositionUpdate) => {
    submitHandler({
      ...data,
      termId: functionData.id,
    })
  }
  return (
    <Modal isOpen={isOpen} handleClose={closeModal}>
      <BranchFunctionUpdateForm
        isModal
        closeHandler={closeModal}
        label={` / ${functionData?.unitPosition?.name}`}
        isSending={isSending}
        onSubmit={submitData}
        initData={initData}
      />
    </Modal>
  )
}

export default MemberFunctionModal
