import { Button } from 'components/Atoms/Button'
import { Card } from 'components/Atoms/Card'
import { HoverIcon } from 'components/Atoms/HoverIcon'
import { Modal } from 'components/Atoms/Modal'
import { Table } from 'components/Atoms/Table'
import { Typography } from 'components/Atoms/Typography'
import { useMemo, useState } from 'react'
import { setupDate, setupTime } from 'tools/formTools'
import { ILifecycle } from 'types/cardRequest'
import { IDict } from 'types/dictionary'

type Props = {
  data: ILifecycle[]
  typeList: IDict[]
  statusList: IDict[]
  eventList: IDict[]
}

export const CardReqDetailsLifecycle = ({ data, statusList, typeList, eventList }: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [modalComment, setModalComment] = useState<string>('')
  const openModal = (data: string) => {
    setIsOpen(true)
    setModalComment(data)
  }
  const closeModal = () => setIsOpen(false)

  const lifecycle = data.map((item: ILifecycle) => {
    return {
      issuer: item?.user?.displayName || item?.user?.email,
      date: `${setupDate(item.createdAt)} ${setupTime(item.createdAt)}`,
      event: eventList.find((e: IDict) => e.id === item.type)?.name,
      status: statusList.find((e: IDict) => e.id === item.status)?.name,
      comment: item.comment
        ? item.comment.substring(0, 50) + (item.comment.length > 50 ? '...' : '')
        : null,
    }
  })
  const columns = useMemo(
    () => [
      {
        Header: 'Zmieniający',
        accessor: 'issuer',
      },
      {
        Header: 'Data',
        accessor: 'date',
      },
      {
        Header: 'Typ akcji',
        accessor: 'event',
      },
      {
        Header: 'Nazwa statusu',
        accessor: 'status',
      },
      {
        Header: 'Komentarz',
        accessor: 'comment',
      },
      {
        Header: 'Akcje',
        accessor: 'action',
        Cell: ({ row }: any) => {
          return (
            <div className='flex'>
              <HoverIcon
                iconName='EyeIcon'
                title='Pokaż cały komentarz'
                onClick={() => openModal(row.original.comment)}
              />
            </div>
          )
        },
      },
    ],
    [],
  )
  return (
    <Card label='Historia zmian'>
      <Table columns={columns} data={lifecycle} />
      <Modal isOpen={isOpen} handleClose={closeModal}>
        <section className='rounded-lg bg-white p-5'>
          <p className='mb-4'>{modalComment}</p>
          <div className='flex items-center justify-center gap-5'>
            <Button label='Zamknij' onClick={closeModal} />
          </div>
        </section>
      </Modal>
    </Card>
  )
}

export default CardReqDetailsLifecycle
