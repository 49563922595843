import { Button } from 'components/Atoms/Button'
import { Card } from 'components/Atoms/Card'
import { Select } from 'components/Atoms/Select'
import { TextField } from 'components/Atoms/TextField'

export const AddMemberForm = () => {
  return (
    <Card>
      <Button label='Zgłość brakującego członka' />
      <form>
        <div className='mb-5 grid w-1/2 grid-cols-3 gap-5'>
          <TextField label='Imię' />
          <TextField label='Nazwisko' />
          <TextField label='Nr legitymacji' />
        </div>
        <div className='grid w-1/2 grid-cols-3 gap-5'>
          <Select
            label='Placówka'
            options={[
              { label: 'Szkoła podstawowa nr 25', value: '1' },
              { label: 'Szkoła podstawowa nr 135', value: '2' },
            ]}
          />
          <Select
            label='Stanowisko'
            options={[
              { label: 'Nauczyciel', value: '1' },
              { label: 'Dyrektor', value: '2' },
            ]}
          />
          <Select
            label='Wymiar etatu'
            options={[
              { label: '1/2 etatu', value: '1' },
              { label: '1/3 etatu', value: '2' },
              { label: 'Pełen etat', value: '3' },
            ]}
          />
        </div>
      </form>
    </Card>
  )
}
