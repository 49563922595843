import { Typography } from 'components/Atoms/Typography'
import MemberExportBar from './MemberExportBar'
import MemberExportGenerated from './MemberExportGenerated'
import {
  IBasicSettingObject,
  IListElement,
  IOptionsObject,
  IPageHandler,
  IVoidObject,
} from 'types/form'
import { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { queryKeys } from 'constants/queryKeys'
import { getRegionsList } from 'fetchers/regionFetchers'
import { errorQuery, setupSearchFilters, setupSearchMultiFilters } from 'tools/queryHelpers'
import { getBranchesListPrefiltred } from 'fetchers/branchFetchers'
import { IUnitSelectedUnits } from 'types/structure'
import { getSectionsListPrefiltred } from 'fetchers/sectionFetchers'
import useRoles from 'hooks/useRoles'
import useSearch from 'hooks/useSearch'
import { getInstitutionsList } from 'fetchers/institutionFetchers'
import { getPositionsList } from 'fetchers/positionFetchers'
import { getSubjectsList } from 'fetchers/subjectFetchers'
import { getAvailableTags } from 'fetchers/tagsFetchers'
import { getUnitPositionsList } from 'fetchers/unitPositionFetchers'
import { Toaster } from 'sonner'
import MemberExportCreate from './MemberExportCreate'

type Props = {
  isRegion?: boolean
  isBranch?: boolean
  isAdmin?: boolean
  userToken: string
  unitContext: any
  userLoaded: boolean
}

const searchParams = {
  simpleParams: [
    'search',
    'searchExtended',
    'email',
    'pesel',
    'sex',
    'card',
    'institutionLimit',
    'regionLimit',
  ],
  arrayParams: [
    'institution',
    'subject',
    'region',
    'branch',
    'section',
    'position',
    'unitPosition',
    'tag',
  ],
}

const selectedUnitsInit: IUnitSelectedUnits = {
  region: [],
  branch: [],
  section: [],
  institution: [],
}

export const MemberExportRequest = ({
  isRegion,
  isBranch,
  isAdmin,
  userToken,
  unitContext,
  userLoaded,
}: Props) => {
  const { rolesOptions } = useRoles()
  const { filters, setParam, perPage, page, setLimit, changePage } = useSearch(searchParams)

  const [regionPerPage, setRegionPerPage] = useState(10)
  const [branchPerPage, setBranchPerPage] = useState(10)
  const [sectionPerPage, setSectionPerPage] = useState(10)
  const [regionSearch, setRegionSearch] = useState('')
  const [branchSearch, setBranchSearch] = useState('')
  const [selectedUnits, setSelectedUnits] = useState<IUnitSelectedUnits>(selectedUnitsInit)
  const [sectionSearch, setSectionSearch] = useState('')
  const [rolesSearch, setRolesSearch] = useState('')
  const [inPerPage, setInPerPage] = useState(10)
  const [institutionSearch, setInstitutionSearch] = useState('')

  const id = unitContext?.id
  const selectedRegions = selectedUnits.region.length
  const selectedBranches = selectedUnits.branch.length

  const branchFilters =
    setupSearchFilters([{ name: branchSearch }]) +
    setupSearchMultiFilters([{ region: selectedUnits.region }])

  const {
    data: regions,
    isLoading: regLoading,
    error: regError,
    refetch: rfReg,
  } = useQuery({
    queryKey: [userToken, queryKeys.regionList, regionPerPage, regionSearch],
    queryFn: () => getRegionsList(1, regionPerPage, [{ name: regionSearch }]),
    retry: errorQuery,
  })

  const {
    data: branches,
    isLoading: brLoading,
    error: brError,
    refetch: rfBr,
  } = useQuery({
    queryKey: [
      userToken,
      queryKeys.branchesList,
      branchPerPage,
      branchSearch,
      selectedRegions,
      branchFilters,
    ],
    queryFn: () => {
      if (selectedRegions === 0) {
        return { items: [] }
      }
      return getBranchesListPrefiltred(1, branchPerPage, branchFilters)
    },
    retry: errorQuery,
  })

  const sectionFilters =
    setupSearchFilters([{ name: sectionSearch }]) +
    setupSearchMultiFilters([{ branch: selectedUnits.branch }])

  const {
    data: sections,
    isLoading: secLoading,
    error: secError,
    refetch: rfSec,
  } = useQuery({
    queryKey: [
      userToken,
      queryKeys.sectionsList,
      sectionPerPage,
      sectionSearch,
      selectedBranches,
      selectedRegions,
      sectionFilters,
    ],
    queryFn: () => {
      if (selectedBranches === 0) {
        return { items: [] }
      }
      return getSectionsListPrefiltred(1, sectionPerPage, sectionFilters)
    },
    retry: errorQuery,
  })

  // INSTITUTIONS LOADER
  // Get institutions list
  const {
    data: institutions,
    isLoading: inLoading,
    error,
    refetch: rfIn,
  } = useQuery({
    queryKey: [userToken, queryKeys.institutionsList, inPerPage, institutionSearch],
    queryFn: () => getInstitutionsList(1, inPerPage, { name: institutionSearch }),
    retry: errorQuery,
  })

  // Set institutions list
  const institutionsList = institutions?.items
    ? institutions.items.map((e: any) => ({ id: e.id, name: e.name }))
    : []
  // END INSTITUTIONS LOADER

  // SUBJECTS LOADER
  const { data: subjects, isLoading: subLoading } = useQuery({
    queryKey: [userToken, queryKeys.regionList],
    queryFn: () => getSubjectsList('all', 1),
    retry: errorQuery,
  })
  // Setup subjects list
  const subjectsList = subjects?.items
    ? subjects.items.map((e: any) => ({ value: e.id, label: e.name }))
    : []
  // END SUBJECTS LOADER

  // FUNCTIONS LOADER
  const { data: functions, isLoading: funLoading } = useQuery({
    queryKey: [userToken, queryKeys.unitPositionsList],
    queryFn: () => getUnitPositionsList('all', 100),
    retry: errorQuery,
  })
  const functionsList = functions?.items
    ? functions.items.map((e: any) => ({ value: e.id, label: e.name }))
    : []
  // END FUNCTIONS LOADER

  // POSITIONS LOADER
  const { data: positions, isLoading: posLoading } = useQuery({
    queryKey: [userToken, queryKeys.positionsList],
    queryFn: () => getPositionsList('all', 10),
    retry: errorQuery,
  })
  const positionsList = positions?.items
    ? positions.items.map((e: any) => ({ value: e.id, label: e.name }))
    : []
  // END POSITIONS LOADER

  // TAGS LOADER
  const {
    data: tags,
    isLoading: tagsLoading,
    refetch: rfTag,
  } = useQuery({
    queryKey: [userToken, queryKeys.tagsListAvailable],
    queryFn: () => getAvailableTags(),
    retry: errorQuery,
  })
  const tagsList = tags?.items
    ? tags.items.map((item: IListElement) => ({ label: item.name, value: item.id }))
    : []
  // END TAGS LOADER

  const setBoxLimit: IPageHandler = {
    regions: (e: number) => setRegionPerPage(e),
    branches: (e: number) => setBranchPerPage(e),
    sections: (e: number) => setSectionPerPage(e),
  }

  const loadings: any = {
    regions: regLoading,
    branches: brLoading,
    sections: secLoading,
    role: rolesOptions?.length === 0,
  }

  const regionsList = regions?.items
    ? regions.items.map((e: any) => ({ id: e.id, name: e.name }))
    : []

  const branchesList = branches?.items
    ? branches.items.map((e: any) => ({ id: e.id, name: e.name }))
    : []

  const sectionsList = sections?.items
    ? sections.items.map((e: any) => ({ id: e.id, name: e.name }))
    : []

  const rolesFiltered = rolesOptions
    ? rolesOptions
        .filter((role) => role?.name.includes(rolesSearch))
        .map((role: any) => ({ name: role?.name, id: role?.role }))
    : []

  const options: IBasicSettingObject = {
    regions: regionsList,
    branches: branchesList,
    sections: sectionsList,
    institutions: institutionsList,
    subjects: subjectsList,
    unitPositions: functionsList,
    positions: positionsList,
    tags: tagsList,
  }

  const query: any = {
    regions: regionSearch,
    branches: branchSearch,
    sections: sectionSearch,
    role: rolesSearch,
  }

  const refetch: IVoidObject = {
    regions: rfReg,
    branches: rfBr,
    sections: rfSec,
    institutions: rfIn,
    tags: rfTag,
  }

  const queryHandler = {
    regions: (e: string) => setRegionSearch(e),
    branches: (e: string) => setBranchSearch(e),
    sections: (e: string) => setSectionSearch(e),
    role: (e: string) => setRolesSearch(e),
    institutions: (e: string) => setInstitutionSearch(e),
  }

  const searchMoreHandler = (unit: any, loading: boolean, setter: React.Dispatch<any>) => {
    const count =
      unit?.pagination?.countFiltered > 0
        ? unit?.pagination?.countFiltered
        : unit?.pagination?.count
    if (!loading && unit?.items?.length < count) setter((prev: number) => prev + 10)
  }

  const searchMore: IVoidObject = {
    regions: () => searchMoreHandler(regions, regLoading, setRegionPerPage),
    branches: () => searchMoreHandler(branches, brLoading, setBranchPerPage),
    sections: () => searchMoreHandler(sections, secLoading, setSectionPerPage),
  }

  const setCustomAgeParam = (ageSearch: string) => {
    setParam.custom(ageSearch)
  }

  useEffect(() => {
    if (selectedRegions === 0) {
      setSelectedUnits({ ...selectedUnitsInit, branch: isBranch ? [id] : [] })
    }
  }, [selectedRegions, isBranch])
  useEffect(() => {
    if (selectedBranches === 0) {
      setSelectedUnits({ ...selectedUnits, section: [] })
    }
  }, [selectedBranches])
  useEffect(() => {
    if (isRegion) setSelectedUnits({ ...selectedUnitsInit, region: [id] })
    if (isBranch) setSelectedUnits({ ...selectedUnitsInit, branch: [id] })
    if (isAdmin) setSelectedUnits({ ...selectedUnitsInit })
  }, [isRegion, isBranch, isAdmin, userToken])

  useEffect(() => {
    const dropdownHeadings = document.querySelectorAll('.dropdown-heading')
    dropdownHeadings.forEach((dropdownHeading) => {
      const span = dropdownHeading.querySelector('span')
      if (span?.textContent === 'Select...') {
        span.textContent = 'Wybierz...'
      }
    })
  }, [])

  return (
    <div>
      <Toaster richColors />
      <Typography size='xl' weight='semibold'>
        Eksport danych
      </Typography>
      {/* <MemberExportBar unitContext={unitContext} userToken={userToken} /> */}
      <MemberExportCreate
        isRegion={isRegion}
        userToken={userToken}
        unitContext={unitContext}
        isBranch={isBranch}
        selectedUnits={selectedUnits}
        setSelectedUnits={setSelectedUnits}
        setCustomAgeParam={setCustomAgeParam}
        searchParams={searchParams}
        options={options}
        loadings={loadings}
        query={query}
        refetch={refetch}
        queryHandler={queryHandler}
        searchMore={searchMore}
        limitSetter={setBoxLimit}
      />
    </div>
  )
}
