import { IPagination } from 'types/pagination'
import type { RootState } from 'app/store'
import { secureBaseApiURL } from 'api'
import { endpoint } from 'constants/endpoints'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export interface IDeclaration {
  address: string
  basePlace: string
  email: string
  firstName: string
  id: string
  isValid: boolean
  lastName: string
  pesel: string
  phoneNumber: string
  position: string
  redacted: boolean
  status: string
  validationResult?: {
    valid: boolean
    errorValidation: {
      property: string
      error: string
    }[]
    errorConflictingAwaitingDeclaration: boolean
    errorWrongStatus: boolean
    warningRemovedFromUnion: boolean
  }
}

interface DeclarationState {
  itemById: IDeclaration | null
  declaration: {
    items: IDeclaration[]
    pagination: IPagination | null
    redacted: boolean
  }
  loading: 'idle' | 'pending' | 'succeeded' | 'failed'
  error: string | null
}

// interface IJob {
//   job: string
//   position: string
//   isMain: boolean
// }

export interface IRequestDeclaration {
  id?: string
  firstName: string
  secondName: string
  lastName: string
  pesel: string
  sex: string
  phoneNumber: string
  email: string
  zipCode: string
  city: string
  street: string
  houseNr: string
  apartmentNr: string
  education: string
  redacted: boolean
  status: string // 'awaiting' | 'accepted' | 'rejected'
  jobs: any
}

export const fetchDeclarations = createAsyncThunk(
  'declaration/fetchDeclarations',
  async (payload?: string) => {
    try {
      const requestStatus = payload ? `filter[status][]=${payload}` : 'filter[status][]=awaiting'

      const response = await secureBaseApiURL.get(
        endpoint.membership.declarationList(1, 10, requestStatus),
      )
      return response.data
    } catch (err) {
      return err
    }
  },
)

export const fetchDeclarationById = createAsyncThunk(
  'declaration/fetchDeclarationById',
  async (payload: string) => {
    try {
      const response = await secureBaseApiURL.get(endpoint.membership.declarationById(payload))
      return response.data
    } catch (err) {
      return err
    }
  },
)

export const updateDeclaration = createAsyncThunk(
  'declaration/updateDeclaration',
  async (payload: IRequestDeclaration) => {
    const { id, status, redacted, ...rest } = payload
    try {
      const response = await secureBaseApiURL.put(
        endpoint.membership.declarationUpdate(String(id)),
        rest,
      )
      return response
    } catch (err) {
      return err
    }
  },
)

export const createDeclaration = createAsyncThunk(
  'declaration/createDeclaration',
  async (payload: IRequestDeclaration) => {
    try {
      const response = await secureBaseApiURL.post(endpoint.membership.declarationCreate, payload)
      return response
    } catch (err) {
      return err
    }
  },
)

interface IId {
  id: string
}
export interface IAcceptDeclarationRequest {
  items: IId[]
  resolution: string
  date: Date
}
export interface IRejectDeclarationRequest {
  items: IId[]
  reason: string
}

const initialState = {
  itemById: null,
  declaration: {
    items: [],
    pagination: null,
    redacted: false,
  },
  loading: 'idle',
  error: null,
} as DeclarationState

export const declarationSlice = createSlice({
  name: 'declaration',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchDeclarations.pending, (state) => {
        state.loading = 'pending'
      })
      .addCase(fetchDeclarations.fulfilled, (state, action) => {
        // state.loading = 'succeeded'
        state.declaration = action.payload
      })
      .addCase(fetchDeclarations.rejected, (state) => {
        // state.loading = 'failed'
        state.error = 'Błąd podczas pobierania deklaracji.'
      })
      .addCase(createDeclaration.fulfilled, (state, action) => {
        state.declaration.items.push(action.payload as any)
      })
      .addCase(fetchDeclarationById.fulfilled, (state, action) => {
        state.itemById = action.payload
      })
  },
})

export const getDeclarations = (state: RootState) => state.declarationReducer.declaration
export const getDeclarationStatus = (state: RootState) => state.declarationReducer.loading
export const getDeclarationError = (state: RootState) => state.declarationReducer.error

export const getDeclarationById = (state: RootState) => state.declarationReducer.itemById

export default declarationSlice.reducer
