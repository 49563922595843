import { useMutation, useQueryClient } from '@tanstack/react-query'
import ErrorRoleModal from 'components/Atoms/ErrorRoleModal'
import { UpdateForm } from 'components/Region/UpdateForm'
import { path } from 'constants/path'
import { queryKeys } from 'constants/queryKeys'
import { createRegion } from 'fetchers/regionFetchers'
import useAuth from 'hooks/useAuth'
import { useState } from 'react'

import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { LoadingSection } from 'components/Atoms/LoadingSection'
import { mutationErrorHandler } from 'tools/errorHandler'
import { AxiosError } from 'axios'
import { regionErrors } from 'error-data/region'
import { successToast } from 'tools/ToastHelpers'
interface ICreateRegion {
  name: string
  email: string
  postalCode: string
  city: string
  streetName: string
  houseNr: string
  apartmentNr: string
  locationDescription: string
  phonePrimary: string
  phoneSecondary: string
  document: string
}
export const RegionCreate = () => {
  const [isSending, setIsSending] = useState(false)
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { isAllAdmin, userLoaded } = useAuth()
  const adminAuth = isAllAdmin()

  const mutation = useMutation({
    mutationFn: (data: ICreateRegion) => createRegion(data),
    onSuccess: () => {
      successToast('Utworzono okręg.')
      queryClient.invalidateQueries({ queryKey: [queryKeys.regionList] })
      navigate(path.region.list)
    },
    onError: (error: AxiosError) => {
      setIsSending(false)
      console.error(error)
      mutationErrorHandler(error, regionErrors.create, 'Nie udało się utworzyć okręgu.')
    },
  })

  const onSubmit = async (data: any) => {
    try {
      setIsSending(true)
      mutation.mutate(data)
    } catch (error) {
      setIsSending(false)
      console.error(error)
      mutationErrorHandler(null, null, 'Nie udało się utworzyć okręgu.')
    }
  }

  const initData = {
    name: '',
    email: '',
    phonePrimary: '',
    phoneSecondary: '',
    postalCode: '',
    city: '',
    streetName: '',
    houseNr: '',
    apartmentNr: '',
    locationDescription: '',
    // document: ''
  }
  if (!userLoaded) return <LoadingSection />
  if (!adminAuth) return <ErrorRoleModal />
  return (
    <UpdateForm label='Utwórz okręg' data={initData} isSending={isSending} onSubmit={onSubmit} />
  )
}
