import { IMutationErrorType } from 'types/httpStatus.enum'

export const exportPasswordErrors: IMutationErrorType = {
  get: {
    message: 'Nie udało się pobrać hasła.',
  },
}

export const exportFileErrors: IMutationErrorType = {
  get: {
    message: 'Nie udało się pobrać pliku.',
  },
}

export const exportRequestErrors: IMutationErrorType = {
  get: {
    message: 'Nie udało się wysłać zapytania.',
  },
}
