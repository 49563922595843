import React from 'react'
import { Card } from 'components/Atoms/Card'
import { LabelTypography } from 'components/Atoms/LabelTypography'
import { useForm } from 'react-hook-form'
import { IRegion } from 'types/region'
import { path } from 'constants/path'
import { useNavigate } from 'react-router-dom'
interface IProps {
  data: IRegion
}
export const RegionBaseInfo: React.FC<IProps> = ({ data }) => {
  const navigate = useNavigate()
  const address = data?.address
    ? `${data?.address?.streetName ?? ''} ${data?.address?.houseNr ?? ''} ${
        data?.address?.apartmentNr ?? ''
      } ${data?.address?.postalCode ?? ''} ${data?.address?.city ?? ''}`
    : ''

  return (
    <Card
      label='Dane ogólne'
      actionsButton={[
        {
          label: 'Edytuj',
          handleClick: () => navigate(`${path.region.update}${data?.id}`),
        },
      ]}
    >
      <div className='mb-5 grid grid-cols-4 gap-5'>
        <LabelTypography label='Nazwa okręgu' text={data?.name} />
        <LabelTypography label='Prezes okręgu' text={data?.regionPresident} />
        <LabelTypography label='Telefon' text={data?.contact.phone} />
        {data?.contact.phoneSecondary && (
          <LabelTypography label='Telefon dodatkowy' text={data?.contact?.phoneSecondary} />
        )}
        <LabelTypography label='Email' text={data?.contact?.email} />
        {data?.contact.emailSecondary && (
          <LabelTypography label='Telefon dodatkowy' text={data?.contact?.emailSecondary} />
        )}
      </div>
      <div className='grid grid-cols-4 gap-5'>
        <LabelTypography label='Adres' text={address} />
        <LabelTypography label='Lokalizacja' text={data?.locationDescription} />
        <LabelTypography label='Godziny otwarcia' text={data?.workHours} />
      </div>
    </Card>
  )
}
