import { useQuery } from '@tanstack/react-query'
import LoadingModal from 'components/Atoms/LoadingModal'
import { Typography } from 'components/Atoms/Typography'
import RodoAccessRequests from 'components/Rodo/RodoAccessRequests'
import { queryKeys } from 'constants/queryKeys'
import { getAccessMemberCriteria, getAccessMembersList } from 'fetchers/rodoFetchers'
import useAuth from 'hooks/useAuth'
import { getLoadingHandler } from 'tools/queryHelpers'
import { IDict } from 'types/dictionary'

export const AccessMembersList = () => {
  const { userToken, isAllAdmin, isAllOD } = useAuth()

  const data = useQuery({
    queryKey: [userToken, queryKeys.rodoAccessMembers],
    queryFn: () => getAccessMembersList(),
  })

  const criteria = useQuery({
    queryKey: [userToken, queryKeys.rodoAccessMembersCriteria],
    queryFn: () => getAccessMemberCriteria(),
  })

  const dataList = data.data?.items || []
  const criteriaItems: IDict[] = criteria.data?.items || []

  const isLoading = data.isPending || criteria.isPending
  const isError = data.error || criteria.error
  const isValid = isAllAdmin() || isAllOD()
  const loadingHandler = getLoadingHandler(isError, isLoading, !isValid)
  if (loadingHandler.show) return <LoadingModal {...loadingHandler} />

  return (
    <div>
      <Typography size='xl' weight='semibold'>
        RODO / Lista zapytań o operacje na danych członków
      </Typography>
      <RodoAccessRequests type='members' list={dataList} criteria={criteriaItems} />
    </div>
  )
}

export default AccessMembersList
