import { Control, Controller } from 'react-hook-form'
import { Select } from '../Select'
import { IOption } from 'types/form'
import { useEffect, useState } from 'react'
import { TextField } from '../TextField'
import { slugify } from 'tools/queryHelpers'

type Props = {
  control: any
  name: any
  label: string
  register: any
  options: IOption[]
  defaultOption: IOption
  clearError?: () => void
}

export const SearchableDropdown = ({
  control,
  name,
  label,
  register,
  options,
  defaultOption,
  clearError,
}: Props) => {
  const [search, setSearch] = useState('')

  const fitlerList = options.filter((e) => slugify(e.label).includes(slugify(search)))

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault()
    }
  }
  const searchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    setSearch(e.target.value)
  }

  return (
    <>
      <TextField onKeyDown={handleKeyPress} label={label} type='text' onChange={searchHandler} />
      <p className='line-ellipsis my-2 text-sm opacity-60'>
        Domyślna wartość: {defaultOption?.label}
      </p>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultOption || {}}
        render={({ field: { onChange, value } }) => (
          <ul className='h-56 overflow-y-scroll rounded-md border-2 border-solid border-gray-300'>
            {fitlerList.map((e: any) => {
              const rKey = `r-${e.value}-${name}`
              const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
                onChange({ value: e })
                clearError && clearError()
              }
              return (
                <li
                  key={rKey}
                  className='flex gap-2 whitespace-pre-line border-b border-solid border-gray-300 px-3 py-2 text-sm'
                >
                  <label htmlFor={rKey} className='cursor-pointer'>
                    <input
                      {...register(name)}
                      type='radio'
                      id={rKey}
                      name={name}
                      onChange={() => handleRadioChange(e.value)}
                      checked={value?.value === e.value}
                      value={e.value}
                    />
                    <span className='pl-2'>{e.label}</span>
                  </label>
                </li>
              )
            })}
          </ul>
        )}
      />
    </>
  )
}

export default SearchableDropdown
