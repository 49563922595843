import { useNavigate } from 'react-router-dom'
import { ArchiveBoxIcon, EyeIcon, PencilIcon, TrashIcon } from '@heroicons/react/24/outline'
import { wipMessage } from 'tools/ToastHelpers'
import { HoverIcon } from 'components/Atoms/HoverIcon'

type Props = {
  id: string
}

export const UnitPositionsActionCell = ({ id }: Props) => {
  const navigate = useNavigate()
  const editPathHandler = () => navigate(`/structure/unit-position/${id}/update`)
  return (
    <div className='flex cursor-pointer'>
      <HoverIcon iconName='PencilIcon' title='Edytuj' onClick={editPathHandler} />
      <HoverIcon iconName='ArchiveBoxIcon' title='Archiwizuj' onClick={() => wipMessage()} />
    </div>
  )
}

export default UnitPositionsActionCell
