export const generatePagination = (current: number, total: number) => {
  if (total === 0) {
    return []
  }
  const shortNav = total < 9
  const firstButton = current === 1
  const lastButton = current === total
  const startNav = current < 5
  const endNav = current >= total - 3
  const heroPage = current === 0
  const pages = []
  if (shortNav) {
    return Array.from({ length: total }, (_, i) => ({
      value: i + 1,
      label: undefined,
    }))
  }
  if (heroPage) {
    return [
      { value: 1, label: undefined },
      { value: 2, label: undefined },
      { value: 3, label: undefined },
      { value: Math.ceil(total / 2), label: '...' },
      { value: total - 2, label: undefined },
      { value: total - 1, label: undefined },
      { value: total, label: undefined },
    ]
  }
  if (firstButton) {
    return [
      { value: 1, label: undefined },
      { value: 2, label: undefined },
      { value: 3, label: undefined },
      { value: 4, label: '...' },
      { value: total, label: undefined },
    ]
  }

  if (lastButton) {
    return [
      { value: 1, label: undefined },
      { value: total - 3, label: '...' },
      { value: total - 2, label: undefined },
      { value: total - 1, label: undefined },
      { value: total, label: undefined },
    ]
  }

  if (startNav) {
    for (let i = 1; i <= current + 1; i++) {
      pages.push({ value: i, label: undefined })
    }
    pages.push({ value: current + 2, label: '...' }, { value: total, label: undefined })
    return pages
  }

  if (endNav) {
    pages.push({ value: 1, label: undefined }, { value: current - 2, label: '...' })

    for (let i = current - 1; i <= total; i++) {
      pages.push({ value: i, label: undefined })
    }
    return pages
  }

  return [
    { value: 1, label: undefined },
    { value: current - 2, label: '...' },
    { value: current - 1, label: undefined },
    { value: current, label: undefined },
    { value: current + 1, label: undefined },
    { value: current + 2, label: '...' },
    { value: total, label: undefined },
  ]
}
