import { secureBaseApiURL } from 'api'
import { endpoint } from 'constants/endpoints'
import { IRodoAccess, IRodoAccessMember, IRodoReq } from 'types/dictionary'
import { getMembersList } from './membersFetchers'

// RODO RESOURCE SECTION
// MEMBER REQUESTS
export const getResourceMembersList = async () => {
  const res = await secureBaseApiURL.get(endpoint.rodo_resource.members.list)
  return res?.data
}

export const getResourceMemberCriteria = async () => {
  const res = await secureBaseApiURL.get(endpoint.rodo_resource.members.criteria)
  return res?.data
}

export const postResourceMemberReq = async (data: IRodoReq[]) => {
  const formData = {
    criteria: data,
    withHistory: true,
  }
  const res = await secureBaseApiURL.post(endpoint.rodo_resource.members.ask, formData)
  return res?.data
}

// DECLARATION REQUESTS
export const getResourceDeclarationsList = async () => {
  const res = await secureBaseApiURL.get(endpoint.rodo_resource.declarations.list)
  return res?.data
}

export const getResourceDeclarationCriteria = async () => {
  const res = await secureBaseApiURL.get(endpoint.rodo_resource.declarations.criteria)
  return res?.data
}

export const postResourceDeclarationReq = async (data: IRodoReq[]) => {
  const formData = {
    criteria: data,
    withHistory: true,
  }
  const res = await secureBaseApiURL.post(endpoint.rodo_resource.declarations.ask, formData)
  return res?.data
}

// UNIVERSAL
export const getResourcePassword = async (id: string) => {
  const res = await secureBaseApiURL.get(endpoint.rodo_resource.password(id))
  return res?.data
}

export const getResourceFile = async (id: string, filename: string) => {
  const res = await secureBaseApiURL.get(endpoint.rodo_resource.file(id), {
    responseType: 'arraybuffer',
  })
  const blob = new Blob([res.data], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  })
  const url = window.URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.href = url
  a.download = 'wyniki_wyszukiwania.zip'
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
  window.URL.revokeObjectURL(url)
}

// RODO ACCESS SECTION
// MEMBER REQUESTS
export const getAccessMembersList = async () => {
  const res = await secureBaseApiURL.get(endpoint.rodo_access.members.list)
  // const usersIds = res?.data?.items.map((item: any) => item.id)
  return res?.data
}

export const getAccessMemberCriteria = async () => {
  const res = await secureBaseApiURL.get(endpoint.rodo_access.members.criteria)
  return res?.data
}

export const postAccessMemberReq = async (data: IRodoAccess) => {
  const res = await secureBaseApiURL.post(endpoint.rodo_access.members.ask, data)
  return res?.data
}

// DECLARATION REQUESTS
export const getAccessDeclarationsList = async () => {
  const res = await secureBaseApiURL.get(endpoint.rodo_access.declarations.list)
  return res?.data
}

export const getAccessDeclarationCriteria = async () => {
  const res = await secureBaseApiURL.get(endpoint.rodo_access.declarations.criteria)
  return res?.data
}

export const postAccessDeclarationReq = async (data: IRodoAccess) => {
  const res = await secureBaseApiURL.post(endpoint.rodo_access.declarations.ask, data)
  return res?.data
}

// UNIVERSAL

export const getAccessPassword = async (id: string) => {
  const res = await secureBaseApiURL.get(endpoint.rodo_access.password(id))
  return res?.data
}

export const getAccessFile = async (id: string, filename: string) => {
  const res = await secureBaseApiURL.get(endpoint.rodo_access.file(id), {
    responseType: 'arraybuffer',
  })
  const blob = new Blob([res.data], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  })
  const url = window.URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.href = url
  a.download = 'wyniki_wyszukiwania.zip'
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
  window.URL.revokeObjectURL(url)
}
