import { IMutationErrorType } from 'types/httpStatus.enum'

export const migrationErrors: IMutationErrorType = {
  mass: {
    u422: 'Część członków nie spełnia kryteriów przeniesienia.',
  },
  removeSection: {
    u422: 'Członek nie jest przypisany do tej Sekcji/Ogniska.',
  },
  addSection: {
    u422: 'Członek jest już przypisany do Sekcji/Ogniska.',
  },
  moveSection: {
    u406: 'Członek jest przypisany do tej Sekcji/Ogniska.',
    u409: 'Istnieje juz  prośba o przeniesienie członka do tej Sekcji/Ogniska.',
    u422: 'Członek nie jest obecnie przypisany do Sekcji/Ogniska.',
  },
  moveBranch: {
    u409: 'Istnieje juz tak prośba o przeniesienie dla tego użytkownika. Należy ją najpierw zaakceptować, odrzucić lub anulować.',
    u422: 'Nieprawidłowy Oddział.',
  },
}
