import { Card } from 'components/Atoms/Card'
import { TextField } from 'components/Atoms/TextField'
import { copyMemberSchema } from 'schemas/memberSchema'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { Button } from 'components/Atoms/Button'
import { Select } from 'components/Atoms/Select'
import { useEffect, useMemo, useState } from 'react'
import { publicBaseApiURL } from 'api'

import { useSelector } from 'react-redux'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { Roles, getRoles, getRolesAsync } from 'features/Role/roleSlice'
import { Alert } from 'components/Atoms/Alert'
import { Spinner } from 'components/Atoms/Spinner'
import { AxiosError } from 'axios'
import { useNavigate } from 'react-router-dom'
import { useMutation, useQuery } from '@tanstack/react-query'
import { queryKeys } from 'constants/queryKeys'
import { getRemoteSearch, getRoleList } from 'fetchers/authFetchers'
import { fetchErrors } from 'constants/fetchErrors'
import SearchAndFetchInput from 'components/Atoms/SearchAndFetchInput/SearchAndFetchInput'
import { getBranchesList } from 'fetchers/branchFetchers'
import useSearchModule from 'hooks/useSearchModule'
import { getRegionsList } from 'fetchers/regionFetchers'
import { getSectionsList } from 'fetchers/sectionFetchers'
import { flatten } from 'lodash'
import { createUser, getUsersList } from 'fetchers/userFetchers'
import useAuth from 'hooks/useAuth'
import { roleById } from 'tools/dictionaryHelper'
import { errorToast } from 'tools/ToastHelpers'
import { getLoadingHandler } from 'tools/queryHelpers'
import LoadingModal from 'components/Atoms/LoadingModal'
import { mutationErrorHandler } from 'tools/errorHandler'

interface IRole {
  label: string
  value: string | number
}
interface IUnit {
  label: string
  value: string | number
}
interface IRoles {
  id: string
  role: IRole
  unit: IUnit
}
interface IForm {
  fullName: string
  phoneNumber: string
  email: string
}
const defaultValueSelector = {
  label: 'Wybierz',
  value: '',
}
const defaultValues = {
  division: '',
  fullName: '',
  phoneNumber: '',
  email: '',
}

// const division = true
// const isLoading = false

export const CopyMemberForm: React.FC = () => {
  const { perPage, setPerPage, filters } = useSearchModule({})
  const [isSending, setIsSending] = useState(false)
  const [roles, setRoles] = useState<IRoles[]>([])
  const [selectedRole, setSelectedRole] = useState<IRole>(defaultValueSelector)
  const [selectedUnit, setSelectedUnit] = useState<IUnit>(defaultValueSelector)

  const [searchParams, setSearchParams] = useState<IForm | null>(null)
  const [selected, setSelected] = useState('')
  const [selectedEmail, setSelectedEmail] = useState('')
  const [error, setError] = useState<string | null>(null)
  const [success, setSuccess] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)
  const rr = useSelector(getRoles)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  // const [rolesList, setRolesList] = useState<IRole[] | []>([])
  const rolesData = useAppSelector((state) => state.rolesReducer.roles)
  const fRoles = flatten(Object.values(rolesData))
  const { userToken, isAllAdmin, isAllOK, isAllOD } = useAuth()

  const isAllow = isAllAdmin() || isAllOD() || isAllOK()

  // DATA
  const {
    data: roleList,
    isLoading: il,
    error: er,
  } = useQuery({
    queryKey: [userToken, queryKeys.roleList],
    queryFn: () => getRoleList(),
  })

  const r: Roles = roleList || { systemwide: [], region: [], branch: [], section: [] }
  useEffect(() => {
    dispatch(getRolesAsync())
  }, [])

  useEffect(() => {
    setSelectedUnit(defaultValueSelector)
  }, [selectedRole])

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<IForm>({
    defaultValues,
    resolver: yupResolver(copyMemberSchema),
  })

  const onSubmit = (data: IForm) => {
    setSearchParams(data)
  }

  const {
    data: data,
    error: remoteSearchError,
    isLoading: remoteUsersIsLoading,
  } = useQuery({
    queryKey: [userToken, queryKeys.remoteSearch, searchParams],
    queryFn: () => {
      if (!searchParams) return []
      const params = {
        name: searchParams.fullName,
        phone: searchParams.phoneNumber,
        email: searchParams.email,
      }
      return getRemoteSearch(params)
    },
  })

  const { data: userData } = useQuery({
    queryKey: [userToken, queryKeys.usersList, selectedEmail],
    queryFn: () => {
      if (!selectedEmail) return { items: [] }
      return getUsersList(1, 100)
    },
  })

  const currentUser = userData?.items
    ? userData?.items.find((i: any) => i.email === selectedEmail)
    : null
  const currentUserRoles = currentUser?.contexts
    ? currentUser?.contexts.map((i: any) => ({
        id: i.role,
        role: {
          label: roleById(i.role, fRoles),
          value: i.role,
        },
        unit: {
          label: i?.unit?.name,
          value: i?.unit?.id,
        },
      }))
    : []

  const selectedRoleUnit = useMemo(() => {
    if (!selectedRole.value) return null

    if (!r) return null

    for (const [key, value] of Object.entries(r)) {
      const role = value.find((i: { role: string; name: string }) => i.role === selectedRole.value)
      if (role) {
        return key
      }
    }

    return null
  }, [selectedRole, userToken])

  const { data: division, isLoading } = useQuery({
    queryKey: [
      userToken,
      queryKeys.branchesList,
      queryKeys.regionList,
      queryKeys.sectionsList,
      filters,
      perPage,
      selectedRoleUnit,
    ],
    queryFn: () => {
      if (selectedRoleUnit === 'branch') {
        return getBranchesList(1, perPage, filters)
      }
      if (selectedRoleUnit === 'region') {
        return getRegionsList(1, perPage, filters)
      }
      if (selectedRoleUnit === 'section') {
        return getSectionsList(1, perPage, filters)
      }
      return null
    },
  })

  const divisionList = (division?.items || []).map((e: { name: string; id: string }) => {
    return { label: e.name, value: e.id }
  })

  const searchMore = () => {
    const count =
      division?.pagination?.countFiltered > 0
        ? division?.pagination?.countFiltered
        : division?.pagination?.count
    if (!isLoading && division?.items?.length < count) setPerPage((prev) => prev + 10)
  }

  const rolesList = useMemo(() => {
    const isArray = Array.isArray(Object.values(r))

    if (!isArray) return []

    return Object.values(r)
      .flat()
      .map((i: { role: string; name: string }) => ({ label: i.name, value: i.role }))
  }, [r])

  const mutate = useMutation({
    mutationFn: async (data: any) => createUser(selected, data),
    onSuccess: () => {
      setIsSending(false)
      if (selectedRole.value && selectedUnit.value) {
        const newRole: IRoles = {
          id: String(roles.length + 1),
          role: selectedRole,
          unit: selectedUnit,
        }

        setRoles((prevRoles) => [...prevRoles, newRole])
        setSelectedRole(defaultValueSelector)
        setSelectedUnit(defaultValueSelector)
      }
      setSuccess('Pomyślnie dodano rolę')
      setLoading(false)
    },
    onError: (error: AxiosError) => {
      setIsSending(false)
      setLoading(false)
      const message = mutationErrorHandler(error, fetchErrors.users)
      setError(message)
      console.error(error)
    },
  })

  // TODO: Marcin Zerknij na ten request bo wydaje mi że problem z rolami
  const handleSubmitRole = async () => {
    setSuccess(null)
    setError(null)
    setLoading(true)
    try {
      setIsSending(true)
      const isSystemWide = selectedRoleUnit === 'systemwide'
      const payload: any = isSystemWide
        ? { systemwideRoles: [selectedRole.value] }
        : { unitRoles: [{ role: selectedRole.value, unit: selectedUnit.value }] }
      mutate.mutate(payload)
    } catch (error: any) {
      console.error(error)
      mutationErrorHandler(null, fetchErrors.users)
    }
  }

  const handleRoleDel = async (role: IRole, unit: IUnit, id: string | number) => {
    setSuccess(null)
    setError(null)
    setLoading(true)

    try {
      const req = await publicBaseApiURL.delete(`user/remove-context/${selected}`, {
        data: {
          role: role.value,
          unit: unit.value,
        },
        headers: {
          'X-Auth-Token': `Bearer ${localStorage.getItem('token')}`,
        },
      })

      setSuccess('Pomyślnie dodano rolę')
      setRoles((prevRoles) => prevRoles.filter((r) => String(r.id) !== String(id)))
    } catch (error) {
      errorToast('Użytkownik został już dodany do systemu')
      setError('Użytkownik został już dodany do systemu')

      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setSelectedRole({ label: 'Wybierz', value: '' })
  }, [userToken])
  const loadingHandler = getLoadingHandler(remoteSearchError, isLoading, !isAllow)
  if (loadingHandler.show) return <LoadingModal {...loadingHandler} />

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card label='Przypisz użytkownika'>
        <div className='grid grid-cols-4 gap-5'>
          <div>
            <TextField
              {...register('fullName')}
              label='Imię i nazwisko'
              placeholder='Imię i nazwisko'
              error={errors.fullName?.message}
            />
          </div>
          <div>
            <TextField
              label='Numer telefonu'
              {...register('phoneNumber')}
              error={errors.phoneNumber?.message}
            />
          </div>
          <div className='mb-5'>
            <TextField label='Email' {...register('email')} />
          </div>
          <div className='flex items-center'>
            <Button
              onClick={() => {
                setSelected('')
                setRoles([])
                setSearchParams(null)
                setError(null)
                setSuccess(null)
              }}
              label='Pobierz dane'
            />
          </div>
          {remoteUsersIsLoading && <Spinner />}
        </div>
        {data?.items && (
          <table className='min-w-full divide-y-reverse divide-slate-300'>
            <thead>
              <tr>
                <th className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0'>
                  Imię i nazwisko
                </th>
                <th className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                  Email:
                </th>
                <th className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                  Telefon:
                </th>
                <th className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>Akcje</th>
              </tr>
            </thead>
            <tbody className='divide-y border-t border-gray-300'>
              {(
                data?.items?.filter((i: any) => (selected ? i.remoteId === selected : i)) || []
              ).map((item: any) => {
                return (
                  <tr key={item.remoteId}>
                    <td
                      className={
                        'whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900 sm:pl-0'
                      }
                    >
                      {item.displayName}
                    </td>
                    <td className={'whitespace-nowrap px-3 py-4 text-sm text-gray-500'}>
                      {item.email}
                    </td>
                    <td className={'whitespace-nowrap px-3 py-4 text-sm text-gray-500'}>
                      {item.phone}
                    </td>
                    <td className={'whitespace-nowrap px-3 py-4 text-sm text-gray-500'}>
                      {!selected && (
                        <Button
                          label='Wybierz'
                          className='flex justify-center'
                          onClick={() => {
                            setSelected(item.remoteId)
                            setSelectedEmail(item.email)
                          }}
                        />
                      )}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        )}
        {selected && (
          <div className='mb-5 grid grid-cols-2 gap-5'>
            <div>
              <Select
                defaultOption={defaultValueSelector}
                token={userToken}
                handleSelect={(val) => setSelectedRole(val)}
                options={rolesList}
                label='Przypisz nową rolę'
                withEmpty
              />
            </div>
            {selectedRoleUnit && selectedRoleUnit !== 'systemwide' ? (
              <div>
                <SearchAndFetchInput
                  externalChangeValue={(val) => setSelectedUnit({ label: val, value: val })}
                  searchMore={searchMore}
                  isLoading={isLoading}
                  name={'division'}
                  label={'Wybierz jednostkę docelową'}
                  register={register}
                  options={divisionList}
                  control={control}
                />

                {/* <Select
                  handleSelect={(val) => setSelectedUnit(val)}
                  options={(division.items || []).map((i:any)=>({label:i.name, value:i.id}))}
                  label='Jednostka struktury'
                  withEmpty
                /> */}
              </div>
            ) : (
              isLoading && (
                <div className='flex items-center justify-center'>
                  <Spinner />
                </div>
              )
            )}

            <div>
              <p className='mb-1 block text-sm font-medium text-gray-700'>{'Przypisane role:'}</p>
              <p className='whitespace-nowrap text-sm text-gray-500'>
                {currentUserRoles
                  .map((i: any) => {
                    return `${i?.role?.label}${i?.unit?.label ? ` (${i?.unit?.label})` : ''}`
                  })
                  .join(', ')}
              </p>
              {/* <ListRoleUnit label='Przypisane role:' roles={currentUserRoles} onRoleDel={handleRoleDel} /> */}
            </div>
          </div>
        )}
        {selected && (
          <div className='flex justify-center gap-5'>
            <Button label='Anuluj' variant='secondary' onClick={() => navigate('/user')} />
            <Button
              label='Zatwierdź'
              disabled={selectedRole && selectedUnit ? false : true || isSending}
              onClick={handleSubmitRole}
            />
          </div>
        )}
        {error && <Alert description={error} headline={'Błąd!!!'} level='red' />}
        {success && <Alert description={success} headline={'Info'} level='blue' />}
        {loading && <Spinner />}
      </Card>
    </form>
  )
}
