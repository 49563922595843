import React from 'react'
import { Card } from '../Card'

type Props = {
  label: string
  rowLength: number
}

const TableLoader = ({ label, rowLength }: Props) => {
  return (
    <Card label={label}>
      <dl className='grid grid-cols-1 text-base/6 sm:grid-cols-[min(50%,theme(spacing.80))_auto] sm:text-sm/6'>
        {[...Array(rowLength)].map((_, index) => (
          <React.Fragment key={index}>
            <dt className='col-start-1 border-t border-zinc-950/5 pt-3 text-zinc-500 first:border-none sm:border-t sm:border-zinc-950/5 sm:py-3'>
              <div className='h-4 w-full max-w-[112px] animate-pulse rounded-md bg-slate-200'></div>
            </dt>
            <dd className='pb-3 pt-1 text-zinc-950 dark:text-white sm:border-t sm:border-zinc-950/5 sm:py-3 dark:sm:border-white/5 sm:[&:nth-child(2)]:border-none'>
              <div className='h-4 w-full max-w-[287px] animate-pulse rounded-md bg-slate-200'></div>
            </dd>
          </React.Fragment>
        ))}
      </dl>
    </Card>
  )
}

export default TableLoader
