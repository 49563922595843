import { publicBaseApiURL } from 'api'
import { endpoint } from 'constants/endpoints'
import { INotify } from 'types/notify'

export const getReasonsList = async () => {
  const res = await publicBaseApiURL.get(endpoint.notify.reasons)
  return res?.data
}

export const getNotificationsList = async (page: number, perPage: number, filters?: string) => {
  const res = await publicBaseApiURL.get(
    endpoint.notify.list(page, perPage, filters ? filters : ''),
  )
  return res?.data
}

export const getNotificationById = async (requestId: string) => {
  const res = await publicBaseApiURL.get(endpoint.notify.byId(requestId))
  return res?.data
}

export const createNotification = async (memberId: string, data: INotify) => {
  const res = await publicBaseApiURL.post(endpoint.notify.create(memberId), data)
  return res?.data
}

export const handleNotification = async (requestId: string, data: any) => {
  const res = await publicBaseApiURL.post(endpoint.notify.handle(requestId), data)
  return res?.data
}

export const removeNotification = async (requestId: string) => {
  const res = await publicBaseApiURL.delete(endpoint.notify.remove(requestId))
  return res?.data
}
