import { HTMLAttributes, ReactNode } from 'react'

export interface IProps extends HTMLAttributes<HTMLButtonElement> {
  variant?: 'primary' | 'secondary' | 'third'
  label?: string | ReactNode
  icon?: React.ReactNode
  className?: string
  disabled?: boolean
  type?: 'button' | 'submit' | 'reset'
}

export const Button: React.FC<IProps> = ({
  variant = 'primary',
  label,
  icon,
  className,
  disabled,
  type,
  ...props
}) => {
  const generateClassButton = () => {
    switch (variant) {
      case 'primary':
        return 'inline-flex items-center rounded border border-transparent bg-indigo-600 px-3 py-2 text-xs font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
      case 'secondary':
        return 'inline-flex items-center rounded border border-transparent bg-indigo-100 px-3 py-2 text-xs font-medium text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
      case 'third':
        return 'inline-flex items-center rounded border border-gray-300 bg-white px-3 py-2 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
      default:
        return 'inline-flex items-center rounded border border-transparent bg-indigo-600 px-3 py-2 text-xs font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
    }
  }
  const buttonClass = className ? `base-button ${className}` : 'base-button'
  return (
    <button
      type={type}
      className={`${buttonClass} ${generateClassButton()} text-center disabled:cursor-not-allowed disabled:border-[1px] disabled:border-slate-200 disabled:bg-slate-50 disabled:text-slate-500 disabled:shadow-none`}
      {...props}
      disabled={disabled}
    >
      {icon && icon}
      {label && label}
    </button>
  )
}
