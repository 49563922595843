import React, { InputHTMLAttributes } from 'react'

export interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string
  id?: string
}

export const Checkbox = React.forwardRef<HTMLInputElement, IProps>(
  ({ label, id, ...props }, ref) => (
    <div className='flex items-start'>
      <div className='flex h-5 items-center'>
        <input
          id={id}
          aria-describedby='comments-description'
          name='comments'
          type='checkbox'
          className='h-4 w-4 cursor-pointer rounded border-gray-300 text-indigo-600 focus:ring-indigo-500'
          ref={ref}
          {...props}
        />
      </div>
      {label && (
        <div className='ml-3 text-sm'>
          <label htmlFor={id} className='cursor-pointer font-medium text-gray-700'>
            {label}
          </label>
        </div>
      )}
    </div>
  ),
)
