import { Typography } from 'components/Atoms/Typography'
import { Select } from 'components/Atoms/Select'
import {
  TrashIcon,
  CheckCircleIcon,
  MinusCircleIcon,
  PlusCircleIcon,
} from '@heroicons/react/20/solid'
import { Button } from 'components/Atoms/Button'
import { useForm, useFieldArray, Controller } from 'react-hook-form'

export const Item = (props: any) => {
  const { handleSubmit, register, control } = useForm({
    defaultValues: {
      obligationJob: [
        {
          institution: { label: 'Szkoła podstawowa nr 25', value: '1' },
          position: { label: 'Nauczyciel', value: '1' },
          fte: { label: 'Nauczyciel', value: '1' },
        },
      ],
    },
  })
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'obligationJob',
  })

  const onSubmit = (data: any) => {
    console.info('data', data)
  }

  return (
    <div className='mt-5 bg-white p-5'>
      <Typography className='mb-5'>
        {props.firstName} {props.lastName}
      </Typography>

      <form onSubmit={handleSubmit(onSubmit)} className='flex items-center justify-between'>
        <div className='flex flex-col items-end'>
          {fields.map((field, index) => (
            <div className='flex items-center' key={field.id}>
              <div className='mb-5 grid grid-cols-3 gap-5'>
                <Select
                  label='Placówka'
                  options={[
                    { label: 'Szkoła podstawowa nr 25', value: '1' },
                    { label: 'Szkoła podstawowa nr 135', value: '2' },
                  ]}
                />
                <Select
                  label='Stanowisko'
                  options={[
                    { label: 'Nauczyciel', value: '1' },
                    { label: 'Dyrektor', value: '2' },
                  ]}
                />
                <Select
                  label='Wymiar etatu'
                  options={[
                    { label: '1/2 etatu', value: '1' },
                    { label: '1/3 etatu', value: '2' },
                    { label: 'Pełen etat', value: '3' },
                  ]}
                />
              </div>
              <Button
                className='ml-5'
                icon={<MinusCircleIcon className='w-5' />}
                onClick={() => remove(index)}
              />
            </div>
          ))}

          <div className='ml-10'>
            <Button
              label='Dodaj placówkę'
              icon={<PlusCircleIcon className='mr-2 w-5' />}
              onClick={() =>
                append({
                  institution: { label: 'Szkoła podstawowa nr 25', value: '1' },
                  position: { label: 'Nauczyciel', value: '1' },
                  fte: { label: 'Nauczyciel', value: '1' },
                })
              }
            />
          </div>
        </div>

        <div className='flex'>
          <CheckCircleIcon className='mr-2' width='25px' color='green' />
          <TrashIcon width='25px' color='#666' />
        </div>
      </form>
    </div>
  )
}
