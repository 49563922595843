import React, { useState } from 'react'
import { Button } from 'components/Atoms/Button'
import { Card } from 'components/Atoms/Card'
import { ConfirmData } from 'components/StatisticalObligation/ConfirmData'
import { Typography } from 'components/Atoms/Typography'
import { Select } from 'components/Atoms/Select'
import { Table } from 'components/Atoms/Table'

const newMember = [
  {
    name: 'Lindsay Walton',
  },
  {
    name: 'Emma Dorsey',
  },
  {
    name: 'Tom Cook',
  },
]
const leftMember = [
  {
    name: 'Whitney Francis',
  },
  {
    name: 'Courtney Henry',
  },
]

export const StatisticalObligationConfirm = () => {
  const [isOK, setIsOK] = useState(false)

  const data = React.useMemo(
    () => [
      {
        number: '1',
        person: 'Stanisław Jarmuszewicz',
        institution: 'Szkoła podstawowa 154',
        position: 'Nauczyciel Mianowany',
        workingTime: 'full',
      },
      {
        number: '2',
        person: 'Małgorzata Jastrzebska',
        institution: 'Szkoła podstawowa 42',
        position: 'Nauczyciel Mianowany',
        workingTime: '1/2',
      },
    ],
    [],
  )

  const columns = React.useMemo(
    () => [
      {
        Header: 'Nr',
        accessor: 'number',
      },
      {
        Header: 'Osoby',
        accessor: 'person',
      },
      {
        Header: 'Placówka',
        accessor: 'institution',
      },
      {
        Header: 'Stanowisko',
        accessor: 'position',
      },
      {
        Header: 'Wymiar pracy',
        accessor: 'workingTime',
      },
    ],
    [],
  )

  const membersLength = (): any[] => {
    if (newMember.length > leftMember.length) return newMember

    return leftMember
  }

  if (isOK) return <ConfirmData />

  return (
    <>
      <Card label='Ognisko X'>
        <div className='flex justify-between'>
          <div>
            <Typography>Obowiązek - bieżący (w trakcje zbierania)</Typography>
            <Typography>Autor aktualnej wersji: Anna Kaczmarska</Typography>
            <Typography>Data zmiany: 3 miesiące temu (15:33) 2023-01-23</Typography>
          </div>
          <div>
            <Typography>Archiwum wypełnionych (11)</Typography>
            <div className='mt-2'>
              <Select
                label='Przełącz na'
                options={[{ label: 'Obowiązek nr 11 2023', value: '11/2023' }]}
              />
            </div>
          </div>
        </div>
      </Card>
      <Card label='Podsumowanie zgłoszenia'>
        <div className='grid w-2/6 grid-cols-5'>
          <div className='col-span-1'></div>
          <p className='col-span-2'>+ 4 osoby</p>
          <p className='col-span-2'>- 1 osoby</p>
        </div>
        <div className='mt-10'>
          <div className='grid w-2/6 grid-cols-5 border-b'>
            <div className='col-span-1'>Nr</div>
            <div className='col-span-2 border-l p-2'>Doszły osoby</div>
            <div className='col-span-2 border-l p-2'>Odeszły Y:</div>
          </div>
          <div className='grid w-2/6 grid-cols-5'>
            <div className='col-span-1'>
              {membersLength().map((item, index) => (
                <div className='border-b p-2' key={index}>
                  {index + 1}
                </div>
              ))}
            </div>
            <div className='col-span-2 border-l'>
              {newMember.map((item) => (
                <div className='border-b p-2' key={item.name}>
                  {item.name}
                </div>
              ))}
            </div>
            <div className='col-span-2 border-l'>
              {leftMember.map((item) => (
                <div className='border-b p-2' key={item.name}>
                  {item.name}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className='flex justify-center'>
          <Button label='Wróć i popraw' variant='secondary' className='mr-5' />
          <Button label='Zatwierdź' onClick={() => setIsOK(true)} />
        </div>
      </Card>

      <Card label='Lista osób'>
        <Table columns={columns} data={data} />
        {/* <p>to jest lista osób...</p> */}
      </Card>
    </>
  )
}
