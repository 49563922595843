import { useAuth } from 'hooks/useAuth'
import { LoadingSection } from 'components/Atoms/LoadingSection'
import { Table } from 'components/Atoms/Table'
import { Modal } from 'components/Atoms/Modal'
import { Spinner } from 'components/Atoms/Spinner'
import { Card } from 'components/Atoms/Card'
import { Button } from 'components/Atoms/Button'
import { useMutation, useQuery } from '@tanstack/react-query'
import { queryKeys } from 'constants/queryKeys'
import {
  getExportFile,
  getExportPassword,
  getMemberExportDictionary,
  getMemberExportList,
} from 'fetchers/exportFetchers'
import { useLastPage } from 'hooks/useLastPage'
import { HoverIcon } from 'components/Atoms/HoverIcon'
import { useNavigate } from 'react-router-dom'
import useSearch from 'hooks/useSearch'
import { errorQuery } from 'tools/queryHelpers'
import React, { useState } from 'react'
import { getUsersList } from 'fetchers/userFetchers'
import { getSectionsList } from 'fetchers/sectionFetchers'
import { AxiosError } from 'axios'
import { mutationErrorHandler } from 'tools/errorHandler'
import { exportFileErrors, exportPasswordErrors } from 'error-data/export'
import { exportFileUpload } from 'tools/exportFileUpload'
import { IMemberListing } from 'types/member'
import TableSkeleton from 'components/Atoms/Skeleton/TableSkeleton'
import { Typography } from 'components/Atoms/Typography'
import { Select } from 'components/Atoms/Select'
import { IOption } from 'types/form'
import { Controller, useForm } from 'react-hook-form'
import { Checkbox } from 'components/Atoms/Checkbox'
import { path } from 'constants/path'
import { Pagination } from 'components/Atoms/Pagination'
import { successToast } from 'tools/ToastHelpers'

const searchParams = {
  simpleParams: [
    'search',
    'searchExtended',
    'email',
    'pesel',
    'sex',
    'card',
    'institutionLimit',
    'regionLimit',
  ],
  arrayParams: [
    'institution',
    'subject',
    'region',
    'branch',
    'section',
    'position',
    'unitPosition',
    'tag',
  ],
}

export const MemberExportList = () => {
  const [generatedById, setGeneratedById] = useState<string>('')
  const [searchedSectionId, setSearchedSectionId] = useState<string>('')
  const [isSending, setIsSending] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [passwordId, setPasswordId] = useState('')
  const [selectedIds, setSelectedIds] = useState<IMemberListing[]>([])

  const { userToken, unitContext, userLoaded, isPOG, isAllOD, isAllOK, isAllAdmin } = useAuth()
  const { control } = useForm()
  const navigate = useNavigate()

  const closeModal = () => {
    setIsOpen(false)
    setPasswordId('')
  }

  const { filters, perPage, page, setLimit, changePage } = useSearch(searchParams)
  const id = unitContext?.id

  // EXPORT LOADER
  const {
    data: memberExportList,
    isLoading: membersExportLoading,
    refetch: memberExportRefetch,
  } = useQuery({
    queryKey: [userToken, queryKeys.memberExportList, filters, perPage, page, id],
    queryFn: () => getMemberExportList(page, perPage, filters),
    retry: errorQuery,
  })

  const { data: exportDictionary, isLoading: isLoadingExportDictionary } = useQuery({
    queryKey: [userToken, queryKeys.memberExportDictionary, id],
    queryFn: getMemberExportDictionary,
    retry: errorQuery,
  })

  const { data: userData } = useQuery({
    queryKey: [userToken, queryKeys.usersList, generatedById],
    queryFn: () => {
      return getUsersList(1, 100)
    },
  })

  const { data: sectionData, isLoading: isLoadingSectionId } = useQuery({
    queryKey: [userToken, queryKeys.section],
    queryFn: () => {
      return getSectionsList(0, 1000)
    },
  })

  const lastPage = useLastPage(memberExportList?.pagination, membersExportLoading)
  const generatedExportsList = memberExportList?.items || []

  const getUserEmailById = (userId: string) => {
    const user = userData?.items?.find((user: any) => user.id === userId)

    return user?.email || user?.displayName || user?.firstName + ' ' + user?.lastName || 'Nieznany'
  }

  const translateFieldNames = (fields: string[]) => {
    const fieldMap = exportDictionary?.items.reduce((acc: any, item: any) => {
      acc[item.id] = item.name
      return acc
    }, {})

    return fields.map((field) => fieldMap?.[field] || field).join(', ')
  }

  const translateSectionNames = (units: string[]) => {
    const sectionMap = sectionData?.items.reduce((acc: any, item: any) => {
      acc[item.id] = item.name
      return acc
    }, {})
    return units.map((unit) => (sectionMap && sectionMap[unit]) || unit).join(', ')
  }

  const password = useMutation({
    mutationFn: (id: any) => getExportPassword(id),
    onSuccess: (req) => {
      setPasswordId(req.password)
      setIsSending(false)
    },
    onError: (error: AxiosError) => {
      setIsSending(false)
      console.error(error)
      mutationErrorHandler(error, null, exportPasswordErrors.get.message)
    },
  })

  const file = useMutation({
    mutationFn: ({ id, filename }: { id: string; filename: string }) => getExportFile(id, filename),
    onSuccess: (res) => {
      setIsSending(false)
      exportFileUpload(res)
    },
    onError: (error: AxiosError) => {
      setIsSending(false)
      console.error(error)
      mutationErrorHandler(error, null, exportFileErrors.get.message)
    },
  })

  const passwordHandler = (id: string) => {
    try {
      setIsOpen(true)
      setIsSending(true)
      password.mutate(id)
    } catch (error) {
      setIsSending(false)
      console.error('Error', error)
      mutationErrorHandler(null, null, exportPasswordErrors.get.message)
    }
  }

  const fileHandler = ({ id, filename }: { id: string; filename: string }) => {
    try {
      setIsSending(true)
      file.mutate({ id, filename })
    } catch (error) {
      setIsSending(false)
      console.error('Error', error)
      mutationErrorHandler(null, null, exportFileErrors.get.message)
    }
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'Nazwa pliku',
        accessor: 'fileName',
        Cell: ({ row }: any) => {
          return row.original?.name
        },
      },

      {
        Header: 'Wygenerowane przez',
        accessor: 'createdBy',
        Cell: ({ row }: any) => getUserEmailById(row.original?.createdBy),
      },
      {
        Header: 'Dane',
        accessor: 'data',
        Cell: ({ row }: any) => translateFieldNames(row.original?.fields),
      },
      {
        Header: 'Jednostka',
        accessor: 'unit',
        Cell: ({ row }: any) => translateSectionNames(row.original?.units),
      },
      {
        Header: 'Data utworzenia',
        accessor: 'createdAt',
        Cell: ({ row }: any) => {
          return new Date(row.original?.createdAt).toISOString().split('T')[0].split('-').join('-')
        },
      },
      {
        Header: 'Data wygaśnięcia',
        accessor: ' expiresAt',
        Cell: ({ row }: any) => {
          return new Date(row.original?.expiresAt).toISOString().split('T')[0].split('-').join('-')
        },
      },
      {
        Header: 'Pobierz hasło',
        accessor: 'password',
        Cell: ({ row }: any) => {
          return (
            <Button
              disabled={row.original.status !== 'completed' || isSending}
              onClick={() => {
                passwordHandler(row.original?.resultsFile?.id)
              }}
              label='Pobierz hasło'
              variant='secondary'
            />
          )
        },
      },
      {
        Header: 'Akcje',
        accessor: 'action',
        Cell: ({ row }: any) => {
          return (
            <div className='flex'>
              <HoverIcon
                iconName='EyeIcon'
                title='Zobacz'
                onClick={() => {
                  navigate(`/export/${row.original?.id}`)
                }}
              />
              <HoverIcon
                disabled={row.original.status !== 'completed' || isSending}
                iconName='ArrowDownTrayIcon'
                title='Pobierz plik'
                onClick={() => {
                  fileHandler(row.original?.resultsFile)
                }}
              />
            </div>
          )
        },
      },
    ],
    [selectedIds, exportDictionary, userData, sectionData, isSending],
  )

  const onLimitHandler = (e: IOption) => setLimit(e.value)

  if (!userLoaded) return <LoadingSection />

  return (
    <Card
      label='Wygenerowane listy eksportu'
      actionsButton={[
        {
          label: 'Utwórz eksport',
          handleClick: () => navigate(path.export.request),
        },
      ]}
    >
      <div className='flex items-center justify-between gap-4'>
        {lastPage && (
          <>
            <div className='flex flex-col justify-end gap-4 md:flex-row'>
              <div className='flex items-center gap-4'>
                <Typography weight='semibold' className='text-sm'>
                  Liczba wierszy na stronę
                </Typography>
                <Select
                  handleSelect={onLimitHandler}
                  options={[
                    { label: '10', value: 10 },
                    { label: '20 ', value: 20 },
                  ]}
                />
              </div>
            </div>

            <span className='flex flex-col items-center gap-2 md:flex-row md:gap-4'>
              {lastPage > 1 && (
                <Pagination lastPage={lastPage} currentPage={page} handlePageChange={changePage} />
              )}
            </span>
          </>
        )}
      </div>
      {!membersExportLoading && (
        <>
          <Table maxColumnWidth='300px' columns={columns} data={generatedExportsList || []} />

          <Modal isOpen={isOpen} handleClose={closeModal}>
            <Card
              actionsButton={[
                {
                  label: 'Skopiuj hasło',
                  handleClick: () => {
                    navigator.clipboard.writeText(passwordId)
                    successToast('Hasło skopiowane do schowka')
                  },
                },
              ]}
              label='Hasło do pliku'
            >
              <div>
                <div className='p-6'>
                  {password.isPending ? (
                    <div className='mx-auto h-6 w-[300px] animate-pulse rounded-md bg-slate-300'></div>
                  ) : (
                    <p className='text-center'>{passwordId}</p>
                  )}
                </div>
                <div className='flex justify-center'>
                  <Button variant='secondary' label={'Zamknij'} onClick={closeModal} />
                </div>
              </div>
            </Card>
          </Modal>
        </>
      )}
      {membersExportLoading && <TableSkeleton />}
    </Card>
  )
}
