import FilterActionMenu from 'components/Atoms/FilterElements/FilterActionMenu'
import FilterSearchInput from 'components/Atoms/FilterElements/FilterSearchInput'
import FilterSelectWindow from 'components/Atoms/FilterElements/FilterSelectWindow'
import { xor } from 'lodash'
import { useEffect, useState } from 'react'
import { slugify } from 'tools/queryHelpers'

type FilterQuery = {
  [key: string]: string
}

type Props = {
  disabled?: boolean
  label: string
  filterQuery: string
  setSearchQuery: React.Dispatch<React.SetStateAction<FilterQuery>>
  dataList: any[]
  selectedList: string[]
  setSelectedList: React.Dispatch<React.SetStateAction<string[]>>
}
export const FilterSection = ({
  disabled,
  label,
  filterQuery,
  setSearchQuery,
  dataList,
  selectedList,
  setSelectedList,
}: Props) => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false)
  const queryHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setSearchQuery((prev: FilterQuery) => {
      return {
        ...prev,
        [name]: value,
      }
    })
  }

  const handleUnCheckAll = () => setSelectedList([])
  const handleCheckAll = () => setSelectedList(dataList.map((e) => e.value))

  const handleInputCheck = (value: string) => {
    setSelectedList((prev: string[]) => xor(prev, [value]))
  }

  const filterHandler = () => {
    return dataList
      .filter((e) => {
        const validQuery = slugify(e.value).includes(filterQuery)
        const selected = selectedList.includes(e.id)
        return validQuery || selected
      })
      .sort((a, b) => {
        const isSelectedA = selectedList.includes(a.id)
        const isSelectedB = selectedList.includes(b.id)

        if (isSelectedA && !isSelectedB) {
          return -1
        } else if (!isSelectedA && isSelectedB) {
          return 1
        } else {
          return 0
        }
      })
  }
  const filteredData = filterHandler()
  return (
    <section className='w-full'>
      <FilterSearchInput label={label} name={label} queryHandler={queryHandler} />
      <FilterActionMenu handleCheckAll={handleCheckAll} handleUnCheckAll={handleUnCheckAll} />
      <FilterSelectWindow
        keyName={label}
        filteredData={filteredData}
        selectedData={selectedList}
        handleInputCheck={handleInputCheck}
      />
    </section>
  )
}

export default FilterSection
