import { publicBaseApiURL } from 'api'
import { endpoint } from 'constants/endpoints'
import { setupFilters } from 'tools/queryHelpers'
import { IFilters } from 'types/form'
import { ILegal } from 'types/legal'

export const getLegalList = async (page: number, count: number, filters?: IFilters[]) => {
  const filterData = setupFilters(filters)
  const res = await publicBaseApiURL.get(endpoint.legal.list(page, count, filterData))
  return res?.data
}

export const getLegal = async (id: string) => {
  const res = await publicBaseApiURL.get(endpoint.legal.byId(id))
  return res?.data
}

export const createLegal = async (data: ILegal) => {
  const res = await publicBaseApiURL.post(endpoint.legal.create, data)
  return res?.data
}

export const updateLegal = async (id: string, data: ILegal) => {
  const res = await publicBaseApiURL.put(endpoint.legal.update(id), data)
  return res?.data
}
