import { Card } from 'components/Atoms/Card'
import { LabelTypography } from 'components/Atoms/LabelTypography'
import { setupFullDate } from 'tools/formTools'
import { ICardReq } from 'types/cardRequest'

type Props = {
  showDetails?: boolean
  toggleDetails: () => void
  data?: ICardReq
  type: any
  status: any
  branch: string
}

export const CardReqDetailsInfo = ({
  data,
  type,
  status,
  branch,
  showDetails,
  toggleDetails,
}: Props) => {
  return (
    <Card
      label='Dane wniosku'
      actionsButton={[
        {
          label: showDetails ? 'Ukryj dane' : 'Pokaż dane',
          handleClick: toggleDetails,
        },
      ]}
    >
      <div className='mb-4 grid grid-cols-6 gap-4'>
        <LabelTypography label='Data utworzenia' text={setupFullDate(data?.createdAt)} />
        <LabelTypography label='Typ wniosku' text={type} />
        <LabelTypography label='Status' text={status} />
      </div>
      <div className='grid grid-cols-6 gap-4'>
        <LabelTypography label='Imię' text={data?.member?.firstName} />
        <LabelTypography label='Nazwisko' text={data?.member?.lastName} />
        <LabelTypography label='Okręg' text={branch} />
        <LabelTypography label='Oddział' text={data?.branch?.name} />
      </div>
    </Card>
  )
}

export default CardReqDetailsInfo
