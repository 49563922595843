import { publicBaseApiURL } from 'api'
import { endpoint } from 'constants/endpoints'

export const getCensusList = async (page: number, pageSize: number, filters: any) => {
  const res = await publicBaseApiURL.get(
    `${endpoint.census.list}?page=${page}&perPage=${pageSize}${filters || ''}`,
    {},
  )
  return res?.data
}

export const createCensus = async (data: any) => {
  const res = await publicBaseApiURL.post(endpoint.census.create, data)
  return res?.data
}


export const getCensusDetails = async (id: string) => { 
  const res = await publicBaseApiURL.get(endpoint.census.byId(id), {})
  return res?.data
}
