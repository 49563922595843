import { Table } from 'components/Atoms/Table'
import React from 'react'
import { EyeIcon } from '@heroicons/react/20/solid'
import { Card } from 'components/Atoms/Card'
import { IBranchList } from 'types/branch'
import { useNavigate } from 'react-router-dom'
import { wipMessage } from 'tools/ToastHelpers'
import { HoverIcon } from 'components/Atoms/HoverIcon'
import { TableName } from 'components/Atoms/TableName'
interface IProps {
  data: IBranchList
}
export const BranchSections: React.FC<IProps> = ({ data }) => {
  const navigate = useNavigate()
  const showHandler = (id: string) => navigate(`/section/${id}`)
  const sectionsList = data?.items ? data?.items : []
  const columns = React.useMemo(
    () => [
      {
        Header: 'Nazwa ogniska',
        accessor: 'name',
        Cell: (row: any) => <TableName name={row.row.original.name} />,
      },
      {
        Header: 'Lokalizacja ogniska',
        accessor: 'location',
      },
      {
        Header: 'Adres ogniska',
        accessor: 'address',
      },
      {
        Header: 'Prezes',
        accessor: 'president',
      },
      {
        Header: 'Akcje',
        accessor: 'action',
        Cell: (row: any) => {
          return (
            <div className='flex'>
              <HoverIcon
                iconName='EyeIcon'
                title='Zobacz'
                onClick={() => showHandler(row.row.original.id)}
              />
              <HoverIcon iconName='TrashIcon' title='Usuń' onClick={() => wipMessage()} />
            </div>
          )
        },
      },
    ],
    [],
  )

  if (sectionsList.length === 0) {
    return (
      <Card label='Powiązane ogniska'>
        <p>Brak powiązanych ognisk</p>
      </Card>
    )
  }

  return (
    <Card label='Powiązane ogniska'>
      <Table data={data?.items ? data?.items : []} columns={columns} />
    </Card>
  )
}
