import { Button } from 'components/Atoms/Button'
import { Card } from 'components/Atoms/Card'
import { Typography } from 'components/Atoms/Typography'

interface IProps {
  setDisplaySummary: () => void
}

export const ReadyToApprove = ({ setDisplaySummary }: IProps) => {
  return (
    <Card className='p-10'>
      <Typography className='text-center uppercase'>
        wszyscy potwierdzeni teraz musisz potwierdzić zgłoszenie
      </Typography>
      <div className='mt-5 flex justify-center'>
        <Button label='ZATWIERDŹ' onClick={setDisplaySummary} />
      </div>
    </Card>
  )
}
