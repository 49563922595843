import { publicBaseApiURL } from 'api'
import { endpoint } from 'constants/endpoints'
import { IFilters } from 'types/form'

export const getUsersList = async (page: number, count: number, filters?: IFilters[]) => {
  const transFilters = {}

  filters?.forEach((obj) => {
    const key = Object.keys(obj)[0]
    const value = obj[key]
    Object.assign(transFilters, { [key]: value })
  })
  const data = {
    region: [],
    branch: [],
    section: [],
    search: '',
    role: [],
    ...transFilters,
  }
  const res = await publicBaseApiURL.post(endpoint.users.listPaginated(page, count, ''), data)
  return res?.data
}

export const getAllUsers = async () => {
  const res = await publicBaseApiURL.get(endpoint.users.list)
  console.log({ res })
  return res?.data
}

export const createUser = async (id: string, data: any) => {
  const res = await publicBaseApiURL.post(endpoint.user.create(id), data)
  return res?.data
}

export const getPositionsList = async () => {
  const res = await publicBaseApiURL.get(endpoint.user.roleGlobal, {})
  return res?.data
}

export const getUserById = async (id: string) => {
  const res = await publicBaseApiURL.get(endpoint.users.singleUserById(id))
  return res?.data
}

export const deleteUserRole = async (id: string) => {
  const res = await publicBaseApiURL.delete(endpoint.user.deleteUserRole(id))
  return res?.data
}
