import { Table } from 'components/Atoms/Table'
import UnitPositionsActionCell from './UnitPositionsActionCell'
import { useMemo } from 'react'
import { getTermLength, yearFlex } from 'tools/dictionaryHelper'
import { TableName } from 'components/Atoms/TableName'

type Props = {
  unitPositions: any
  structureTypesData: any
}

export const UnitPositonsList = ({ unitPositions, structureTypesData }: Props) => {
  const columns = useMemo(
    () => [
      {
        Header: 'Nazwa funkcji',
        accessor: 'name',
        Cell: (row: any) => <TableName name={row.row.original.name} />,
      },
      {
        Header: 'Poziom',
        accessor: 'unitType',
        Cell: (row: any) => {
          const { label } = row.row.original
          return <p>{label}</p>
        },
      },
      {
        Header: 'Czas kadencji',
        accessor: 'termLength',
        Cell: (row: any) => {
          const { lengthOfTerm, lengthOfTermUnit } = row.row.original
          return <p>{getTermLength(lengthOfTermUnit, lengthOfTerm)}</p>
        },
      },
      {
        Header: 'Maksymalna liczebność',
        accessor: 'maxNumber',
        Cell: (row: any) => {
          const { maxNumber } = row.row.original
          return <p>{maxNumber ? maxNumber : '-'}</p>
        },
      },
      {
        Header: 'Akcje',
        accessor: 'action',
        Cell: (row: any) => {
          const { id } = row.row.original
          return <UnitPositionsActionCell id={id} />
        },
      },
    ],
    [],
  )
  return <Table columns={columns} data={unitPositions} maxColumnWidth={'300px'} />
}

export default UnitPositonsList
