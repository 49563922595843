import { publicBaseApiURL } from 'api'
import { endpoint } from 'constants/endpoints'
import { ICardReq, ICardReqTypeDict } from 'types/cardRequest'
import { IMemberCardsRequest } from 'types/member'

interface IList {
  id: string
}

// TYPES
export const getCardReqTypeDict = async () => {
  const res = await publicBaseApiURL.get(endpoint.card.request.dict.type)
  return res?.data
}

export const getCardReqStatusDict = async () => {
  const res = await publicBaseApiURL.get(endpoint.card.request.dict.status)
  return res?.data
}

export const getCardReqEventDict = async () => {
  const res = await publicBaseApiURL.get(endpoint.card.request.dict.event)
  return res?.data
}

// CARD REQUESTS
export const createCard = async (memberId: string, type: string, comment: string) => {
  const res = await publicBaseApiURL.post(endpoint.card.request.create(memberId, type), { comment })
  return res?.data
}

export const createCardMass = async (data: IMemberCardsRequest) => {
  const { type, ...form } = data
  const res = await publicBaseApiURL.post(endpoint.card.request.createMass(type), form)
  return res?.data
}

export const checkCardReq = async (items: IList[], type: string) => {
  const res = await publicBaseApiURL.post(endpoint.card.request.check(type), { items })
  return res?.data
}

// CARD FETCHERS

export const getCardsRequestList = async (page: number, count: number, filters?: string) => {
  const res = await publicBaseApiURL.get(
    `${endpoint.card.request.list}?page=${page}&perPage=${count}&${filters || ''}`,
  )
  return res?.data
}

export const getCardsList = async (page: number, count: number, filters?: string) => {
  const res = await publicBaseApiURL.get(
    `${endpoint.card.list}?page=${page}&perPage=${count}&${filters || ''}`,
  )
  return res?.data
}

export const getCardStatusList = async () => {
  const res = await publicBaseApiURL.get(endpoint.card.statusTypes)
  return res?.data
}

export const getCard = async (id: string, showDetails?: boolean) => {
  const details = showDetails ? '?unredacted=true' : ''
  const res = await publicBaseApiURL.get(endpoint.card.getCard(id, details))
  return res?.data
}

export const getCardRequest = async (id: string, showDetails?: boolean) => {
  const details = showDetails ? '?unredacted=true' : ''
  const res = await publicBaseApiURL.get(endpoint.card.request.show(id, details))
  return res?.data as ICardReq
}

// REQUEST FETCHERS
const getReqType = (type: ICardReqTypeDict) => {
  if (type === 'new') return 'new'
  if (type === 'accepted') return 'accept'
  if (type === 'printed') return 'print'
  if (type === 'in_branch') return 'send-to-branch'
  if (type === 'issued') return 'issue'
  if (type === 'rejected') return 'reject'
  return type
}
export const getCarReqdUpdate = async (type: ICardReqTypeDict, comment: string, ids: IList[]) => {
  const data = {
    comment,
    items: ids.map((item) => ({ id: item.id })),
  }
  const reqType = getReqType(type)
  if (reqType === 'print') {
    const res = await publicBaseApiURL.post(endpoint.card.request.update('print'), data, {
      responseType: 'arraybuffer',
    })
    return res
  }
  if (reqType === 'new') {
    const res = await publicBaseApiURL.post(endpoint.card.request.update(data.items[0].id))
    return res?.data
  }
  const res = await publicBaseApiURL.post(endpoint.card.request.update(reqType), data)
  return res?.data
}

export const getCardPrint = async (ids: IList[]) => {
  const data = {
    items: ids.map((item) => ({ id: item.id })),
  }
  const res = await publicBaseApiURL.post(endpoint.card.print, data, {
    responseType: 'arraybuffer',
  })
  return res
}

export const changeCardReqType = async (type: string, comment: string, cardReqId: string) => {
  const data = { comment, type }
  const res = await publicBaseApiURL.put(endpoint.card.request.changeType(cardReqId), data)
  return res?.data
}
