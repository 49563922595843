import { useAuth } from 'hooks/useAuth'
import { LoadingSection } from 'components/Atoms/LoadingSection'
import { MemberExportRequest } from './MemberExportRequest'

export const MemberExportView = () => {
  const { userToken, unitContext, userLoaded, isPOG, isAllOD, isAllOK, isAllAdmin } = useAuth()
  const isRegion = isAllOK()
  const isBranch = isAllOD()
  // const isSection = isPOG()
  const isAdmin = isAllAdmin()

  if (!userLoaded) return <LoadingSection />
  // if (isSection) return <ErrorRoleModal />

  return (
    <>
      <MemberExportRequest
        isRegion={isRegion}
        isBranch={isBranch}
        isAdmin={isAdmin}
        userToken={userToken}
        unitContext={unitContext}
        userLoaded={userLoaded}
      />
    </>
  )
}
