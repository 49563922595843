import { useEffect, useState } from 'react'
import { getLastPage } from 'tools/queryHelpers'
import { IPagination } from 'types/form'

export const useLastPage = (pagination: IPagination, isLoading: boolean) => {
  const [lastPage, setLastPage] = useState(1)
  const data = getLastPage(pagination)
  useEffect(() => {
    if (!isLoading && data?.lastPage) {
      setLastPage(data.lastPage)
    }
  }, [data, isLoading])
  return lastPage
}
