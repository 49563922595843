import { endpoint } from 'constants/endpoints'
import { publicBaseApiURL } from 'api'
import { IBranchMassMoveRequest } from 'types/branch'
import { ISectionMassMoveRequest } from 'types/section'

// BRANCH MIGRATIONS - LIST
export const getMemberBranchMigrations = async (page: number, count: number, filters?: string) => {
  const res = await publicBaseApiURL.get(
    endpoint.migration.branch.list(page, count, filters ? filters : ''),
  )
  return res?.data
}
// BRANCH MIGRATIONS - MASS
export const moveMassMemberToBranch = async (data: IBranchMassMoveRequest) => {
  const { branchId, ...form } = data
  const res = await publicBaseApiURL.post(endpoint.migration.branch.mass.create(branchId), form)
  return res?.data
}

// SECTION MIGRATIONS - MASS
export const moveMassMemberToSection = async (data: ISectionMassMoveRequest) => {
  const { sectionId, ...form } = data
  const res = await publicBaseApiURL.post(endpoint.migration.section.mass(sectionId), form)
  return res?.data
}
