import { Table } from 'components/Atoms/Table'
import React from 'react'

export const History = () => {
  const data = React.useMemo(
    () => [
      {
        changer: 'Joanna Joma',
        time: '01.01.2022 12:32',
        actionName: 'Utwprzenie nowego ogniska',
        afterChange: 'Szkoła podstawowa nr 150',
        beforeChange: '-',
      },
    ],
    [],
  )

  const columns = React.useMemo(
    () => [
      {
        Header: 'Zmieniający',
        accessor: 'changer',
      },
      {
        Header: 'Data i godzina',
        accessor: 'time',
      },
      {
        Header: 'Nazwa akcji',
        accessor: 'actionName',
      },
      {
        Header: 'Treść po zmianie',
        accessor: 'afterChange',
      },
      {
        Header: 'Treść przed zmianie',
        accessor: 'beforeChange',
      },
    ],
    [],
  )
  return <p>Work in progress...</p>
  // return <Table data={data} columns={columns} />
}
