import { useState } from 'react'
import * as OutlineIcons from '@heroicons/react/24/outline'
import * as SolidIcons from '@heroicons/react/24/solid'

type Props = {
  name: string
}

export const LabelName = ({ name }: Props) => {
  const [isHover, setIsHover] = useState(false)
  const iconComponents = {
    outline: (OutlineIcons as any)['InformationCircleIcon'],
    solid: (SolidIcons as any)['InformationCircleIcon'],
  }
  const IconComponent = isHover ? iconComponents.solid : iconComponents.outline
  return (
    <span className={'flex items-start justify-between pl-2'}>
      <p>{name.length > 80 ? `${name.slice(0, 70)}...` : name}</p>
      {IconComponent && name.length > 80 && (
        <IconComponent
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
          className={'ml-2 w-4 shrink-0 cursor-pointer'}
          color={isHover ? 'rgb(67, 56, 202)' : '#000'}
          title={name}
        />
      )}
    </span>
  )
}
