import ErrorRoleModal from './ErrorRoleModal'
import ErrorRouteModal from './ErrorRouteModal'
import { LoadingSection } from './LoadingSection'

type Props = {
  route: boolean
  forbidden: boolean
  isLoading: boolean
}

export const LoadingModal = ({ route, forbidden, isLoading }: Props) => {
  if (isLoading) return <LoadingSection />
  if (route) return <ErrorRouteModal />
  if (forbidden) return <ErrorRoleModal />
  return null
}

export default LoadingModal
