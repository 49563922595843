import { publicBaseApiURL } from 'api'
import { endpoint } from 'constants/endpoints'

export const getEducationsList = async (page: number, count: number) => {
  const res = await publicBaseApiURL.get(
    `${endpoint.membership.education.list}?page=${page}&perPage=${count}`,
    {},
  )
  return res?.data
}

export const getEducation = async (id: string) => {
  const res = await publicBaseApiURL.get(endpoint.membership.education.show(id), {})
  return res?.data
}

export const createEducation = async (data: any) => {
  const res = await publicBaseApiURL.post(endpoint.membership.education.create, data)
  return res?.data
}

export const updateEducation = async (id: string, data: any) => {
  const res = await publicBaseApiURL.put(endpoint.membership.education.update(id), data)
  return res?.data
}
