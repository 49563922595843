import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useAppSelector } from 'app/hooks'
import { Card } from 'components/Atoms/Card'
import { SectionMergeForm } from 'components/Section/SectionMergeForm'
import { path } from 'constants/path'
import { queryKeys } from 'constants/queryKeys'
import { getSectionsList, sectionClubMerge } from 'fetchers/sectionFetchers'
import { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { ISectionMerge } from 'types/section'
import { LoadingSection } from 'components/Atoms/LoadingSection'
import { sectionErrors } from 'error-data/section'
import { mutationErrorHandler } from 'tools/errorHandler'
import { successToast } from 'tools/ToastHelpers'

export const SectionMerge = () => {
  const [isSending, setIsSending] = useState<boolean>(false)
  const [queryParams, setQueryParams] = useSearchParams()
  const navigate = useNavigate()
  const { userToken } = useAppSelector((state: any) => state.authReducer)

  const removeClub = queryParams.get('removeId')
  const removeId = removeClub

  const filters = [{ subtype: 'club' }]

  const { data, isLoading, error, refetch } = useQuery({
    queryKey: [userToken, queryKeys.sectionsList, filters],
    queryFn: () => getSectionsList(1, 1000, filters),
  })

  const getSection = data?.items || []
  const sections = getSection.map((e: { name: string; id: string; parent: { id: string } }) => {
    return { label: e?.name, value: e?.id, parent: e?.parent?.id }
  })
  const queryClient = useQueryClient()

  const initRemoveData = sections.find((item: any) => item.value === removeId)
  const defaultValues = {
    removeId: removeId || '',
    targetId: '',
    newSection: '',
  }

  const mutation = useMutation({
    mutationFn: (data: ISectionMerge) => {
      const { removeId, targetId, newSection } = data
      return sectionClubMerge(targetId, removeId, { name: newSection })
    },
    onSuccess: () => {
      successToast('Połączono ogniska.')
      queryClient.invalidateQueries({ queryKey: [queryKeys.sectionsList] })
      navigate(path.section.list)
    },
    onError: (error: any) => {
      setIsSending(false)
      console.error(error)
      mutationErrorHandler(error, sectionErrors.clubMerge, 'Nie udało się połączyć ognisk.')
    },
  })

  const onSubmit = async (data: ISectionMerge) => {
    try {
      setIsSending(true)
      mutation.mutate(data)
    } catch (error) {
      setIsSending(false)
      console.error(error)
      mutationErrorHandler(null, null, 'Nie udało się połączyć ognisk.')
    }
  }

  if (isLoading) return <LoadingSection />
  return (
    <Card label={'Połącz ogniska'}>
      <SectionMergeForm
        isSending={isSending}
        defaultValues={defaultValues}
        initRemoveData={initRemoveData}
        sections={sections}
        onSubmit={onSubmit}
        navigator={navigate}
      />
    </Card>
  )
}
