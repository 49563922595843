export interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  size?: 'sm' | 'base' | 'lg' | 'xl' | '2xl'
  weight?: 'normal' | 'medium' | 'semibold'
  children: React.ReactNode
}

export const Typography: React.FC<IProps> = ({
  children,
  size = 'base',
  weight = 'normal',
  className,
  ...props
}) => (
  <p className={`text-'${size}' font-${weight} ${className} break-words`} {...props}>
    {children}
  </p>
)
