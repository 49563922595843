import { Button } from 'components/Atoms/Button'
import { Card } from 'components/Atoms/Card'
import { LabelTypography } from 'components/Atoms/LabelTypography'
import { Typography } from 'components/Atoms/Typography'
import { ArrowLeftIcon } from '@heroicons/react/24/outline'
import { useNavigate } from 'react-router-dom'
import useAuth from 'hooks/useAuth'
import {
  getMemberChangelogDetails,
  getMembersDetails,
  revertMemberChange,
} from 'fetchers/membersFetchers'
import { errorQuery, getLoadingHandler } from 'tools/queryHelpers'
import { useMutation, useQuery } from '@tanstack/react-query'
import LoadingModal from 'components/Atoms/LoadingModal'
import { queryKeys } from 'constants/queryKeys'
import { TMemberChangelogType } from 'types/member'
import { useMemo, useState } from 'react'
import { Table } from 'components/Atoms/Table'
import { setupDate, setupTime } from 'tools/formTools'
import { toast } from 'react-toastify'
import { AxiosError } from 'axios'
import { mutationErrorHandler } from 'tools/errorHandler'
import { historyErrors } from 'error-data/history'
import { successToast } from 'tools/ToastHelpers'

export const HistoryDetails = () => {
  const [isSending, setIsSending] = useState(false)
  const { userToken, id, historyId, termId, isPOG, isAllAdmin, isAllOD } = useAuth()
  const showActions = isAllAdmin() || isAllOD()
  const isValid = !isPOG()
  const navigate = useNavigate()
  const backToList = () => navigate(`/member/${id}/history`)

  // GET MEMBER DETAILS
  const { data: member, isLoading: memberIsLoading } = useQuery({
    queryKey: [userToken, queryKeys.member, id],
    queryFn: () => getMembersDetails(id),
    retry: errorQuery,
  })
  const memberName = `${member?.firstName}${member?.secondName ? ` ${member?.secondName}` : ''} ${
    member?.lastName
  }`

  // GET CHANGES DETAILS
  const { data, isLoading, error } = useQuery({
    queryKey: [userToken, queryKeys.memberChangeLogDetails, id, historyId, termId],
    queryFn: () => getMemberChangelogDetails(id, historyId, termId as TMemberChangelogType),
    retry: errorQuery,
  })
  const changes = data?.changes
    ? data.changes.map((change: any) => {
        return {
          field: change.label,
          before: change.oldValue || '-',
          after: change.newValue || '-',
        }
      })
    : []
  const date = setupDate(data?.createdAt)
  const time = setupTime(data?.createdAt)

  // REVERT CHANGES
  const mutation = useMutation({
    mutationFn: () => revertMemberChange(id, historyId, termId as TMemberChangelogType),
    onSuccess: () => {
      setIsSending(false)
      successToast('Dane przywrócone.')
      backToList()
    },
    onError: (error: AxiosError) => {
      console.error(error)
      setIsSending(false)
      mutationErrorHandler(error, historyErrors.revert, 'Nie udało się przywrócić danych.')
    },
    retry: errorQuery,
  })

  const revertChanges = async () => {
    try {
      setIsSending(true)
      mutation.mutate()
    } catch (error) {
      setIsSending(false)
      console.error(error)
      mutationErrorHandler(null, null, 'Nie udało się przywrócić danych.')
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Pole',
        accessor: 'field',
      },
      {
        Header: 'Treść po zmianie',
        accessor: 'after',
      },
      {
        Header: 'Treść przed zmianą',
        accessor: 'before',
      },
    ],
    [],
  )
  const actionButton = showActions
    ? [
        {
          disabled: isSending,
          label: 'Przywróć zmiany',
          handleClick: revertChanges,
        },
      ]
    : []

  const loadingHandler = getLoadingHandler(error, isLoading, !isValid)
  if (loadingHandler.show) return <LoadingModal {...loadingHandler} />

  return (
    <>
      <Typography size='xl' weight='semibold'>
        Dane członka / Historia zmian / Szczegóły zmian na użytkowniku
      </Typography>
      <Card label={`Historia zmian: ${memberName} - dzień ${date}`} actionsButton={actionButton}>
        <div className='flex'>
          <div className='mr-10'>
            <LabelTypography label='Zmieniający' text={memberName} />
          </div>
          <div className='mr-10'>
            <LabelTypography label='Data' text={date} />
          </div>
          <LabelTypography label='Godzina' text={time} />
        </div>
        <Typography size='xl' className='mb-3 mt-5'>
          Zmienione treści
        </Typography>
        <Table columns={columns} data={changes} />
      </Card>

      <Button
        label='Powrót do historii zmian'
        icon={<ArrowLeftIcon className='mr-2 w-4' />}
        onClick={backToList}
        className='mt-5'
      />
    </>
  )
}
