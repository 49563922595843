import { IMutationErrorType } from 'types/httpStatus.enum'

export const institutionErrors: IMutationErrorType = {
  archive: {
    u404: 'Instytucja nie istnieje.',
    u422: 'Instytucja ma aktywnych członków.',
  },
  create: {},
  update: {
    u404: 'Instytucja nie istnieje.',
  },
  updateGroup: {
    u404: 'Grupa placóweknie istnieje.',
  },
  updateType: {
    u404: 'Typ placówki nie istnieje.',
  },
}
