import { publicBaseApiURL } from 'api'
import { endpoint } from 'constants/endpoints'

export const getUnitPositionsList = async (page: number | 'all', count: number) => {
  const res = await publicBaseApiURL.get(
    `${endpoint.membership.unitPosition.list}?page=${page}&perPage=${count}`,
    {},
  )
  return res?.data
}

export const getUnitPosition = async (id: string) => {
  const res = await publicBaseApiURL.get(endpoint.membership.unitPosition.show(id), {})
  return res?.data
}

export const createUnitPosition = async (data: any) => {
  const res = await publicBaseApiURL.post(endpoint.membership.unitPosition.create, data)
  return res?.data
}

export const updateUnitPosition = async (id: string, data: any) => {
  const res = await publicBaseApiURL.put(endpoint.membership.unitPosition.update(id), data)
  return res?.data
}

export const getTermLength = async () => {
  const res = await publicBaseApiURL.get(endpoint.membership.termLength, {})
  return res?.data
}
