import { publicBaseApiURL } from 'api'
import { endpoint } from 'constants/endpoints'

export const getTagsList = async () => {
  const res = await publicBaseApiURL.get(`${endpoint.membership.tags.list}?page=all`)
  return res?.data
}

export const getAvailableTags = async () => {
  const res = await publicBaseApiURL.get(`${endpoint.membership.tags.available}?page=all`)
  return res?.data
}

export const createTag = async (data: { name: string }) => {
  const res = await publicBaseApiURL.post(endpoint.membership.tags.create, data)
  return res?.data
}

export const getTag = async (id: string) => {
  const res = await publicBaseApiURL.get(endpoint.membership.tags.byId(id))
  return res?.data
}

export const updateTag = async (id: string, data: { name: string }) => {
  const res = await publicBaseApiURL.put(endpoint.membership.tags.update(id), data)
  return res?.data
}
