import {
  PencilIcon,
  EyeIcon,
  ArchiveBoxIcon,
  DocumentDuplicateIcon,
} from '@heroicons/react/24/outline'
import { HoverIcon } from 'components/Atoms/HoverIcon'
import { path } from 'constants/path'
import { useNavigate } from 'react-router-dom'
import { IListElement } from 'types/form'
import { ISectionClub } from 'types/section'

type Props = {
  data: ISectionClub
  archive?: boolean
  club?: boolean
  isEditAllow?: boolean
  showModalHandler: (data: IListElement) => void
}

export const SectionActionCell = ({
  showModalHandler,
  data,
  archive,
  club,
  isEditAllow,
}: Props) => {
  const navigate = useNavigate()
  const editPath = data.subtype === 'club' ? 'club' : 'group'
  return (
    <div className='flex'>
      <HoverIcon iconName='EyeIcon' title='Zobacz' onClick={() => navigate(data.id)} />
      {!archive && isEditAllow && (
        <>
          <HoverIcon
            iconName='ArchiveBoxIcon'
            title='Archiwizuj'
            onClick={() => showModalHandler(data)}
          />
          <HoverIcon
            iconName='PencilIcon'
            title='Edytuj'
            onClick={() => navigate(`${editPath}/${data.id}/update`)}
          />
          {club && (
            <HoverIcon
              iconName='DocumentDuplicateIcon'
              title='Połącz'
              onClick={() => navigate(`${path.section.merge}?removeId=${data.id}`)}
            />
          )}
        </>
      )}
    </div>
  )
}

export default SectionActionCell
