import { useState } from 'react'
import * as OutlineIcons from '@heroicons/react/24/outline'
import * as SolidIcons from '@heroicons/react/24/solid'
import clsx from 'clsx'

type Props = {
  className?: string
  iconName: string
  disabled?: boolean
  title: string
  onClick?: () => void
  noMargin?: boolean
  noPointer?: boolean
}

export const HoverIcon = ({
  disabled,
  className,
  noMargin,
  noPointer,
  iconName,
  title,
  onClick,
}: Props) => {
  const [isHover, setIsHover] = useState(false)

  const iconComponents = {
    outline: (OutlineIcons as any)[iconName],
    solid: (SolidIcons as any)[iconName],
  }
  const IconComponent = isHover ? iconComponents.solid : iconComponents.outline
  const margin = noMargin ? '' : ''
  const pointer = disabled ? 'cursor-default' : noPointer ? 'cursor-default' : 'cursor-pointer'
  const isDisabled = disabled ? 'opacity-50' : ''

  return (
    <div
      className={clsx('rounded p-1', className, margin, pointer, isDisabled, {
        'bg-gray-200': isHover,
      })}
      onClick={disabled ? undefined : onClick}
      onMouseEnter={() => !disabled && setIsHover(true)}
      onMouseLeave={() => !disabled && setIsHover(false)}
    >
      {IconComponent && (
        <IconComponent
          color={isHover ? 'rgb(67, 56, 202)' : '#000'}
          title={title}
          className='w-5'
        />
      )}
    </div>
  )
}
