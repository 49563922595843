import { Button } from 'components/Atoms/Button'
import { Calendar } from 'components/Atoms/Calendar'
import { Select } from 'components/Atoms/Select'
import { TextField } from 'components/Atoms/TextField'
import { Typography } from 'components/Atoms/Typography'
import { MinusCircleIcon, PlusCircleIcon } from '@heroicons/react/20/solid'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, Controller } from 'react-hook-form'
import { createSectionSchemaGroup } from 'schemas/sectionSchema'
import { useSelector } from 'react-redux'
import { getBranches, getBranchesAsync } from 'features/Structure/branches/slice'
import { useAppDispatch } from 'app/hooks'
import { TextareaField } from 'components/Atoms/TextareaField'
import { ICreateSectionGroup } from 'types/section'
import { toast } from 'react-toastify'
import { errorRequires } from 'tools/ToastHelpers'
import { ILegalHook } from 'types/legal'
import LegalSearch from 'components/Legal/LegalSearch'

interface IProps {
  isSending?: boolean
  onSubmit: (data: any) => void
  navigate: (path: string) => void
  data: ICreateSectionGroup
  branchesList?: IOption[]
  typesList: IOption[]
  createRoot?: boolean
  legalData: ILegalHook
}
interface IOption {
  label: string
  value: number | string
}

export const SectionFormGroup: React.FC<IProps> = ({
  legalData,
  isSending,
  onSubmit,
  navigate,
  data,
  branchesList = [],
  typesList,
  createRoot,
}) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm<ICreateSectionGroup>({
    resolver: yupResolver(createSectionSchemaGroup),
    defaultValues: data,
  })

  const onValidationFailure = () => errorRequires(errors)

  return (
    <form onSubmit={handleSubmit(onSubmit, onValidationFailure)}>
      {createRoot && (
        <div className='mb-5 grid grid-cols-4 gap-5'>
          <div>
            <p className='mb-1 block text-sm font-medium text-gray-700'>Oddział</p>
            <Controller
              name={'branch'}
              control={control}
              render={({ field: { onChange } }) => (
                <Select
                  handleSelect={({ value }) => {
                    onChange(value)
                    legalData && legalData.setUnit(String(value))
                  }}
                  options={branchesList}
                  withEmpty
                />
              )}
            />
            <p className='mt-2 text-sm text-red-600'>{errors?.branch?.message}</p>
          </div>
          <div>
            <p className='mb-1 block text-sm font-medium text-gray-700'>Typ sekcji</p>
            <Controller
              name={'subtype'}
              control={control}
              render={({ field: { onChange } }) => (
                <Select
                  handleSelect={({ value }) => onChange(value)}
                  options={typesList}
                  withEmpty
                />
              )}
            />
            <p className='mt-2 text-sm text-red-600'>{errors?.subtype?.message}</p>
          </div>
        </div>
      )}
      <div className='mb-5 grid grid-cols-4 gap-5'>
        <TextField
          {...register('phonePrimary')}
          error={errors.phonePrimary?.message}
          label='Numer telefonu'
          placeholder='Podaj numer'
        />
        <TextField
          {...register('phoneSecondary')}
          error={errors.phoneSecondary?.message}
          label='Numer telefonu dodatkowy'
          placeholder='Podaj numer'
        />
        <TextField
          {...register('email')}
          error={errors.email?.message}
          label='E-mail kontaktowy'
          placeholder='Podaj e-mail'
        />
        <div>
          <LegalSearch control={control} legalData={legalData} register={register} />
          <p className='mt-2 text-sm text-red-600'>{errors?.document?.message ? 'Wybierz' : ''}</p>
        </div>
      </div>

      <div className='my-5 grid w-3/4 grid-cols-1 gap-5'>
        <TextareaField {...register('locationDescription')} label='Lokalizacja' />
      </div>
      <div className='mt-10 flex'>
        <div className='mr-5'>
          <Button variant='secondary' label='Anuluj' onClick={() => navigate('/section')} />
        </div>
        <Button disabled={isSending} label='Zatwierdź' />
      </div>
    </form>
  )
}
