interface IProps {
  // target: number
  completed: number
}

export const ProgressBar = ({ completed }: IProps) => {
  const containerStyles = {
    height: 20,
    width: '100%',
    backgroundColor: '#e0e0de',
    borderRadius: 50,
  }

  const labelStyles = {
    padding: 5,
    color: 'white',
    fontWeight: 'bold',
  }

  return (
    <div style={containerStyles}>
      <div
        style={{
          height: '100%',
          width: `${completed}%`,
          backgroundColor: 'green',
          borderRadius: 'inherit',
          textAlign: 'right',
        }}
      >
        <span style={labelStyles}>{`${completed}%`}</span>
      </div>
    </div>
  )
}
