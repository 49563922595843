type Props = {
  restNum: number
}

export const ShowMoreTagButton = ({ restNum }: Props) => {
  return (
    <p className='flex gap-2'>
      <span>Rozwiń listę</span>
      <span>+{restNum}</span>
    </p>
  )
}

export default ShowMoreTagButton
