import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Axios, AxiosError } from 'axios'
import { Card } from 'components/Atoms/Card'
import ErrorRoleModal from 'components/Atoms/ErrorRoleModal'
import { LabelTypography } from 'components/Atoms/LabelTypography'
import { Typography } from 'components/Atoms/Typography'
import { LegalForm } from 'components/Legal/LegalForm'
import { path } from 'constants/path'
import { queryKeys } from 'constants/queryKeys'
import { getBranchesList } from 'fetchers/branchFetchers'
import { createLegal, getLegal, updateLegal } from 'fetchers/legatFetchers'
import { getRegionsList } from 'fetchers/regionFetchers'
import { getSectionsList } from 'fetchers/sectionFetchers'
import useAuth from 'hooks/useAuth'
import useSearchModule from 'hooks/useSearchModule'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { errorForbiddenHandler, errorQuery } from 'tools/queryHelpers'
import { ILegal } from 'types/legal'
import { LoadingSection } from 'components/Atoms/LoadingSection'
import { legalErrors } from 'error-data/legal'
import { mutationErrorHandler } from 'tools/errorHandler'
import { successToast } from 'tools/ToastHelpers'

export const LegalUpdate = () => {
  const { perPage, setPerPage, filters } = useSearchModule({})
  const [type, setType] = useState('')
  const [isSending, setIsSending] = useState(false)
  const { isAllOD, isAllOK, isAdmins, userToken, unitContext, userLoaded, id } = useAuth()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const isAdmin = isAdmins()
  const setTypeHandler = (value: string) => {
    setType(value)
    setPerPage(10)
  }

  const {
    data: legal,
    isLoading,
    error,
    refetch,
  } = useQuery({
    queryKey: [userToken, queryKeys.declarationList, id],
    queryFn: () => getLegal(id),
    retry: errorQuery,
  })

  const mutation = useMutation({
    mutationFn: (data: ILegal) => updateLegal(id, data),
    onSuccess: () => {
      successToast('Zaktrualizowano dokument.')
      refetch()
      queryClient.invalidateQueries({ queryKey: [queryKeys.legalList] })
      navigate(path.resolution.list)
    },
    onError: (error: AxiosError) => {
      setIsSending(false)
      console.error(error)
      mutationErrorHandler(error, legalErrors.update, 'Nie udało się zaktualizować dokumentu.')
    },
  })

  const { data: division, isLoading: divisionLoading } = useQuery({
    queryKey: [
      userToken,
      queryKeys.branchesList,
      queryKeys.regionList,
      queryKeys.sectionsList,
      filters,
      perPage,
      type,
    ],
    queryFn: () => {
      if (type === 'branch') {
        return getBranchesList(1, perPage, filters)
      }
      if (type === 'region') {
        return getRegionsList(1, perPage, filters)
      }
      if (type === 'section') {
        return getSectionsList(1, perPage, filters)
      }
      return null
    },
  })

  const divisionList = (division?.items || []).map(
    (e: { name: string; id: string; parent: { name: string } }) => {
      return {
        label: `${e.name}${e.parent?.name && type !== 'region' ? ` (${e.parent?.name})` : ''}`,
        value: e.id,
      }
    },
  )

  const searchMore = () => {
    const count =
      division?.pagination?.countFiltered > 0
        ? division?.pagination?.countFiltered
        : division?.pagination?.count
    if (!isLoading && division?.items?.length < count) setPerPage((prev) => prev + 10)
  }

  const onSubmit = async (data: ILegal) => {
    try {
      const { division, ...dataForm } = data
      setIsSending(true)
      mutation.mutate(dataForm)
    } catch (error) {
      setIsSending(false)
      console.error(error)
      mutationErrorHandler(null, null, 'Nie udało się zaktualizować dokumentu.')
    }
  }
  const initData: ILegal = {
    name: legal?.unit?.name || '',
    identifier: legal?.identifier || '',
    dateEnactment: new Date(legal?.dateEnactment),
    dateEffective: new Date(legal?.dateEffective),
    unit: legal?.unit?.id || '',
  }
  if (errorForbiddenHandler(error)) return <ErrorRoleModal />

  if (!userLoaded || isLoading) return <LoadingSection />

  return (
    <section>
      <Typography size='xl' weight='semibold'>
        Uchwały / {initData?.identifier} / Edytuj
      </Typography>

      <Card label={`Edytuj uchwałę ${initData?.identifier}`}>
        <LabelTypography label='Jednostka decyzyjna' text={initData.name} />
        <LegalForm
          isEdit
          isAdmin={isAdmin}
          divisionLoading={divisionLoading}
          divisionList={divisionList}
          searchMore={searchMore}
          setTypeHandler={setTypeHandler}
          onSubmit={onSubmit}
          isSending={isSending}
          data={initData}
        />
      </Card>
    </section>
  )
}

export default LegalUpdate
