import { publicBaseApiURL, secureBaseApiURL } from 'api'
import { endpoint } from 'constants/endpoints'
import { IBranchMassMoveRequest } from 'types/branch'
import { IIdForm, IIdFormList } from 'types/form'
import { TMemberChangelogType, TMigration } from 'types/member'
import { IStructureMemberFunction, IStructureMemberFunctionUpdate } from 'types/structure'

export const getMembersSectionList = async (
  id: string,
  page: number,
  count: number,
  filters?: string,
) => {
  const res = await publicBaseApiURL.get(
    endpoint.members.section(id, page, count, filters ? filters : ''),
  )
  return res?.data
}

export const getInstitutionMembersList = async (id: string, page: number, count: number) => {
  const res = await publicBaseApiURL.get(
    endpoint.members.list(page, count, `&filter[institution][]=${id}`),
  )
  return res?.data
}

export const getMembersList = async (page: number, count: number, filters?: string) => {
  const res = await publicBaseApiURL.get(endpoint.members.list(page, count, filters ? filters : ''))
  return res?.data
}

export const getMembersDetails = async (id: string, reveal?: boolean) => {
  const res = await publicBaseApiURL.get(
    `${endpoint.members.byId(id)}${reveal ? '?unredacted=true' : ''}`,
  )
  return res?.data
}

export const getMemberContact = async (id: string, reveal?: boolean) => {
  const res = await publicBaseApiURL.get(
    `${endpoint.members.contact(id)}${reveal ? '?unredacted=true' : ''}`,
  )
  return res?.data
}

export const getMemberSensitive = async (id: string, reveal?: boolean) => {
  const res = await secureBaseApiURL.get(
    `${endpoint.members.sensitive(id)}${reveal ? '?unredacted=true' : ''}`,
  )
  return res?.data
}

export const updateMemberSensitive = async (id: string, data: any) => {
  const res = await secureBaseApiURL.put(endpoint.members.updateSensitive(id), data)
  return res?.data
}

export const updateMemberGeneralInfo = async (id: string, data: any) => {
  const res = await publicBaseApiURL.put(endpoint.members.updateGeneral(id), data)
  return res?.data
}

export const archiveMember = async (id: string, data: any) => {
  const res = await publicBaseApiURL.post(endpoint.members.archive(id), data)
  return res?.data
}

export const updateMemberContact = async (id: string, data: any) => {
  const res = await publicBaseApiURL.put(endpoint.members.updateContact(id), data)
  return res?.data
}

// EMPLOYMENT
export const getMemberEmployment = async (id: string) => {
  const res = await publicBaseApiURL.get(endpoint.membership.employmentList(id))
  return res?.data
}

export const getMemberEmploymentUpdate = async (id: string) => {
  const res = await publicBaseApiURL.get(endpoint.membership.employmentListUpdate(id))
  return res?.data
}

export const updateMemberEmployment = async (id: string, employmentId: string, data: any) => {
  const res = await publicBaseApiURL.put(endpoint.members.updateEmployment(id, employmentId), data)
  return res?.data
}

export const addMemberEmployment = async (id: string, data: any) => {
  const res = await publicBaseApiURL.post(endpoint.members.addEmployment(id), data)
  return res?.data
}

// FUNCTIONS
export const getMemberFunctions = async (id: string, archived?: boolean) => {
  const filter = archived ? '?filter[onlyCurrent]=false' : ''
  const res = await publicBaseApiURL.get(endpoint.members.functions(id) + filter)
  return res?.data
}

export const getMemberFunctionById = async (memberId: string, termId: string) => {
  const res = await publicBaseApiURL.get(endpoint.members.functionById(memberId, termId))
  return res?.data
}
export const addMemberFunction = async (data: IStructureMemberFunction) => {
  const { memberId, unitId, unitPositionId, ...timeData } = data
  const res = await publicBaseApiURL.post(
    endpoint.members.functionCreate(memberId, unitId, unitPositionId),
    timeData,
  )
  return res?.data
}

export const updateMemberFunction = async (data: IStructureMemberFunctionUpdate) => {
  const { memberId, termId, ...timeData } = data
  const res = await publicBaseApiURL.put(
    endpoint.members.functionUpdate(memberId, termId),
    timeData,
  )
  return res?.data
}

// Section Migration
export const removeMemberFromSection = async (memberId: string, sectionId: string) => {
  const res = await publicBaseApiURL.delete(endpoint.members.removeFromSection(memberId, sectionId))
  return res?.data
}

export const addMemberToSection = async (memberId: string, sectionId: string) => {
  const res = await publicBaseApiURL.post(endpoint.members.addToSection(memberId, sectionId))
  return res?.data
}

export const moveMemberToSection = async (memberId: string, sectionId: string, comment: string) => {
  const res = await publicBaseApiURL.post(endpoint.members.moveToSection(memberId, sectionId), {
    comment,
  })
  return res?.data
}

// Branch migration
export const moveMemberToBranch = async (memberId: string, branchId: string, comment: string) => {
  const res = await publicBaseApiURL.post(endpoint.migration.branch.create(memberId, branchId), {
    comment,
  })
  return res?.data
}

export const getMemberBranchMigrationDetail = async (migrationId: string) => {
  const res = await publicBaseApiURL.get(endpoint.migration.branch.details(migrationId))
  return res?.data
}

export const cancelBranchMigration = async (migrationId: string) => {
  const res = await publicBaseApiURL.delete(endpoint.migration.branch.cancel(migrationId))
  return res?.data
}

export const acceptBranchMigration = async (migrationId: string) => {
  const res = await publicBaseApiURL.post(endpoint.migration.branch.accept(migrationId))
  return res?.data
}

export const rejectBranchMigration = async (migrationId: string) => {
  const res = await publicBaseApiURL.post(endpoint.migration.branch.reject(migrationId))
  return res?.data
}

export const branchMigrationHandler = async (migrationId: string, action: TMigration) => {
  switch (action) {
    case 'accept':
      return acceptBranchMigration(migrationId)
    case 'reject':
      return rejectBranchMigration(migrationId)
    case 'cancel':
      return cancelBranchMigration(migrationId)
  }
}

// MASS MIGRATION
export const cancelBranchMigrationMass = async (data: IIdFormList) => {
  const res = await publicBaseApiURL.delete(endpoint.migration.branch.mass.cancel, { data })
  return res?.data
}

export const acceptBranchMigrationMass = async (data: IIdFormList) => {
  const res = await publicBaseApiURL.post(endpoint.migration.branch.mass.accept, data)
  return res?.data
}

export const rejectBranchMigrationMass = async (data: IIdFormList) => {
  const res = await publicBaseApiURL.post(endpoint.migration.branch.mass.reject, data)
  return res?.data
}

export const branchMigrationMassHandler = async (data: IIdFormList, type: TMigration) => {
  switch (type) {
    case 'accept':
      return acceptBranchMigrationMass(data)
    case 'cancel':
      return cancelBranchMigrationMass(data)
    case 'reject':
      return rejectBranchMigrationMass(data)
  }
}

// HISTORY
export const getMemberChangelog = async (
  page: number,
  count: number,
  memberId: string,
  filters: string,
  type: TMemberChangelogType,
) => {
  const api = type === 'simple' ? publicBaseApiURL : secureBaseApiURL
  const res = await api.get(endpoint.members.changelog(page, count, memberId, filters || ''))
  return res?.data
}

export const getMemberChangelogDetails = async (
  memberId: string,
  changeId: string,
  type: TMemberChangelogType,
) => {
  const api = type === 'simple' ? publicBaseApiURL : secureBaseApiURL
  const res = await api.get(endpoint.members.changelogSingle(memberId, changeId))
  return res?.data
}

export const revertMemberChange = async (
  memberId: string,
  changeId: string,
  type: TMemberChangelogType,
) => {
  const api = type === 'simple' ? publicBaseApiURL : secureBaseApiURL
  const res = await api.post(endpoint.members.changelogRevert(memberId, changeId))
  return res?.data
}

export const getMemberTags = async (id: string) => {
  const res = await publicBaseApiURL.get(endpoint.members.tags(id))
  return res?.data
}

export const addMemberTag = async (tagId: string, memberId: string) => {
  const res = await publicBaseApiURL.post(endpoint.membership.tags.add(tagId, memberId))
  return res?.data
}

export const removeMemberTag = async (tagId: string, memberId: string) => {
  const res = await publicBaseApiURL.delete(endpoint.membership.tags.remove(tagId, memberId))
  return res?.data
}

export const getMemberDecorations = async (memberId: string) => {
  const res = await publicBaseApiURL.get(endpoint.members.memberDecorations(memberId))
  return res?.data
}

export const getDecoratiosList = async (page: number | string, count: number) => {
  const res = await publicBaseApiURL.get(
    `${endpoint.members.decorationList}?page=${page}&perPage=${count}`,
  )
  return res?.data
}

export const getUserAvialableDecorations = async () => {
  const res = await publicBaseApiURL.get(endpoint.members.userAvialableDecorations)
  return res?.data
}

export const addMemberDecoration = async (memberId: string, data: any) => {
  const res = await publicBaseApiURL.post(endpoint.members.addDecoration(memberId), data)
  return res?.data
}
