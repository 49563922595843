import { useMutation, useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { Button } from 'components/Atoms/Button'
import { Card } from 'components/Atoms/Card'
import { Modal } from 'components/Atoms/Modal'
import { ShowMoreTagButton } from 'components/Atoms/ShowMoreTagButton'
import { Spinner } from 'components/Atoms/Spinner'
import TagFrame from 'components/Atoms/TagFrame'
import { queryKeys } from 'constants/queryKeys'
import { tagErrors } from 'error-data/tags'
import { addMemberTag, removeMemberTag } from 'fetchers/membersFetchers'
import { getAvailableTags } from 'fetchers/tagsFetchers'
import useAuth from 'hooks/useAuth'
import { useCallback, useState } from 'react'
import { ReactTags, Tag } from 'react-tag-autocomplete'
import { successToast } from 'tools/ToastHelpers'
import { mutationErrorHandler } from 'tools/errorHandler'
import { IListElement } from 'types/form'

type Props = {
  memberTags: any
  refetch: () => void
}

export const MemberTagEdit = ({ memberTags, refetch }: Props) => {
  const [fullList, setFullList] = useState(false)
  const [isUpdating, setIsUpdating] = useState(false)
  const [selected, setSelected] = useState<Tag[]>(memberTags)
  const { id: memberId, userToken } = useAuth()

  const { data, isLoading, error } = useQuery({
    queryKey: [userToken, queryKeys.tagsListAvailable],
    queryFn: () => getAvailableTags(),
  })
  const tagsData = data?.items
    ? data.items.map((item: IListElement) => ({ label: item.name, value: item.id }))
    : []

  const tagsList = fullList ? selected : selected.slice(0, 10)
  const showTagToggler = selected.length > 10
  const restNum = selected.length - tagsList.length

  const addTag = useMutation({
    mutationFn: (tag: Tag) => addMemberTag(String(tag.value), memberId),
    onSuccess: (_res, tag) => {
      successToast(`Przypisano tag ${tag.label}`)
      refetch()
      setIsUpdating(false)
    },
    onError: (error: AxiosError, tag) => {
      setIsUpdating(false)
      console.error(error)
      mutationErrorHandler(error, tagErrors.createMember, `Nie udało się dodać tagu ${tag.label}`)
    },
  })

  const removeTag = useMutation({
    mutationFn: (tag: Tag) => removeMemberTag(String(tag.value), memberId),
    onSuccess: (_res, tag) => {
      successToast(`Usunięto tag ${tag.label}`)
      refetch()
      setIsUpdating(false)
    },
    onError: (error: AxiosError, tag) => {
      setIsUpdating(false)
      console.error(error)
      mutationErrorHandler(error, tagErrors.delete, `Nie udało się usunąć tagu ${tag.label}`)
    },
  })

  const addHandler = async (tag: Tag) => {
    try {
      setIsUpdating(true)
      await addTag.mutateAsync(tag)
    } catch (error) {
      console.error(error)
      setIsUpdating(false)
      mutationErrorHandler(null, null, `Nie udało się dodać tagu ${tag.label}`)
    }
  }

  const removeHandler = async (tag: Tag) => {
    try {
      setIsUpdating(true)
      await removeTag.mutateAsync(tag)
    } catch (error) {
      setIsUpdating(false)
      console.error(error)
      mutationErrorHandler(null, null, `Nie udało się usunąć tagu ${tag.label}`)
    }
  }

  const onAdd = useCallback((newTag: Tag) => {
    setSelected((prevSelected) => [...prevSelected, newTag])
    addHandler(newTag)
  }, [])

  const onDelete = useCallback(
    (tagIndex: number) => {
      const tagToRemove = selected[tagIndex]
      setSelected((prevSelected) => prevSelected.filter((_, i) => i !== tagIndex))
      removeHandler(tagToRemove)
    },
    [selected],
  )

  if (isLoading) {
    return (
      <div className='mt-5 flex h-full items-center justify-center'>
        <Spinner />
      </div>
    )
  }

  return (
    <>
      <div className='px-4 py-6'>
        <ul className='mb-4 flex flex-wrap items-center gap-2'>
          {tagsList.map((tag: Tag, i: number) => {
            return <TagFrame key={String(tag.value)} removeToken={() => onDelete(i)} tag={tag} />
          })}
          {showTagToggler && (
            <li>
              <Button
                type='button'
                label={fullList ? 'Zwiń listę' : <ShowMoreTagButton restNum={restNum} />}
                onClick={() => setFullList((p) => !p)}
              />
            </li>
          )}
        </ul>
        <ReactTags
          labelText=''
          placeholderText='Wyszukaj tagi...'
          selected={selected}
          suggestions={tagsData}
          onAdd={onAdd}
          onDelete={onDelete}
          noOptionsText='Nie znaleziono tagów'
          renderTag={() => <></>}
          classNames={{
            root: 'relative',
            rootIsActive: 'rc__active',
            rootIsDisabled: 'is-disabled',
            rootIsInvalid: 'is-invalid',
            label: 'rc__label',
            tagList: 'hidden',
            tagListItem: 'react-tags__list-item',
            tag: 'react-tags__tag',
            tagName: 'react-tags__tag-name',
            comboBox: 'w-full',
            input:
              'w-[100%!important] rounded-lg border border-slate-200 bg-slate-100 py-1.5 pr-8 pl-3 text-sm/6 text-gray-900 focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25',
            listBox: 'rc__listbox p-1 rounded-xl border border-slate-200 bg-white',
            option: 'rc__listbox-option group flex cursor-default items-center gap-2 rounded-lg py-1.5 px-3 hover:bg-slate-100',
            optionIsActive: 'is-active',
            highlight: 'react-tags__listbox-option-highlight',
          }}
        />
      </div>
      <Modal isOpen={isUpdating}>
        <Card label='Aktualizowanie listy tagów' className='px-10 py-5'>
          <div className=' bg-white p-4'>
            <p className='mx-auto mb-2 text-center text-lg'>Proszę czekać...</p>
            <div className='mt-5 flex h-full items-center justify-center'>
              <Spinner />
            </div>
          </div>
        </Card>
      </Modal>
    </>
  )
}

export default MemberTagEdit
