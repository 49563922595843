import { Card } from 'components/Atoms/Card'
import { copyMemberSchema } from 'schemas/memberSchema'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { Button } from 'components/Atoms/Button'
import { Select } from 'components/Atoms/Select'
import { ListRoleUnit } from 'components/Atoms/ListRole-Unit'
import { useEffect, useMemo, useState } from 'react'
import { publicBaseApiURL } from 'api'

import { useSelector } from 'react-redux'
import { useAppDispatch } from 'app/hooks'
import { getRoles, getRolesAsync } from 'features/Role/roleSlice'
import { Alert } from 'components/Atoms/Alert'
import { Spinner } from 'components/Atoms/Spinner'
import { IContext, ISingleUserData } from 'types/userlist'
import useAuth from 'hooks/useAuth'
import { queryKeys } from 'constants/queryKeys'
import { useQuery } from '@tanstack/react-query'
import { getBranchesList } from 'fetchers/branchFetchers'
import { getRegionsList } from 'fetchers/regionFetchers'
import { getSectionsList } from 'fetchers/sectionFetchers'

interface IProps {
  handleVisibleEditField: () => void
  userData: ISingleUserData
  mutate: (id: string) => void
}
interface IRole {
  label: string
  value: string | number
}
interface IUnit {
  label: string
  value: string | number
}
interface IRoles {
  id: string | number
  role: IRole
  unit: IUnit
}
interface IForm {
  fullName: string
  phoneNumber: string
  email: string
}
const defaultValueSelector = {
  label: 'Wybierz',
  value: '',
}
const defaultValues = {
  fullName: '',
  phoneNumber: '',
  email: '',
}

export const EditUser: React.FC<IProps> = ({ userData, handleVisibleEditField, mutate }) => {
  const [roles, setRoles] = useState<IRoles[]>([])
  const [selectedRole, setSelectedRole] = useState<IRole>(defaultValueSelector)
  const [selectedUnit, setSelectedUnit] = useState<IUnit>(defaultValueSelector)

  const [searchParams, setSearchParams] = useState<IForm | null>(null)
  const [error, setError] = useState<string | null>(null)
  const [success, setSuccess] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)
  const [loadingRemoteUsers, setLoadingRemoteUsers] = useState(false)
  const r = useSelector(getRoles)
  const dispatch = useAppDispatch()
  const { userToken } = useAuth()
  // const [rolesList, setRolesList] = useState<IRole[] | []>([])

  useEffect(() => {
    dispatch(getRolesAsync())
  }, [])

  useEffect(() => {
    setSelectedUnit(defaultValueSelector)
  }, [selectedRole])

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<IForm>({
    defaultValues,
    resolver: yupResolver(copyMemberSchema),
  })

  const onSubmit = (data: IForm) => {
    setLoadingRemoteUsers(true)
    setSearchParams(data)
  }

  const selectedRoleUnit = useMemo(() => {
    if (!selectedRole.value) return null

    if (!r) return null

    for (const [key, value] of Object.entries(r)) {
      const role = value.find((i: { role: string; name: string }) => i.role === selectedRole.value)
      if (role) {
        return key
      }
    }

    return null
  }, [selectedRole])
  const { data: division, isLoading } = useQuery({
    queryKey: [
      userToken,
      queryKeys.branchesList,
      queryKeys.regionList,
      queryKeys.sectionsList,
      selectedRole,
    ],
    queryFn: () => {
      if (selectedRoleUnit === 'branch') return getBranchesList(1, 1000, [])
      if (selectedRoleUnit === 'region') return getRegionsList(1, 1000, [])
      if (selectedRoleUnit === 'section') return getSectionsList(1, 1000, [])
      return { items: [] }
    },
  })

  const rolesList = useMemo(() => {
    const isArray = Array.isArray(Object.values(r))

    if (!isArray) return []

    const rolesData = Object.values(r)
      .flat()
      .map((i: { role: string; name: string }) => ({ label: i.name, value: i.role }))
    return rolesData
  }, [r])

  const handleSubmitRole = async () => {
    setSuccess(null)
    setError(null)
    setLoading(true)

    try {
      const payload: any = {
        role: selectedRole.value,
        unit: selectedUnit.value,
      }

      const req = await publicBaseApiURL.put(`user/add-context/${userData?.id}`, payload, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-Auth-Token': `Bearer ${localStorage.getItem('token')}`,
        },
      })

      setSuccess('Pomyślnie dodano rolę')

      if (selectedRole.value && selectedUnit.value) {
        const newRole: IRoles = {
          id: String(roles.length + 1),
          role: selectedRole,
          unit: selectedUnit,
        }

        setRoles((prevRoles) => [...prevRoles, newRole])
        setSelectedRole(defaultValueSelector)
        setSelectedUnit(defaultValueSelector)
      }
    } catch (error) {
      setError('Użytkownik został już dodany do systemu')
      console.error(error)
    } finally {
      setLoading(false)
      mutate(userData.id)
    }
  }
  const handleRoleDel = async (role: IRole, unit: IUnit, id: string | number) => {
    setSuccess(null)
    setError(null)
    setLoading(true)

    try {
      const req = await publicBaseApiURL.delete(`user/remove-context/${userData?.id}`, {
        data: {
          role: role.value,
          unit: unit.value,
        },
        headers: {
          'X-Auth-Token': `Bearer ${localStorage.getItem('token')}`,
        },
      })

      setSuccess('Pomyślnie usunięta rola')
      setRoles((prevRoles) => prevRoles.filter((r) => String(r.id) !== String(id)))
    } catch (error) {
      setError('Nie udało się usunąć')
      console.error(error)
    } finally {
      setLoading(false)
      mutate(userData?.id)
    }
  }

  const handleCopyRoles = async (remoteId: string) => {
    try {
      const req = await publicBaseApiURL.put(
        `user/update/${userData.id}/remote-id/${remoteId}`,
        null,
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'X-Auth-Token': `Bearer ${localStorage.getItem('token')}`,
          },
        },
      )
    } catch (error) {
      setError('Nie udało się skopiować')
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setRoles([])
    if (userData?.contexts) {
      userData?.contexts.map((e: IContext, index: number) => {
        setRoles((prev) => [
          ...prev,
          {
            id: index,
            role: {
              label: e?.role,
              value: e?.role,
            },
            unit: {
              label: e?.unit?.name,
              value: e?.unit?.id,
            },
          },
        ])
      })
    }
  }, [userData])

  return (
    <div>
      <Card
        actionsButton={[
          {
            label: 'Archiwizuj',
            handleClick: handleSubmit(onSubmit),
          },
          {
            label: 'Zatwierdź',
            handleClick: handleSubmitRole,
            disabled: selectedRole && selectedUnit ? false : true,
          },
        ]}
      >
        <div className='mb-5 grid grid-cols-4 gap-5'>
          <div>
            <Select
              handleSelect={(val) => setSelectedRole(val)}
              options={rolesList}
              label='Rola'
              withEmpty
            />
          </div>
          {division ? (
            <div>
              <Select
                handleSelect={(val) => setSelectedUnit(val)}
                options={(division.items || []).map((i: any) => ({ label: i.name, value: i.id }))}
                label='Jednostka struktury'
                withEmpty
              />
            </div>
          ) : (
            isLoading && (
              <div className='flex items-center justify-center'>
                <Spinner />
              </div>
            )
          )}

          <div>
            <ListRoleUnit label='Przypisane role:' roles={roles} onRoleDel={handleRoleDel} />
          </div>
        </div>
        <div className='flex justify-center'>
          <Button
            label='Powrót'
            onClick={() => {
              handleVisibleEditField()
            }}
          />
        </div>
        {error && <Alert description={error} headline={'Błąd!!!'} level='red' />}
        {success && <Alert description={success} headline={'Info'} level='blue' />}
        {loading && <Spinner />}
      </Card>
    </div>
  )
}
