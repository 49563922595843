import { useMemo, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from 'app/hooks'
import { Button } from 'components/Atoms/Button'
import { Typography } from 'components/Atoms/Typography'
import { queryKeys } from 'constants/queryKeys'
import {
  getCardReqStatusDict,
  getCardReqTypeDict,
  getCardsRequestList,
} from 'fetchers/cardFetchers'
import { Controller, useForm } from 'react-hook-form'
import { Select } from 'components/Atoms/Select'
import { Checkbox } from 'components/Atoms/Checkbox'
import { Table } from 'components/Atoms/Table'
import useSearchModule from 'hooks/useSearchModule'
import useAuth from 'hooks/useAuth'
import LoadingModal from 'components/Atoms/LoadingModal'
import useLegalFetcher from 'hooks/useLegalFetcher'
import { Pagination } from 'components/Atoms/Pagination'
import { errorQuery, getLastPage, getLoadingHandler } from 'tools/queryHelpers'
import { HoverIcon } from 'components/Atoms/HoverIcon'
import { AdjustmentsHorizontalIcon } from '@heroicons/react/20/solid'
import { path } from 'constants/path'
import CardReqDetails from './CardReqDetails'
import CardReqUpdate from 'components/CardReq/CardReqUpdate'
import { ICardReqSingle, ICardReqTypeDict } from 'types/cardRequest'
import useSearch from 'hooks/useSearch'
import { IOption } from 'types/form'
import { useLastPage } from 'hooks/useLastPage'
import { Spinner } from 'components/Atoms/Spinner'
import { IDict } from 'types/dictionary'
import CardReqUpdateType from 'components/CardReq/CardReqUpdateType'

export const CardsListRequest = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [typeModalIsOpen, setTypeModalIsOpen] = useState(false)
  const [typeModalId, setTypeModalId] = useState<ICardReqSingle | null>(null)
  const [selectedIds, setSelectedIds] = useState<ICardReqSingle[]>([])
  const [showCompleteData, setShowCompleteData] = useState(false)
  const [showArchiveData, setShowArchiveData] = useState(false)
  const [selectedRegion, setSelectedRegion] = useState<string | null>(null)
  const [filteredByStatus, setFilteredByStatus] = useState<string>('')
  const [typeOfRequest, setTypeOfRequest] = useState<ICardReqTypeDict | ''>('')
  const [selectedAction, setSelectedAction] = useState<string>('')

  const { control } = useForm()
  const { isAllAdmin, isAllOD, isAllOK, userLoaded, memberId } = useAuth()
  const { filters, page, perPage, setLimit, changePage } = useSearch({})
  const { userToken } = useAppSelector((state: any) => state.authReducer)
  const isAllow = isAllAdmin() || isAllOD() || isAllOK()
  const navigate = useNavigate()

  const isAdmin = isAllAdmin()

  // GET LEGITIMATIONS LIST
  const setupFilters = showCompleteData ? filters + '&unredacted=true' : filters
  const {
    data: cards,
    isLoading,
    error,
    refetch,
  } = useQuery({
    queryKey: [userToken, queryKeys.cardsListRequest, page, perPage, memberId, setupFilters],
    queryFn: () => getCardsRequestList(page, perPage, setupFilters),
    retry: errorQuery,
  })
  // END GET LEGITIMATIONS LIST

  // REQUEST STATUS
  const { data: reqStatus, isLoading: statusLoading } = useQuery({
    queryKey: [userToken, queryKeys.cardReqStatusDict],
    queryFn: () => getCardReqStatusDict(),
  })

  const statusList = reqStatus?.items || []
  const cardsList = cards?.items
    ? cards.items.map((card: ICardReqSingle) => ({
        ...card,
        status: statusList.find((status: IDict) => status.id === card.status)?.name,
      }))
    : []
  // END REQUEST STATUS

  // REQEUST TYPE
  const { data: reqType, isLoading: typeLoading } = useQuery({
    queryKey: [userToken, queryKeys.cardReqTypeDict],
    queryFn: () => getCardReqTypeDict(),
  })
  const typeList = reqType?.items || []
  // END REQEUST TYPE

  // HANDLE CHECKBOX CHANGE
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>, row: any) => {
    const isChecked = e.currentTarget.checked
    setSelectedIds((prevList) => {
      if (isChecked) {
        return [...prevList, row.row.original]
      } else {
        return prevList.filter((item) => item.id !== row.row.original.id)
      }
    })
  }
  const allSelected = selectedIds.length === cards?.items?.length
  const handleDeselectAll = () => setSelectedIds([])
  const handleToggleSelection = () => setSelectedIds(allSelected ? [] : cardsList)
  // END HANDLE CHECKBOX CHANGE

  // PAGE HANDLERS
  const lastPage = useLastPage(cards?.pagination, isLoading)
  const onLimitHandler = (e: IOption) => setLimit(e.value)
  const handleChangePage = (page: number) => {
    handleDeselectAll()
    changePage(page)
  }
  // END PAGE HANDLERS

  // MODAL HANDLERS
  const openModal = () => setIsOpen(true)
  const closeModal = () => {
    setIsOpen(false)
    setTypeOfRequest('')
  }
  const openTypeModal = (selected: ICardReqSingle) => {
    setTypeModalIsOpen(true)
    setTypeModalId(selected)
  }
  const closeTypeModal = () => {
    setTypeModalIsOpen(false)
  }
  // END MODAL HANDLERS

  // TABLE COLUMNS
  const columns = useMemo(
    () => [
      {
        Header: (
          <Checkbox
            label=''
            id='selectAll'
            checked={selectedIds.length === cardsList?.length}
            onChange={handleToggleSelection}
          />
        ),
        accessor: 'id',

        Cell: (row: any) => (
          <Controller
            name='cardId'
            control={control}
            render={({ field: { onChange } }) => (
              <Checkbox
                checked={selectedIds.some((item) => item.id === row.row.original.id)}
                onChange={(e) => {
                  onChange(e.target.checked)
                  handleCheckboxChange(e, row)
                }}
              />
            )}
          />
        ),
      },
      { Header: 'Imię', accessor: 'member.firstName' },
      { Header: 'Nazwisko', accessor: 'member.lastName' },
      { Header: 'Status', accessor: 'status' },
      {
        Header: 'Typ wniosku',
        accessor: 'type',
        Cell: ({ row }: any) => {
          return typeList.find((type: IDict) => type.id === row.original.type)?.name
        },
      },
      { Header: 'Oddział', accessor: 'branch.name' },
      { Header: 'Data wniosku', accessor: 'createdAt' },
      { Header: 'Data aktualizacji', accessor: 'updatedAt' },
      {
        Header: 'Akcje',
        accessor: 'action',
        Cell: (row: any) => {
          const declarationId = row.row.original.id
          return (
            <div className='flex'>
              <HoverIcon
                disabled={row.row.original?.archived}
                iconName='EyeIcon'
                title='Zobacz'
                onClick={() => navigate(`/card-request/${declarationId}`)}
              />
              {row.row.original.status === 'Nowy' && (
                <HoverIcon
                  disabled={row.row.original?.archived}
                  iconName='PencilIcon'
                  title='Zmień typ'
                  onClick={() => openTypeModal(row.row.original)}
                />
              )}
            </div>
          )
        },
      },
    ],
    [selectedIds, cards?.items, showCompleteData],
  )
  // END TABLE COLUMNS

  const loadingHandler = getLoadingHandler(error, !userLoaded, !isAllow)
  if (loadingHandler.show) return <LoadingModal {...loadingHandler} />
  if (isOpen && typeOfRequest) {
    return (
      <CardReqUpdate
        type={typeOfRequest}
        selectedIds={selectedIds}
        closeModal={closeModal}
        refetch={refetch}
        deselectAll={handleDeselectAll}
      />
    )
  }
  if (typeModalIsOpen && typeModalId) {
    return (
      <CardReqUpdateType
        selectedId={typeModalId}
        closeModal={closeTypeModal}
        refetch={refetch}
        deselectAll={handleDeselectAll}
      />
    )
  }
  console
  return (
    <div>
      <Typography size='xl' weight='semibold'>
        Lista wniosków o legitymację
      </Typography>

      <div className='my-5 flex'>
        <Button label='Utwórz wniosek' onClick={() => navigate(path.card.request.create)} />
      </div>

      <div className='bg-white p-5'>
        <div className='mb-4 items-center md:flex md:justify-between'>
          <Controller
            name='completeData'
            control={control}
            render={({ field: { onChange } }) => (
              <Checkbox
                label='Pokazuj kompletne dane'
                id='Pokazuj kompletne dane'
                checked={showCompleteData}
                onChange={(e) => {
                  onChange(e.target.checked)
                  setShowCompleteData((prev) => !prev)
                  refetch()
                }}
              />
            )}
          />
        </div>
        <div>
          {!isAdmin && (
            <div className='mb-4 flex flex-wrap items-center gap-2'>
              <Button label='Pokaż gotowe wydania' variant='secondary' />
              <Controller
                name='ArchiveData'
                control={control}
                render={({ field: { onChange } }) => (
                  <Checkbox
                    label='Pokaż archiwalne'
                    id='Pokaż archiwalne'
                    checked={showArchiveData}
                    onChange={(e) => {
                      onChange(e.target.checked)
                      setShowArchiveData((prev) => !prev)
                      refetch()
                    }}
                  />
                )}
              />
            </div>
          )}
          <div className='flex items-center justify-between gap-4'>
            <div className='flex items-center gap-4'>
              <Select
                width='215px'
                handleSelect={(val) => setTypeOfRequest(`${val.value}` as ICardReqTypeDict)}
                withEmpty
                selectLabel='Działania masowe'
                options={[
                  // { label: 'Zmień typ wniosku', value: 'new' },
                  { label: 'Zatwierdź wniosek', value: 'accepted' },
                  { label: 'Drukuj', value: 'printed' },
                  { label: 'Przekaż', value: 'in_branch' },
                  { label: 'Potwierdź wydanie', value: 'issued' },
                  { label: 'Odrzuć', value: 'rejected' },
                ]}
              />

              <Button label='Zastosuj' variant='secondary' onClick={openModal} />
            </div>
            <Select
              width='180px'
              handleSelect={(val) => setFilteredByStatus(`${val.value}`)}
              options={[
                { label: 'Filtruj według', value: 'filteredBy' },
                { label: 'Dacie (od do)', value: 'date' },
                { label: 'Oddziale', value: 'branch' },
                { label: 'Statusie', value: 'status' },
              ]}
            />
          </div>
        </div>

        <Table columns={columns} data={cardsList} />
        {isLoading && (
          <div className='flex justify-center p-4 align-middle'>
            <Spinner />
          </div>
        )}
        <div className='flex justify-between gap-4'>
          <div className='ml-auto flex items-center gap-4'>
            {lastPage >= 2 && (
              <>
                <div className='flex items-center gap-4'>
                  <p className='w-[120px] text-sm font-medium'>Wierszy na stronę</p>
                  <Select
                    handleSelect={onLimitHandler}
                    options={[
                      { label: '10', value: 10 },
                      { label: '20', value: 20 },
                    ]}
                  />
                </div>
                <Pagination
                  lastPage={lastPage}
                  currentPage={page}
                  handlePageChange={handleChangePage}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
