import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Typography } from 'components/Atoms/Typography'
import { RecordData } from 'components/Members/RecordData'
import { EditMember } from 'components/Members/EditMember'
import { ConfirmDeleteMemberModal } from 'components/Atoms/ConfirmDeleteMemberModal'
import { publicBaseApiURL } from 'api'
import { endpoint } from 'constants/endpoints'
import { queryKeys } from 'constants/queryKeys'
import useAuth from 'hooks/useAuth'
import { InfoUser } from 'components/User/InfoUser'
import { deleteUserRole, getUserById } from 'fetchers/userFetchers'
import { errorForbiddenHandler } from 'tools/queryHelpers'
import ErrorRouteModal from 'components/Atoms/ErrorRouteModal'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-toastify'
import { EditUser } from 'components/User/EditUser'
import { AxiosError } from 'axios'
import { mutationErrorHandler } from 'tools/errorHandler'
import { memberErrors } from 'error-data/member'
import { successToast } from 'tools/ToastHelpers'

export const UserDetails = () => {
  const [isDeleting, setIsDeleting] = useState(false)
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const { id: userId } = useParams()
  const { userToken, isAllAdmin, isAllOD, isAllOK } = useAuth()
  const queryClient = useQueryClient()

  const {
    data: userData,
    error,
    refetch,
  } = useQuery({
    queryKey: [userToken, queryKeys.user, userId],
    queryFn: () => {
      if (!userId) return
      return getUserById(userId)
    },
  })

  const deleteMutation = useMutation({
    mutationFn: (id: string) => deleteUserRole(id),
    onSuccess: () => {
      setIsDeleting(false)
      successToast('Usunięto rolę.')
      queryClient.invalidateQueries({ queryKey: [queryKeys.user] })
      refetch()
    },
    onError: (error: AxiosError) => {
      setIsDeleting(false)
      console.error(error)
      mutationErrorHandler(error, memberErrors.roleDelete, 'Nie udało się usunąć roli.')
    },
  })

  const deleteHandler = async (roleId: string) => {
    setIsDeleting(true)
    try {
      // deleteMutation.mutate(roleId)
    } catch (error) {
      setIsDeleting(false)
      console.error(error)
      mutationErrorHandler(null, null, 'Nie udało się usunąć roli.')
    }
  }

  const forbiddenRoute = errorForbiddenHandler(error)

  const mutate = () => {
    console.info('mutate')
  }
  const handleVisibleEditField = () => {
    setIsEdit((prev) => !prev)
  }
  if (forbiddenRoute) return <ErrorRouteModal />
  return (
    <>
      <div>
        <div className='flex justify-between'>
          <Typography size='xl' weight='semibold'>
            Użytkownik / {userData?.email} / {isEdit ? 'Edycja' : 'Podgląd'}
          </Typography>
        </div>
        {isEdit ? (
          <EditUser
            handleVisibleEditField={handleVisibleEditField}
            userData={userData}
            mutate={mutate}
          />
        ) : (
          <InfoUser
            handleVisibleEditField={handleVisibleEditField}
            deleteHandler={deleteHandler}
            data={userData}
          />
        )}

        {/* <RecordData /> */}
      </div>

      <ConfirmDeleteMemberModal
        isOpen={isOpenDeleteModal}
        handleClose={() => setIsOpenDeleteModal(false)}
      />
    </>
  )
}
