import { useMutation, useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { Card } from 'components/Atoms/Card'
import { LabelTypography } from 'components/Atoms/LabelTypography'
import TableLoader from 'components/Atoms/Loader/TableLoader'
import { queryKeys } from 'constants/queryKeys'
import { cardErrors } from 'error-data/card'
import { getCardPrint, getCardReqStatusDict } from 'fetchers/cardFetchers'
import useAuth from 'hooks/useAuth'
import { useState } from 'react'
import { errorToast } from 'tools/ToastHelpers'
import { mutationErrorHandler } from 'tools/errorHandler'
import { fileUpload } from 'tools/fileUpload'
import { IDict } from 'types/dictionary'
import { ICard } from 'types/member'

type Props = {
  card: ICard
  openNewModal: () => void
  openCancelModal: () => void
}

export const MemberID = ({ card, openNewModal, openCancelModal }: Props) => {
  const [isSending, setIsSending] = useState<boolean>(false)
  const { userToken } = useAuth()

  // REQUEST STATUS
  const { data: reqStatus, isLoading: statusLoading } = useQuery({
    queryKey: [userToken, queryKeys.cardReqStatusDict],
    queryFn: () => {
      return card ? getCardReqStatusDict() : null
    },
  })
  const statusList = reqStatus?.items || []
  const currentStatus = statusList.find((status: IDict) => status.id === card?.status)
  // END REQUEST STATUS

  // PRINT CARD
  const printMutation = useMutation({
    mutationFn: () => getCardPrint([{ id: card.id }]),
    onSuccess: (res) => {
      setIsSending(false)
      fileUpload(res)
    },
    onError: (error: AxiosError) => {
      console.error(error)
      setIsSending(false)
      mutationErrorHandler(error, cardErrors.print, cardErrors.print.message)
    },
  })
  // END PRINT CARD

  // BUTTON HANDLERS
  const handlePrint = async () => {
    try {
      setIsSending(true)
      await printMutation.mutateAsync()
    } catch (error) {
      console.error(error)
      setIsSending(false)
      mutationErrorHandler(null, cardErrors.print, cardErrors.print.message)
    }
  }
  // END BUTTON HANDLERS

  if (statusLoading) {
    return <TableLoader rowLength={1} label='Legitymacja' />
  }

  if (!card) {
    return (
      <Card
        label='Legitymacja'
        actionsButton={[{ label: 'Wydaj legitymację', handleClick: openNewModal }]}
      >
        <p className='mb-1 block text-sm font-medium text-gray-700'>Nie wydano legitymacji.</p>
      </Card>
    )
  }

  return (
    <Card
      label='Legitymacja'
      actionsButton={[
        { label: 'Nowa', handleClick: openNewModal },
        // { label: 'UNIEWAŻNIJ', handleClick: openCancelModal },
        { disabled: isSending, label: 'Drukuj', handleClick: () => handlePrint() },
      ]}
    >
      {/* <p>Work in progress...</p> */}
      <div className='grid grid-cols-5 '>
        <div className='mr-6'>
          <LabelTypography label='Number legitymacji' text={card?.number} />
        </div>
        <div className='mx-6'>
          <LabelTypography label='Status' text={currentStatus?.name} />
        </div>
        <div className='mx-6'>
          <LabelTypography label='Data wydania' text={String(card?.createdAt)} />
        </div>
        <div className='mx-6'>
          <LabelTypography label='Ostatnia zmiana' text={String(card?.updatedAt)} />
        </div>
      </div>
    </Card>
  )
}
