import { IOption } from 'types/form'
import { IUnitLimit } from 'types/structure'
import { IRegUser, IRemoteUser } from 'types/userlist'

export const dataToSelect = (data: any[]): IOption[] => {
  return (data || []).map((e: { name: string; id: string; type: string }) => {
    return { label: e?.name, value: e?.id || e?.type }
  })
}

export const getStatusObject = (data: IUnitLimit[]) => {
  const initData = {}
  data.forEach((u: IUnitLimit) => {
    Object.assign(initData, { [u.unitPosition.id]: u.currentNumber })
  })
  return initData
}

export const filterUniqueUsers = (data: IRemoteUser[] = [], exists: IRegUser[] = []) => {
  const existsIds = exists.map((e: IRegUser) => e.id)
  return data.filter((e: IRemoteUser) => !existsIds.includes(e.remoteId))
}
