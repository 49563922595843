import { publicBaseApiURL } from 'api'
import { endpoint } from 'constants/endpoints'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { AuthContext } from 'features/auth/authSlice'

export const fetchUserData = createAsyncThunk('auth/fetchUserData', async (authToken: string) => {
  try {
    const config = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-Auth-Token': `Bearer ${authToken}`,
      },
    }
    const response = await publicBaseApiURL.get(endpoint.user.me)

    return response.data
  } catch (error) {
    console.error(error)
    return error
  }
})

export const fetchUserContexts = createAsyncThunk(
  'auth/fetchUserContexts',
  async (authToken: string) => {
    try {
      const config = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-Auth-Token': `Bearer ${authToken}`,
        },
      }
      const response = await publicBaseApiURL.get(endpoint.user.availableContexts, config)
      return response.data.items
    } catch (error) {
      console.error(error)
      return error
    }
  },
)

export const changeContexts = createAsyncThunk(
  'auth/changeContext',
  async ({ authToken, context }: { authToken: string; context: AuthContext }) => {
    try {
      const config = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Auth-Token': `Bearer ${authToken}`,
        },
      }
      const response = await publicBaseApiURL.post(endpoint.user.changeContext, context, config)
      return response.data
    } catch (error) {
      console.error(error)
      return error
    }
  },
)
