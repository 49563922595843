import { Typography } from 'components/Atoms/Typography'
import { Link, useNavigate } from 'react-router-dom'
import { path } from 'constants/path'
import { useQuery } from '@tanstack/react-query'
import useAuth from 'hooks/useAuth'
import { queryKeys } from 'constants/queryKeys'
import { getTagsList } from 'fetchers/tagsFetchers'
import { errorQuery } from 'tools/queryHelpers'
import { Card } from 'components/Atoms/Card'
import { LoadingSection } from 'components/Atoms/LoadingSection'
import SettingsBaseList from 'components/Settings/SettingsBaseList'
import TagFrame from 'components/Atoms/TagFrame'
import { IListElement } from 'types/form'
import { Tag } from 'react-tag-autocomplete'

export const TagsList = () => {
  const navigate = useNavigate()
  const { userToken } = useAuth()
  const { data, isLoading, refetch } = useQuery({
    queryKey: [userToken, queryKeys.tagsList],
    queryFn: () => getTagsList(),
    retry: errorQuery,
  })

  const tagsList = data?.items
    ? data.items.map((item: IListElement) => ({ label: item.name, value: item.id }))
    : []

  const addNewTag = () => navigate(path.settings.tags.create)
  return (
    <section>
      <Typography size='xl' weight='semibold'>
        <Link to={path.settings.main}>Ustawienia</Link> / Lista tagów
      </Typography>
      <Card
        label='Lista tagów'
        actionsButton={[
          {
            label: 'Dodaj nowy tag',
            handleClick: addNewTag,
          },
        ]}
      >
        {isLoading ? (
          <LoadingSection />
        ) : (
          <ul className='flex flex-wrap gap-4 p-4'>
            {tagsList.map((t: Tag) => (
              <li key={String(t.value)}>
                <TagFrame tag={t} editToken />
              </li>
            ))}
          </ul>
        )}
      </Card>
    </section>
  )
}

export default TagsList
