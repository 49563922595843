import { errorMessage } from 'constants/errorMessage'
import * as yup from 'yup'

export const rodoMemberSchema = yup.object({
  resourceId: yup.string().required(errorMessage.requiredField),
  datetimeFrom: yup.date().required(errorMessage.requiredField),
  datetimeTo: yup
    .date()
    .required(errorMessage.requiredField)
    .min(yup.ref('datetimeFrom'), 'Data zakończenia musi być późniejsza niż data rozpoczęcia'),
})
