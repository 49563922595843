import { useQuery } from '@tanstack/react-query'
import LoadingModal from 'components/Atoms/LoadingModal'
import { LoadingSection } from 'components/Atoms/LoadingSection'
import { Typography } from 'components/Atoms/Typography'
import RodoResourceRequests from 'components/Rodo/RodoResourceRequests'
import { queryKeys } from 'constants/queryKeys'
import { getResourceMemberCriteria, getResourceMembersList } from 'fetchers/rodoFetchers'
import useAuth from 'hooks/useAuth'
import { getLoadingHandler } from 'tools/queryHelpers'
import { IDict } from 'types/dictionary'

export const ResourceMembersList = () => {
  const { userToken, isAllAdmin, isAllOD } = useAuth()

  const data = useQuery({
    queryKey: [userToken, queryKeys.rodoResourceMembers],
    queryFn: () => getResourceMembersList(),
  })

  const criteria = useQuery({
    queryKey: [userToken, queryKeys.rodoResourceMembersCriteria],
    queryFn: () => getResourceMemberCriteria(),
  })

  const dataList = data.data?.items || []
  const criteriaItems: IDict[] = criteria.data?.items || []

  const isLoading = data.isPending || criteria.isPending
  const isError = data.error || criteria.error
  const isValid = isAllAdmin() || isAllOD()
  const loadingHandler = getLoadingHandler(isError, isLoading, !isValid)
  if (loadingHandler.show) return <LoadingModal {...loadingHandler} />
  return (
    <div>
      <Typography size='xl' weight='semibold'>
        RODO / Lista zapytań o dane członków
      </Typography>
      <RodoResourceRequests type='members' list={dataList} criteria={criteriaItems} />
    </div>
  )
}

export default ResourceMembersList
