import React from 'react'
import { useNavigate } from 'react-router-dom'

const SecureConnectionRedirect = (): JSX.Element => {
  const navigate = useNavigate()
  return (
    <section>
      <h1>Funkcja dostępna tylko po bezpiecznym połączeniu do VPN</h1>
      <p>
        Dostęp do danych wrażliwych - zaloguj się do VPN Część systemu do której chcesz uzyskać
        dostęp, wymaga bezpiecznego połączenia VPN. Jeśli nie pamiętasz jak się podłączyć lub nie
        masz skonfigurowanego połączenia przejdź do instrukcji:
      </p>
      <a href=''>Instrukcja korzystania z bezpiecznego systemu VPN</a>
      <p>Zastrzeżona funkcja: (Wniosek o przeniesienie danych członka z innego oddziału)</p>
      <button onClick={() => navigate(-1)}>Wróc</button>
      <button>Skontaktuj się z it</button>
      <button>Sprawdź ponownie (30s)</button>
    </section>
  )
}

export default SecureConnectionRedirect
