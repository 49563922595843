import { useQuery } from '@tanstack/react-query'
import { Button } from 'components/Atoms/Button'
import { Card } from 'components/Atoms/Card'
import { LabelTypography } from 'components/Atoms/LabelTypography'
import { Table } from 'components/Atoms/Table'
import { queryKeys } from 'constants/queryKeys'
import { getBranchesListPrefiltred } from 'fetchers/branchFetchers'
import { getCensusDetails } from 'fetchers/censusFetchers'
import { getRegionsList } from 'fetchers/regionFetchers'
import useAuth from 'hooks/useAuth'
import { useMemo } from 'react'
import { Fragment } from 'react/jsx-runtime'
import { errorQuery } from 'tools/queryHelpers'
import { useNavigate } from 'react-router-dom'
import { path } from 'constants/path'

const CensusDetails = () => {
  const { userToken, id } = useAuth()
  const cardId = id as string
  const navigate = useNavigate()

  const {
    data: censusData,
    isPending: isCensusDataPending,
    isLoading: isCensusDataLoading,
    error: censusDataError,
    refetch: refetchCensusData,
  } = useQuery({
    queryKey: [userToken, queryKeys.censusDetails, id],
    queryFn: () => getCensusDetails(id),
  })
 
  // Fetch all regions
  const { data: regions } = useQuery({
    queryKey: [userToken, queryKeys.regionList],
    queryFn: () => getRegionsList(1, 1000, []), // Removed filtering by search
    retry: errorQuery,
  })

  // Fetch all branches
  const { data: branches } = useQuery({
    queryKey: [userToken, queryKeys.branchesList],
    queryFn: () => getBranchesListPrefiltred(1, 1000, ''), // Removed filtering by selected regions
    retry: errorQuery,
  })

  const translateBranch = (branchId: string) => {
    if (!branches) return 'Wczytuję...'
    const branch = branches?.items?.find((branch: any) => branch.id === branchId)
    return branch?.name || 'Nieznany'
  }

  const translateRegion = (regionId: string) => {
    if (!regions) return 'Wczytuję...'
    const region = regions?.items?.find((region: any) => region.id === regionId)
    return region?.name || 'Nieznany'
  }

  const getStatusText = (status: string) => {
    switch (status) {
      case 'awaiting':
        return 'Oczekiwanie'
      case 'completed':
        return 'Gotowy do pobrania'
      case 'error':
        return 'Konflikt'
      default:
        return ''
    }
  }

  const translateArchiveStatus = (archived: boolean) => {
    return archived ? 'Tak' : 'Nie'
  }

  const regionsColumns = useMemo(
    () => [
      {
        Header: 'Nazwa',
        accessor: 'id',
        Cell: ({ row }: any) => {
          return translateRegion(row.original?.id)
        },
      },
    ],
    [],
  )

  const branchesColumns = useMemo(
    () => [
      {
        Header: 'Nazwa',
        accessor: 'id',
        Cell: ({ row }: any) => {
          return translateBranch(row.original?.id)
        },
      },
      {
        Header: 'Jednostka nadrzędna',
        accessor: 'parentUnitId',
        Cell: ({ row }: any) => {
          return translateRegion(row.original?.parentUnitId)
        },
      },
    ],
    [],
  )

  if (isCensusDataLoading) {
    return (
      <Card label='Szczegóły spisu'>
        <dl className='grid grid-cols-1 text-base/6 sm:grid-cols-[min(50%,theme(spacing.80))_auto] sm:text-sm/6'>
          {[...Array(6)].map((_, index) => (
            <Fragment key={index}>
              <dt className='col-start-1 border-t border-zinc-950/5 pt-3 text-zinc-500 first:border-none sm:border-t sm:border-zinc-950/5 sm:py-3'>
                <div className='h-4 w-full max-w-[112px] animate-pulse rounded-md bg-slate-200'></div>
              </dt>
              <dd className='pb-3 pt-1 text-zinc-950 dark:text-white sm:border-t sm:border-zinc-950/5 sm:py-3 dark:sm:border-white/5 sm:[&:nth-child(2)]:border-none'>
                <div className='h-4 w-full max-w-[287px] animate-pulse rounded-md bg-slate-200'></div>
              </dd>
            </Fragment>
          ))}
        </dl>
      </Card>
    )
  }

  return (
    <>
      <Card label={censusData?.name}>
        <div className='grid grid-cols-1 gap-4 pb-4 sm:grid-cols-2 md:grid-cols-4'>
          <LabelTypography label='Data początku' text={censusData?.dateFrom} />
          <LabelTypography label='Data końca' text={censusData?.dateTo} />
          <div>
            <label htmlFor='' className='mb-1 block text-sm font-medium text-gray-700'>
              Status dokumentu
            </label>
            <span
              className={`inline-flex items-center rounded-full border px-2.5 py-0.5 text-xs font-semibold focus:ring-2 focus:ring-ring focus:ring-offset-2 text-gray-900 ${
                censusData?.status === 'completed'
                  ? 'bg-green-400 border-green-500'
                  : censusData?.status === 'error'
                  ? 'bg-red-400 border-red-500'
                  : 'bg-yellow-400 border-yellow-500'
              }`}
            >
              {getStatusText(censusData?.status)}
            </span>
          </div>
          <LabelTypography label='Archiwalny' text={translateArchiveStatus(censusData?.archived)} />
        </div>
      </Card>
      <Card label={'List okręgów'}>
        {censusData?.selectedRegions.length === 0 ? (
          <div className='text-sm text-gray-500'>Brak okręgów w spisie</div>
        ) : (
          <Table
            maxColumnWidth='300px'
            columns={regionsColumns}
            data={censusData?.selectedRegions}
          />
        )}
      </Card>
      <Card label={'List oddziałów'}>
        {censusData?.selectedBranches.length === 0 ? (
          <div className='text-sm text-gray-500'>Brak oddziałów w spisie</div>
        ) : (
          <Table
            maxColumnWidth='300px'
            columns={branchesColumns}
            data={censusData?.selectedBranches}
          />
        )}
      </Card>
      <div className='my-4 flex justify-center gap-4'>
        <Button
          variant='secondary'
          label='Powrót'
          onClick={(e) => {
            e.preventDefault()
            navigate(path.census.list)
          }}
        />
      </div>
    </>
  )
}

export default CensusDetails
