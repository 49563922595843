import { useMutation, useQuery } from '@tanstack/react-query'
import { Button } from 'components/Atoms/Button'
import { Card } from 'components/Atoms/Card'
import { Checkbox } from 'components/Atoms/Checkbox'
import { HoverIcon } from 'components/Atoms/HoverIcon'
import LoadingModal from 'components/Atoms/LoadingModal'
import { Select } from 'components/Atoms/Select'
import { Spinner } from 'components/Atoms/Spinner'
import { Table } from 'components/Atoms/Table'
import { Typography } from 'components/Atoms/Typography'
import BranchMigrationMassModal from 'components/Branch/BranchMigrationMassModal'
import { queryKeys } from 'constants/queryKeys'
import { branchMigrationErrors } from 'error-data/branch'
import { branchMigrationMassHandler } from 'fetchers/membersFetchers'
import { getMemberBranchMigrations } from 'fetchers/migrationFetchers'
import useAuth from 'hooks/useAuth'
import useSearch from 'hooks/useSearch'
import { useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { errorToast } from 'tools/ToastHelpers'
import { mutationErrorHandler } from 'tools/errorHandler'
import { setupDate } from 'tools/formTools'
import { errorQuery, getLoadingHandler, setupSearchFilters } from 'tools/queryHelpers'
import { ICardReqError } from 'types/cardRequest'
import { TMigration } from 'types/member'
import { IMemberMigrationItem, IMemberMigrationRes } from 'types/migration'

export const BranchMigrations = () => {
  const [isSending, setIsSending] = useState(false)
  const [sendFilter, setFilter] = useState<string>('')
  const [selectedIds, setSelectedIds] = useState<IMemberMigrationItem[]>([])
  const [isOpen, setIsOpen] = useState(false)
  const [typeOfRequest, setTypeOfRequest] = useState<TMigration>()
  const [fetchErrors, setFetchErrors] = useState<ICardReqError[]>([])

  const [showComplete, setShowComplete] = useState(false)
  const { perPage, page, setLimit, changePage } = useSearch({
    simpleParams: ['onlyUnhandled', 'status'],
  })
  const { userToken, userLoaded, isAllAdmin, isAllOD } = useAuth()
  const { control } = useForm()
  const isValid = isAllAdmin() || isAllOD()
  const navigate = useNavigate()

  // FETCH DATA
  const statusFilter = setupSearchFilters([{ status: sendFilter || '' }])
  const showUnhandled = sendFilter !== 'awaiting' ? '&filter[onlyUnhandled]=false' : ''
  const completeFilter = showComplete ? '&unredacted=true' : ''
  const branchesFilters = statusFilter + completeFilter + showUnhandled

  const { data, isLoading, error, refetch } = useQuery({
    queryKey: [userToken, queryKeys.branchMigrations, page, perPage, branchesFilters],
    queryFn: () => getMemberBranchMigrations(page, perPage, branchesFilters),
    retry: errorQuery,
  })
  const migrationsList = data?.items
    ? data?.items.map((item: IMemberMigrationRes) => {
        return {
          id: item.id,
          firstName: item.member?.firstName,
          lastName: item.member?.lastName,
          status: item.status,
          card: item.member?.card,
          createdAt: setupDate(item.createdAt),
          before: item.branchBefore?.name,
          after: item.branchAfter?.name,
          canHandle: item.canHandle,
          canCancel: item.canCancel,
        }
      })
    : []

  // HANDLE CHECKBOX CHANGE
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>, row: any) => {
    const isChecked = e.currentTarget.checked
    setSelectedIds((prevList) => {
      if (isChecked) {
        return [...prevList, row.row.original]
      } else {
        return prevList.filter((item) => item.id !== row.row.original.id)
      }
    })
  }
  // TABLE COLUMNS
  const columns = useMemo(
    () => [
      {
        Header: 'Wybierz',
        accessor: 'id',
        Cell: (row: any) => (
          <Controller
            name='cardId'
            control={control}
            render={({ field: { onChange } }) => (
              <Checkbox
                checked={selectedIds.some((item) => item.id === row.row.original.id)}
                onChange={(e) => {
                  onChange(e.target.checked)
                  handleCheckboxChange(e, row)
                }}
              />
            )}
          />
        ),
      },
      {
        Header: 'Imię',
        accessor: 'firstName',
      },
      {
        Header: 'Nazwisko',
        accessor: 'lastName',
      },
      {
        Header: 'Nr legitymacji',
        accessor: 'card',
        Cell: ({ row }) => {
          const card = row.original.card || '-'
          return <p>{card} </p>
        },
      },
      {
        Header: 'Odddział przekazujący',
        accessor: 'before',
      },
      {
        Header: 'Odddział przejmujący',
        accessor: 'after',
      },
      {
        Header: 'Termin zawiadomienia',
        accessor: 'createdAt',
      },
      {
        Header: 'Akcje',
        accessor: 'action',
        Cell: ({ row }: any) => {
          return (
            <div className='flex'>
              <HoverIcon
                disabled={row.original?.archived}
                iconName='EyeIcon'
                title='Szczegóły'
                onClick={() => navigate(row.original.id)}
              />
            </div>
          )
        },
      },
    ],
    [branchesFilters, selectedIds],
  )

  const selectorOptions = [
    { label: 'Oczekujące', value: 'awaiting' },
    { label: 'Zaakceptowane', value: 'accepted' },
    { label: 'Odrzucone', value: 'rejected' },
  ]
  const resolveOptions = [
    { label: 'Wycofaj zgłoszenia', value: 'cancel' },
    { label: 'Odrzuć zgłoszenia', value: 'reject' },
    { label: 'Zatwierdź zgłoszenia', value: 'accept' },
  ]

  const openModal = () => {
    if (selectedIds.length > 0) {
      setIsOpen(true)
    } else {
      errorToast('Zaznacz przynajmniej jeden wniosek')
    }
  }
  const closeModal = () => {
    setIsOpen(false)
    setTypeOfRequest(undefined)
    setSelectedIds([])
    setFetchErrors([])
  }
  // SEND HANDLER
  const mutation = useMutation({
    mutationFn: (type: TMigration) => {
      const data = selectedIds.map((item) => ({ id: item.id }))
      const formData = { items: data }
      return branchMigrationMassHandler(formData, type)
    },
    onSuccess: (_res, data) => {
      const message = {
        accept: 'Zaakceptowano wniosek.',
        reject: 'Odrzucono wniosek.',
        cancel: 'Anulowano wniosek.',
      }
      toast.success(message[data])
      setFetchErrors([])
      refetch()
      closeModal()
    },
    onError: (error: any, data) => {
      setIsSending(false)
      console.error('Error', error)
      setFetchErrors(error?.response?.data?.items || [])
      const message = {
        accept: 'Nie udało się zaakceptować wniosku.',
        reject: 'Nie udało się odrzucić wniosku.',
        cancel: 'Nie udało się anulować wniosku.',
      }
      mutationErrorHandler(error, branchMigrationErrors[data], message[data])
    },
  })

  const sendHandler = async (type: TMigration) => {
    try {
      setFetchErrors([])
      setIsSending(true)
      mutation.mutate(type)
    } catch (error) {
      setIsSending(false)
      console.error('Error', error)
      const message = {
        accept: 'Nie udało się zaakceptować wniosku.',
        reject: 'Nie udało się odrzucić wniosku.',
        cancel: 'Nie udało się anulować wniosku.',
      }
      mutationErrorHandler(null, branchMigrationErrors[data], message[type])
    }
  }

  const loadingHandler = getLoadingHandler(error, !userLoaded, !isValid)
  if (loadingHandler.show) return <LoadingModal {...loadingHandler} />

  return (
    <div>
      <Typography size='xl' weight='semibold'>
        Zgłoszenia o przeniesienie do oddziału
      </Typography>
      <Card label='Lista zgłoszeń'>
        <div className='flex items-center justify-between gap-5'>
          {isValid && (
            <div className='flex items-center justify-between gap-4'>
              <div className='flex items-center gap-4'>
                <Select
                  width='215px'
                  handleSelect={(val) => setTypeOfRequest(`${val.value}` as TMigration)}
                  withEmpty
                  selectLabel='Działania masowe'
                  options={resolveOptions}
                />

                <Button label='Zastosuj' variant='secondary' onClick={openModal} />
              </div>
            </div>
          )}
          <div className='flex items-center gap-4'>
            <Controller
              name='completeData'
              control={control}
              render={({ field: { onChange } }) => (
                <Checkbox
                  label='Pokazuj kompletne dane'
                  id='Pokazuj kompletne dane'
                  checked={showComplete}
                  onChange={(e) => {
                    onChange(e.target.checked)
                    setShowComplete(e.target.checked)
                    refetch()
                  }}
                />
              )}
            />
            <div className='w-48'>
              <Select handleSelect={(val) => setFilter(`${val.value}`)} options={selectorOptions} />
            </div>
          </div>
        </div>
        {isLoading ? <Spinner /> : <Table data={migrationsList} columns={columns} />}
        {migrationsList.length === 0 && !isLoading && (
          <Typography className='mb-2 text-center text-sm' size='sm' color='gray-500'>
            Brak zgłoszeń
          </Typography>
        )}
      </Card>
      <BranchMigrationMassModal
        isSending={isSending}
        closeModal={closeModal}
        sendHandler={sendHandler}
        type={typeOfRequest}
        isOpen={isOpen}
        migrations={selectedIds}
        fetchErrors={fetchErrors}
      />
    </div>
  )
}

export default BranchMigrations
