import { Button } from 'components/Atoms/Button'
import { Card } from 'components/Atoms/Card'
import { LabelTypography } from 'components/Atoms/LabelTypography'
import { Typography } from 'components/Atoms/Typography'
import { ArrowLeftIcon } from '@heroicons/react/24/outline'
import { useParams, useNavigate } from 'react-router-dom'

export const UserHistoryDetails = () => {
  const params = useParams()
  const navigate = useNavigate()
  return (
    <>
      <Card
        label={`Historia zmian użytkownika ${params.historyId}`}
        actionsButton={[{ label: 'Przywróć zmiany', handleClick: () => alert('przywróć zmiany.') }]}
      >
        <div className='flex'>
          <div className='mr-10'>
            <LabelTypography label='Zmieniający' text='Grzegorz Brzęczyszczykiewicz' />
          </div>
          <div className='mr-10'>
            <LabelTypography label='Data' text='2020-04-05' />
          </div>
          <LabelTypography label='Godzina' text='12:43' />
        </div>
        <Typography size='xl' className='mb-3 mt-5'>
          Zmienione treści
        </Typography>
        <div className='flex'>
          <div className='mr-10'>
            <LabelTypography label='Pole' text='Dodatkowy adres email' />
          </div>
          <div className='mr-10'>
            <LabelTypography label='Treść przed zmianą' text='anna.sklodowska@buziaczek.pl' />
          </div>
          <LabelTypography label='Treść po zmianie' text='ania13@hot.com' />
        </div>
      </Card>

      <Button
        label='Powrót do historii zmian'
        icon={<ArrowLeftIcon className='mr-2 w-4' />}
        onClick={() => navigate(`/member/${params.id}/history`)}
        className='mt-5'
      />
    </>
  )
}
