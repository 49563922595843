import { Card } from 'components/Atoms/Card'
import { BranchSections } from 'components/Branch/BranchSections'
import { BranchPerformedFunctions } from 'components/Branch/BranchPerformedFunctions'
import { History } from 'components/Utilites/History'
import { useParams } from 'react-router-dom'
import { BranchBaseInfo } from 'components/Branch/BranchBaseInfo'
import { getBranch } from 'fetchers/branchFetchers'
import { IOption } from 'types/form'
import { useAppSelector } from 'app/hooks'
import { queryKeys } from 'constants/queryKeys'
import { useQuery } from '@tanstack/react-query'
import { getSectionsList } from 'fetchers/sectionFetchers'
import { LoadingSection } from 'components/Atoms/LoadingSection'
import { errorQuery } from 'tools/queryHelpers'
import { getStructureFunctionList } from 'fetchers/structureFetcher'
import { useArchiveSection } from 'fetchers/hooks/useArchiveSection'
import { useState } from 'react'
import BranchDecorations from 'components/Branch/BranchDecorations'

export const BranchDetails = () => {
  const [current, setCurrent] = useState('true')
  const [page, setPage] = useState({
    current: 1,
    limit: 10,
  })
  const { id } = useParams()
  const branchId = id as string
  const { userToken } = useAppSelector((state: any) => state.authReducer)

  const onItemsPerPageChange = (data: IOption) => {
    setPage((prev) => ({ ...prev, limit: Number(data.value) }))
  }
  const changePage = (newPage: number) => {
    setPage((prev) => ({ ...prev, current: newPage }))
  }
  const {
    data: branch,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: [userToken, queryKeys.branch, branchId],
    queryFn: () => getBranch(branchId),
  })

  const { data: branchSections, isLoading: branchSectionsLoading } = useQuery({
    queryKey: [userToken, queryKeys.sectionsList, branchId],
    queryFn: () => getSectionsList(1, 100, [{ branch: branchId }]),
  })
  const functionFilters = `?page=${page.current}&perPage=${page.limit}&filter[onlyCurrent]=${current}`

  const { data: members, isLoading: membersIsLoading } = useQuery({
    queryKey: [userToken, queryKeys.memberSectionList, id, functionFilters],
    queryFn: () => {
      if (!id) return { items: [], pagination: {} }
      return getStructureFunctionList(id, functionFilters)
    },
    retry: errorQuery,
  })
  const toggleArchivedFunctions = () => {
    setCurrent((prev) => (prev === 'true' ? 'false' : 'true'))
  }

  const lastPage = members?.pagination?.lastPage || 1
  const usersList: any[] = []
  if (members?.items) {
    members.items.forEach((user: any) => {
      user.unitPositions.forEach((position: any) => {
        usersList.push({
          id: user.id,
          firstName: user?.firstName,
          lastName: user?.lastName,
          numberID: user?.numberID,
          functions: position.unitPosition.name,
          section: user?.section?.name,
        })
      })
    })
  }

  // MODAL HANDLER
  const { showModalHandler, ArchiveModal } = useArchiveSection({ refetch })

  const allowArchive = usersList?.length === 0 && branchSections?.items?.length === 0

  if (isLoading || branchSectionsLoading) {
    return <LoadingSection />
  }
  return (
    <>
      <BranchBaseInfo
        data={branch}
        showModalHandler={showModalHandler}
        allowArchive={allowArchive}
      />
      <BranchSections data={branchSections} />
      <BranchDecorations />
      <BranchPerformedFunctions
        isLoading={membersIsLoading}
        withArchived={current === 'false'}
        toggleArchivedFunctions={toggleArchivedFunctions}
        data={usersList}
        onPageChange={changePage}
        lastPage={lastPage}
        currentPage={page.current}
        onItemsPerPageChange={onItemsPerPageChange}
      />
      <Card label='Historia zmian'>
        <History />
      </Card>

      <ArchiveModal />
    </>
  )
}
