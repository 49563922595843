import { Button } from 'components/Atoms/Button'
import { Checkbox } from 'components/Atoms/Checkbox'
import { Select } from 'components/Atoms/Select'
import { Table } from 'components/Atoms/Table'
import { Typography } from 'components/Atoms/Typography'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useSelector, useDispatch } from 'react-redux'
import { DeclarationAccept } from 'components/Declaration/DeclarationsAccept'
import { successToast, wipMessage } from 'tools/ToastHelpers'
import { DeclarationsReject } from 'components/Declaration/DeclarationsReject'
import { AppDispatch } from 'app/store'
import { secureBaseApiURL } from 'api'
import { endpoint } from 'constants/endpoints'
import { useAppSelector } from 'app/hooks'
import { queryKeys } from 'constants/queryKeys'
import {
  acceptDeclaration,
  getDeclarationsList,
  rejectDeclaration,
} from 'fetchers/declarationFetcher'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { IFilters } from 'types/form'
import { getBranchesList, getBranchesListPrefiltred } from 'fetchers/branchFetchers'
import { getRegionsList } from 'fetchers/regionFetchers'
import { Controller, useForm } from 'react-hook-form'
import { getPosition } from 'fetchers/positionFetchers'
import { getInstitution } from 'fetchers/institutionFetchers'
import {
  fetchDeclarations,
  getDeclarationStatus,
  IAcceptDeclarationRequest,
  IRejectDeclarationRequest,
  IDeclaration,
} from 'features/Member/declaration/declarationSlice'
import { AxiosError } from 'axios'

import useSearchModule from 'hooks/useSearchModule'
import useLegalFetcher from 'hooks/useLegalFetcher'
import useAuth from 'hooks/useAuth'
import LoadingModal from 'components/Atoms/LoadingModal'
import { HoverIcon } from 'components/Atoms/HoverIcon'
import { declarationErrors } from 'error-data/declaration'
import { mutationErrorHandler } from 'tools/errorHandler'

export const Declarations = () => {
  const { filters, currentPage } = useSearchModule({ archive: false })
  const [isOpenAcceptStep, setIsOpenAcceptStep] = useState(false)
  const [isOpenRejectStep, setIsOpenRejectStep] = useState(false)
  const [showCompleteData, setShowCompleteData] = useState(false)
  const [isRegionSelected, setIsRegionSelected] = useState<boolean>(false)
  const [selectedRegion, setSelectedRegion] = useState<string | null>(null)
  const [selectedBranch, setSelectedBranch] = useState<string>('')
  const [acceptList, setAcceptList] = useState<IDeclaration[]>([])
  const [filteredByStatus, setFilteredByStatus] = useState<string>('')

  const { isAllAdmin, isAllOD, userLoaded } = useAuth()
  const { control } = useForm()
  const { userToken } = useAppSelector((state: any) => state.authReducer)

  const legalData = useLegalFetcher()
  const isAdmin = isAllAdmin()
  const idBranch = isAllOD()
  const queryClient = useQueryClient()

  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()
  const declarationStatus = useSelector(getDeclarationStatus)

  const unredacted = 'unredacted=true'

  const buildFilters = (currentFilters: IFilters[]) => {
    let additionalFilters = showCompleteData ? unredacted : ''

    if (filteredByStatus) {
      additionalFilters += `${additionalFilters ? '&' : ''}filter[status][]=${filteredByStatus}`
    }

    return `${currentFilters}&${additionalFilters}`
  }

  const runBuildFilters = buildFilters(filters)

  const {
    data: declarationData,
    isLoading: declarationLoading,
    error,
    refetch,
  } = useQuery({
    queryKey: [userToken, queryKeys.declarationList, currentPage, filters, runBuildFilters],
    queryFn: () => getDeclarationsList(currentPage, 10, runBuildFilters),
  })

  // GET REGIONS AND BRANCHES
  const { data: branches, isLoading: branchesLoading } = useQuery({
    queryKey: [userToken, queryKeys.branchesList, userLoaded, selectedRegion],
    queryFn: () => {
      if (!userLoaded || (isAdmin && !selectedRegion)) return []
      if (!isAdmin) return getBranchesList(1, 1000)
      return getBranchesListPrefiltred(1, 1000, `&filter[region][]=${selectedRegion}`)
    },
  })

  const branchOptions = branches?.items
    ? branches?.items?.map((item: any) => ({ label: item.name, value: item.id }))
    : []

  const { data: regions, isLoading: regionsLoading } = useQuery({
    queryKey: [userToken, queryKeys.regionList, userLoaded],
    queryFn: () => {
      if (!userLoaded || !isAdmin) return []
      return getRegionsList(1, 1000)
    },
  })

  const regionOptions = regions?.items
    ? regions?.items?.map((item: any) => ({ label: item.name, value: item.id }))
    : []

  // END GET REGIONS AND BRANCHES
  const isLoading = declarationLoading || branchesLoading || !userLoaded
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>, row: any) => {
    const isChecked = e.currentTarget.checked
    setAcceptList((prevList) => {
      if (isChecked) {
        return [...prevList, row.row.original]
      } else {
        return prevList.filter((item) => item.id !== row.row.original.id)
      }
    })
  }

  useEffect(() => {
    if (showCompleteData) {
      refetch()
    } else {
      refetch()
    }
  }, [showCompleteData])

  const handleConfirm = () => setIsOpenAcceptStep(true)
  const handleCancel = () => setIsOpenRejectStep(true)
  const handleCloseRejectedStep = () => setIsOpenRejectStep(false)

  const handleCheckAll = () => {
    if (declarationData?.items) {
      setAcceptList([...declarationData.items])
    }
  }

  const toggleCheckAll = () => {
    if (acceptList.length === declarationData?.items?.length) {
      setAcceptList([])
    } else {
      handleCheckAll()
    }
  }

  const mutation = useMutation({
    mutationFn: (data: IAcceptDeclarationRequest) => acceptDeclaration(data),
    onSuccess: () => {
      successToast('Deklaracja została zaakceptowana.')
      queryClient.invalidateQueries({ queryKey: [queryKeys.declarationList] })
      refetch()
      setIsOpenAcceptStep(false)
    },
    onError: (error: AxiosError, data) => {
      console.error('error', error)
      const noDeclaration = typeof data?.resolution !== 'string'
      const messageArr = []
      const noItems = data?.items?.length === 0
      if (noDeclaration) messageArr.push('Nie przypisano dokumentu')
      if (noItems) messageArr.push('Nie wybrano deklaracji')
      if (messageArr.length === 0) messageArr.push('Nie zaaakceptowano deklaracji.')
      const message = messageArr.join(', ')
      mutationErrorHandler(error, declarationErrors.accept, message)
    },
  })

  const mutationReject = useMutation({
    mutationFn: (data: IRejectDeclarationRequest) => rejectDeclaration(data),
    onSuccess: () => {
      successToast('Deklaracja została odrzucona.')
      queryClient.invalidateQueries({ queryKey: [queryKeys.declarationList] })
      refetch()
      setIsOpenRejectStep(false)
    },
    onError: (error: AxiosError) => {
      setIsOpenRejectStep(false)
      console.error('error', error)
      mutationErrorHandler(error, declarationErrors.reject, 'Nie udało się odrzucić deklaracji.')
    },
  })

  const rejectHandler = (data: IRejectDeclarationRequest) => {
    try {
      mutationReject.mutate(data)
    } catch (error) {
      setIsOpenRejectStep(false)
      console.error(error)
      mutationErrorHandler(null, null, 'Nie udało się odrzucić deklaracji.')
    }
  }

  useEffect(() => {
    dispatch(fetchDeclarations(filteredByStatus))
  }, [filteredByStatus, declarationStatus, dispatch])

  const columns = useMemo(
    () => [
      {
        Header: (
          <Checkbox
            label=''
            id='selectAll'
            checked={acceptList.length === declarationData?.items?.length}
            onChange={toggleCheckAll}
          />
        ),
        accessor: 'id',
        Cell: (row: any) => (
          <Controller
            name='unitId'
            control={control}
            render={({ field: { onChange } }) => (
              <Checkbox
                checked={acceptList.some((item) => item.id === row.row.original.id)}
                onChange={(e) => {
                  onChange(e.target.checked)
                  handleCheckboxChange(e, row)
                }}
              />
            )}
          />
        ),
      },
      {
        Header: 'Imię',
        accessor: 'firstName',
      },
      {
        Header: 'Nazwisko',
        accessor: 'lastName',
      },
      {
        Header: 'Telefon',
        accessor: 'phoneNumber',
      },
      {
        Header: 'PESEL',
        accessor: 'pesel',
      },
      {
        Header: 'Stanowisko',
        accessor: 'position',
        Cell: (row: any) => {
          const positionId = row.row.original.position
          const { data: position } = useQuery({
            queryKey: [userToken, queryKeys.position, positionId],
            queryFn: () => getPosition(positionId),
          })

          const displayName = position?.name
            ? showCompleteData
              ? position.name
              : position.name.charAt(0) + '...'
            : ''

          return <p>{displayName}</p>
        },
      },
      {
        Header: 'E-mail',
        accessor: 'email',
      },
      {
        Header: 'Główna placówka',
        accessor: 'basePlace',
        Cell: (row: any) => {
          const basePlaceId = row.row.original.basePlace

          const { data: institution } = useQuery({
            queryKey: [userToken, queryKeys.institution],
            queryFn: () => getInstitution(basePlaceId),
          })

          const displayName = institution?.name
            ? showCompleteData
              ? institution.name
              : institution.name.charAt(0) + '...'
            : ''

          return <p>{displayName}</p>
        },
      },
      {
        Header: 'Akcje',
        accessor: 'action',
        Cell: (row: any) => {
          const declarationId = row.row.original.id
          return (
            <div className='flex'>
              <HoverIcon
                disabled={row.row.original?.archived}
                iconName='EyeIcon'
                title='Zobacz'
                onClick={() => navigate(`/declaration/${declarationId}`)}
              />
              <HoverIcon
                disabled={row.row.original?.archived}
                iconName='PencilIcon'
                title='Edytuj'
                onClick={() => navigate(`/declaration/${declarationId}/update`)}
              />
              <HoverIcon iconName='TrashIcon' title='Edytuj' onClick={() => wipMessage()} />
            </div>
          )
        },
      },
    ],
    [declarationData?.items, acceptList, showCompleteData, runBuildFilters],
  )

  const verified = isAdmin || idBranch
  if (!verified) {
    return <LoadingModal isLoading={isLoading} forbidden={!verified} route={false} />
  }
  if (isOpenAcceptStep) {
    return (
      <DeclarationAccept
        choosenDeclaration={acceptList}
        handleAcceptDeclaration={mutation.mutate}
        handleCloseList={() => setIsOpenAcceptStep(false)}
        legalData={legalData}
        branchId={selectedBranch}
      />
    )
  }

  if (isOpenRejectStep) {
    return (
      <DeclarationsReject
        rejectedDeclarationList={acceptList}
        handleSubmit={rejectHandler}
        handleCancel={handleCloseRejectedStep}
      />
    )
  }

  const editIsActive = acceptList.length === 0 || selectedBranch === ''

  return (
    <div>
      <Typography size='xl' weight='semibold'>
        Deklaracje członkowskie
      </Typography>

      <div className='my-5 flex'>
        <Button label='Dodaj deklarację' onClick={() => navigate('/declaration/create')} />
      </div>

      <div className='bg-white p-5'>
        {isAdmin ? (
          <div className='mb-4 grid w-full max-w-2xl grid-cols-2 gap-4'>
            <div>
              <Controller
                name='regionId'
                control={control}
                render={({ field: { onChange } }) => (
                  <Select
                    handleSelect={({ value, label }) => {
                      onChange(value)
                      setSelectedRegion(String(value))
                      setSelectedBranch('')
                      setIsRegionSelected(label !== '---')
                    }}
                    label='Wskaż okręg'
                    withEmpty
                    selectLabel='---'
                    options={regionOptions}
                    isLoading={regionsLoading}
                  />
                )}
              />
            </div>
            {isRegionSelected && regionOptions.length > 0 && (
              <div>
                <Controller
                  name='branchId'
                  control={control}
                  defaultValue={{}}
                  render={({ field: { onChange } }) => (
                    <Select
                      handleSelect={({ value }) => {
                        onChange(value)
                        setSelectedBranch(String(value))
                        legalData.setUnit(String(value))
                      }}
                      label='Wskaż oddział'
                      withEmpty
                      selectLabel='---'
                      options={branchOptions}
                      isLoading={branchesLoading}
                    />
                  )}
                />
              </div>
            )}
          </div>
        ) : (
          <div className='mb-4 grid w-full max-w-2xl grid-cols-2 gap-4'>
            <Controller
              name='unitId'
              control={control}
              render={({ field: { onChange } }) => (
                <Select
                  handleSelect={({ value }) => {
                    onChange(value)
                    setSelectedBranch(String(value))
                  }}
                  label='Wskaż oddział'
                  withEmpty
                  selectLabel='---'
                  options={branchOptions}
                  isLoading={branchesLoading}
                />
              )}
            />
          </div>
        )}
        <div className='mb-4 flex items-end justify-between'>
          <div className='flex items-center gap-4'>
            <p className='text-sm font-medium'>Wierszy na stronę</p>
            <Select
              options={[
                { label: '10', value: 10 },
                { label: '20', value: 20 },
              ]}
            />
          </div>
          <div className='flex items-center'>
            <Controller
              name='completeData'
              control={control}
              render={({ field: { onChange } }) => (
                <Checkbox
                  label='Pokazuj kompletne dane'
                  id='Pokazuj kompletne dane'
                  checked={showCompleteData}
                  onChange={(e) => {
                    onChange(e.target.checked)
                    setShowCompleteData((prev) => !prev)
                    refetch()
                  }}
                />
              )}
            />
            <div className='ml-4 w-48'>
              <Select
                handleSelect={(val) => setFilteredByStatus(`${val.value}`)}
                options={[
                  { label: 'Oczekujące', value: 'awaiting' },
                  { label: 'Zaakceptowane', value: 'accepted' },
                  { label: 'Odrzucone', value: 'rejected' },
                ]}
              />
            </div>
          </div>
        </div>

        {!declarationData?.items ? (
          <p>Wczytuję...</p>
        ) : (
          <Table data={declarationData.items} columns={columns} />
        )}

        <div className='mt-4 flex justify-end'>
          <div className='mr-5'>
            <Button label='Odrzuć' variant='secondary' onClick={handleCancel} />
          </div>
          <Button label='Zatwierdź' onClick={handleConfirm} disabled={editIsActive} />
        </div>
      </div>
    </div>
  )
}
