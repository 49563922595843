import { path } from 'constants/path'
import { useNavigate } from 'react-router-dom'
import useAuth from 'hooks/useAuth'
import { LoadingSection } from 'components/Atoms/LoadingSection'
import ErrorRoleModal from 'components/Atoms/ErrorRoleModal'
import { getBranch } from 'fetchers/branchFetchers'
import { queryKeys } from 'constants/queryKeys'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { Typography } from 'components/Atoms/Typography'
import { IStructureMemberFunctionUpdate } from 'types/structure'
import { errorForbiddenHandler, errorNotExistsHandler, errorQuery } from 'tools/queryHelpers'
import { getMemberFunctionById, updateMemberFunction } from 'fetchers/membersFetchers'
import { setupDate } from 'tools/formTools'
import ErrorRouteModal from 'components/Atoms/ErrorRouteModal'
import { toast } from 'react-toastify'
import BranchFunctionUpdateForm from 'components/BranchFunctions/BranchFunctionUpdateForm'
import { AxiosError } from 'axios'
import { mutationErrorHandler } from 'tools/errorHandler'
import { unitTermErrors } from 'error-data/unit'
import { successToast } from 'tools/ToastHelpers'
export const BranchFunctionsUpdate = () => {
  const [isSending, setIsSending] = useState(false)
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { userToken, userLoaded, isAllAdmin, isAllOK, id, memberId, termId } = useAuth()

  // CHECK USER ROLE
  const isAdmin = isAllAdmin()
  const isRegion = isAllOK()

  // GET BRANCHES
  const {
    data: branch,
    isLoading: branchLoading,
    error: branchError,
  } = useQuery({
    queryKey: [userToken, queryKeys.branchesList, userLoaded],
    queryFn: () => getBranch(id),
    retry: errorQuery,
  })
  const notExists = errorNotExistsHandler(branchError)
  const forbiddenBranch = errorForbiddenHandler(branchError)
  // END GET BRANCHES

  // GET MEMBER FUNCTION
  const {
    data: memberFunction,
    isLoading: memberFunctionLoading,
    error: memberFunctionError,
  } = useQuery({
    queryKey: [userToken, queryKeys.memberFunctionById, memberId, termId],
    queryFn: () => getMemberFunctionById(memberId, termId),
    retry: errorQuery,
  })

  // END GET MEMBER FUNCTION

  // SEND HANDLERS
  const mutation = useMutation({
    mutationFn: (data: IStructureMemberFunctionUpdate) => updateMemberFunction(data),
    onSuccess: () => {
      successToast('Przypisano nową funkcję.')
      queryClient.invalidateQueries({ queryKey: [queryKeys.structureFunctions] })
      navigate(path.branchFunctions.list)
    },
    onError: (error: AxiosError) => {
      setIsSending(false)
      console.error(error)
      mutationErrorHandler(error, unitTermErrors.update, 'Nie udało się przypisać funkcji.')
    },
  })

  const onSubmit = async (data: any) => {
    const formData = {
      memberId,
      termId,
      startedAt: setupDate(data?.startedAt),
      endedAt: setupDate(data?.endedAt),
    }
    try {
      setIsSending(true)
      mutation.mutate(formData)
    } catch (error) {
      setIsSending(false)
      console.error(error)
      mutationErrorHandler(null, null, 'Nie udało się przypisać funkcji.')
    }
  }
  // END SEND HANDLERS

  const initData = {
    startedAt: memberFunction?.startedAt ? new Date(memberFunction?.startedAt) : undefined,
    endedAt: memberFunction?.endedAt ? new Date(memberFunction?.endedAt) : undefined,
  }
  if (memberFunction?.startedAt)
    Object.assign(initData, { startedAt: new Date(memberFunction?.startedAt) })

  // ERROR HANDLERS
  if (!userLoaded || branchLoading) return <LoadingSection />
  if (notExists) return <ErrorRouteModal />
  if ((!isAdmin && !isRegion) || forbiddenBranch) return <ErrorRoleModal />
  // END ERROR HANDLERS

  return (
    <section>
      <Typography size='xl' weight='semibold'>
        <span>Edytuj funkcję / USERNAME</span>
        <br />
        <span className='mb-5 mt-1 text-xs font-semibold text-gray-500'>
          {branch?.name ? ` ${branch?.name} (${branch?.parent?.name})` : ''}
        </span>
      </Typography>

      <BranchFunctionUpdateForm isSending={isSending} onSubmit={onSubmit} initData={initData} />
    </section>
  )
}
