import ErrorCard from 'components/ErrorCard'
import { useNavigate } from 'react-router-dom'

export const ErrorRoleModal = () => {
  const navigate = useNavigate()
  return (
    <ErrorCard
      roleError
      label={'Brak uprawnień'}
      message={'Nie masz uprawnień, aby przeglądać zawartość tej sekcji.'}
      onCancel={() => navigate('/')}
      buttonLabel={'Strona główna'}
    />
  )
}

export default ErrorRoleModal
