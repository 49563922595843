import React from 'react'
import { useQuery } from '@tanstack/react-query'
import { useAppSelector } from 'app/hooks'
import { Card } from 'components/Atoms/Card'
import { Table } from 'components/Atoms/Table'
import { TableName } from 'components/Atoms/TableName'
import { queryKeys } from 'constants/queryKeys'
import { getBranchesDecorationsList } from 'fetchers/branchFetchers'
import { useParams } from 'react-router-dom'
import TableLoader from 'components/Atoms/Loader/TableLoader'

const BranchDecorations = () => {
  const { userToken } = useAppSelector((state: any) => state.authReducer)

  const { id } = useParams()
  const branchId = id as string

  const {
    data: unitDecorationList,
    isLoading: unitDecorationListLoading,
    refetch: refetchUnitDecorationList,
  } = useQuery({
    queryKey: [userToken, queryKeys.unitDecorationList, branchId],
    queryFn: () => getBranchesDecorationsList(branchId),
  })

  const unitDecorationListItems = unitDecorationList?.items ? unitDecorationList?.items : []

  const columns = React.useMemo(
    () => [
      {
        Header: 'Imię i nazwisko członka',
        accessor: (row: any) => `${row.member.firstName} ${row.member.lastName}`,
        Cell: (row: any) => (
          <TableName
            name={`${row.row.original.member.firstName} ${row.row.original.member.lastName}`}
          />
        ),
      },
      {
        Header: 'Nazwa odznaczenia',
        accessor: 'decoration.name',
      },
      {
        Header: 'Osoba nadająca',
        accessor: 'grantedBy.email',
      },
      {
        Header: 'Jednostka nadająca',
        accessor: 'grantingUnit.name',
      },
      {
        Header: 'Data nadania odznaczenia',
        accessor: 'date',
      },
    ],
    [],
  )

  if (unitDecorationListLoading) {
    return <TableLoader label='Lista przyznanych odznaczeń w oddziale' rowLength={2} />
  }

  return (
    <Card label='Lista przyznanych odznaczeń w oddziale'>
      <Table data={unitDecorationListItems || []} columns={columns} />
    </Card>
  )
}

export default BranchDecorations
