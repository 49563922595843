export enum HttpStatus {
  IDLE = 'idle',
  FULFILLED = 'fulfilled',
  PENDING = 'pending',
  REJECTED = 'rejected',
}

export interface IMutationErrorType {
  [key: string]: IMutationErrorRoutes
}

export interface IMutationErrorRoutes {
  [key: string]: string
}
