import React, { Fragment, InputHTMLAttributes, useState, useEffect } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/20/solid'
import { useAppSelector } from 'app/hooks'
import { flatten } from 'lodash'
import { roleById } from 'tools/dictionaryHelper'

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

interface IRole {
  label: string
  value: string | number
}
interface IUnit {
  label: string
  value: string | number
}
interface IRoles {
  id: string | number
  role: IRole
  unit: IUnit
}
interface IProps {
  roles: IRoles[]
  label?: string
  error?: string
  onRoleDel: (role: IRole, unit: IUnit, id: string | number) => void
}
const normalFieldClass =
  'flex justify-between items-start relative w-full rounded-md border border-gray-300 bg-white px-2.5 py-1.5 text-sm text-left shadow-sm mb-2'

export const ListRoleUnit: React.FC<IProps> = ({ label, roles, onRoleDel, ...props }) => {
  const rolesData = useAppSelector((state) => state.rolesReducer.roles)
  const fRoles = flatten(Object.values(rolesData))
  return (
    <div>
      {label && <p className='mb-1 block text-sm font-medium text-gray-700'>{label}</p>}

      <div className='relative'>
        {roles.map((role: IRoles) => {
          return (
            <div className={normalFieldClass} key={role.id} {...props}>
              <p className='whitespace-pre-line'>
                {roleById(role?.role?.label, fRoles)}
                {role?.unit?.label ? ` (${role?.unit?.label})` : ''}
              </p>
              <button
                className='hover:opacity-75'
                onClick={() => onRoleDel(role?.role, role?.unit, role.id)}
              >
                <XMarkIcon className='mt-[1px] h-4 w-4 text-gray-700' />
              </button>
            </div>
          )
        })}
        {roles.length === 0 && <p className='text-sm text-gray-500'>Brak</p>}
      </div>
    </div>
  )
}
