// import React, { useEffect } from 'react'
import { ThreeCircles } from 'react-loader-spinner'
// import { useSelector, useDispatch } from 'react-redux'
// import { getUserInfo, getUserToken } from 'features/auth/authSlice'
// import { fetchUserData } from 'features/auth/authActions'
// import { useNavigate } from 'react-router-dom'
// import { AppDispatch } from 'app/store'
// import qs from 'qs'

export const LoadingLoginPage = () => {
  // const navigate = useNavigate();
  // const dispatch = useDispatch<AppDispatch>();
  // const user = useSelector(getUserInfo);
  // const authToken = useSelector(getUserToken);

  // const querystring = qs.parse(window.location.search, { ignoreQueryPrefix: true })
  // const token = querystring.token as string;

  // useEffect(() => {
  //   if(token) {
  //     dispatch(fetchUserData(token))
  //     localStorage.setItem('token', `${token}`)
  //   }
  //     if(user) {
  //       localStorage.setItem('userInfo', JSON.stringify(user))
  //       navigate('/dashboard')
  //     }
  // }, [user, authToken])

  return (
    <div className='flex h-screen w-full items-center justify-center'>
      <ThreeCircles
        height='100'
        width='100'
        color='blue'
        wrapperStyle={{}}
        wrapperClass=''
        visible={true}
        ariaLabel='three-circles-rotating'
        outerCircleColor=''
        innerCircleColor=''
        middleCircleColor=''
      />
    </div>
  )
}
