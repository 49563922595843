import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { Card } from 'components/Atoms/Card'
import ErrorRoleModal from 'components/Atoms/ErrorRoleModal'
import { BranchMergeForm } from 'components/Branch/BranchMergeForm'
import { path } from 'constants/path'
import { queryKeys } from 'constants/queryKeys'
import { branchErrors } from 'error-data/branch'
import {
  getBranch,
  getBranchesList,
  getBranchesListPrefiltred,
  mergeBranch,
} from 'fetchers/branchFetchers'
import useAuth from 'hooks/useAuth'
import useSearchModule from 'hooks/useSearchModule'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { successToast } from 'tools/ToastHelpers'
import { mutationErrorHandler } from 'tools/errorHandler'
import { errorForbiddenHandler, setupFilters } from 'tools/queryHelpers'
import { IMergeBranch, IMergeBranchFetcher } from 'types/branch'

export const BranchMerge = () => {
  const [isSending, setIsSending] = useState(false)
  const { perPage, setPerPage, setSearchString, filters } = useSearchModule({})
  const { userToken, id } = useAuth()
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const {
    data: current,
    isLoading: loadingCurrent,
    error,
  } = useQuery({
    queryKey: [userToken, queryKeys.branchesList, filters],
    queryFn: () => getBranch(id),
    retry: (failureCount, error) => {
      if ((error as AxiosError)?.response?.status === 403) return false
      return failureCount < 2
    },
  })

  const parent = current?.parent?.id

  const { data, isLoading: loadingRemoved } = useQuery({
    queryKey: [userToken, queryKeys.branchesList, filters, perPage, parent],
    queryFn: () => {
      if (id) {
        if (!parent) return { items: [], pagination: { count: 0, countFiltered: 0 } }
        const filterData = setupFilters(filters)
        return getBranchesListPrefiltred(1, perPage, `${filterData}&filter[region][]=${parent}`)
      }
      return getBranchesList(1, perPage, filters)
    },
  })

  const mutation = useMutation({
    mutationFn: (data: IMergeBranchFetcher) => mergeBranch(data),
    onSuccess: () => {
      successToast('Złączono oddziały.')
      queryClient.invalidateQueries({
        queryKey: [queryKeys.branchesList, queryKeys.branch, data.toRemove, data.target],
      })
      navigate(path.branch.list)
    },
    onError: (error: any) => {
      setIsSending(false)
      console.error(error)
      mutationErrorHandler(error, branchErrors.merge, 'Nie udało się złączyć oddziałów.')
    },
  })

  const searchMore = () => {
    const count =
      data?.pagination?.countFiltered > 0
        ? data?.pagination?.countFiltered
        : data?.pagination?.count
    if (!loadingRemoved && data?.items?.length < count) setPerPage((prev) => prev + 10)
  }

  const setQuery = (e: string) => {
    setSearchString(e)
  }

  const removedList = (data?.items || []).map((e: { name: string; id: string }) => {
    return { label: e.name, value: e.id }
  })

  const onSubmit = async (data: IMergeBranchFetcher) => {
    try {
      setIsSending(true)
      mutation.mutate(data)
    } catch (error) {
      setIsSending(false)
      console.error(error)
      mutationErrorHandler(null, null, 'Nie udało się złączyć oddziałów.')
    }
  }

  if (errorForbiddenHandler(error)) return <ErrorRoleModal />

  return (
    <Card label={'Połącz oddziały'}>
      <BranchMergeForm
        isSending={isSending}
        id={id}
        onSubmit={onSubmit}
        currentName={current?.name}
        searchMore={searchMore}
        loadingRemoved={loadingRemoved}
        removedList={removedList}
        setQuery={setQuery}
      />
    </Card>
  )
}
function setIsSending(arg0: boolean) {
  throw new Error('Function not implemented.')
}
