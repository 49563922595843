import { publicBaseApiURL } from 'api'
import { endpoint } from 'constants/endpoints'

export const getStructureTypesList = async (page: number, count: number) => {
  const res = await publicBaseApiURL.get(
    `${endpoint.structure.baseStructures}?page=${page}&perPage=${count}`,
    {},
  )
  return res?.data
}

export const getStructureFunctionList = async (id: string, filters?: string) => {
  const res = await publicBaseApiURL.get(endpoint.structure.functions(id, filters || ''), {})
  return res?.data
}

export const getStructureFunctionStatus = async (id: string) => {
  const res = await publicBaseApiURL.get(endpoint.structure.functionStatus(id), {})
  return res?.data
}
