import { Button } from './Atoms/Button'
import { Card } from './Atoms/Card'

type Props = {
  label: string
  message: string
  onCancel: () => void
  buttonLabel: string
  error?: any
  roleError?: boolean
}

export const ErrorCard = ({ roleError, label, message, onCancel, buttonLabel, error }: Props) => {
  if (!roleError) console.error(error)
  return (
    <section className='mx-auto max-w-xl'>
      <Card label={label}>
        <div className='mb-2 flex flex-col items-center'>
          <p className='mb-6 mt-2 text-center text-xl'>{message}</p>

          <Button label={buttonLabel} onClick={onCancel} />
        </div>
      </Card>
    </section>
  )
}

export default ErrorCard
