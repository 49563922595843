import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation, useQuery } from '@tanstack/react-query'
import clsx from 'clsx'
import { Button } from 'components/Atoms/Button'
import { Card } from 'components/Atoms/Card'
import LoadingModal from 'components/Atoms/LoadingModal'
import SearchAndFetchInput from 'components/Atoms/SearchAndFetchInput/SearchAndFetchInput'
import { Table } from 'components/Atoms/Table'
import { TextareaField } from 'components/Atoms/TextareaField'
import { Typography } from 'components/Atoms/Typography'
import { queryKeys } from 'constants/queryKeys'
import { migrationErrors } from 'error-data/member-migration'
import { getBranch, getBranchesListPrefiltredGlobal } from 'fetchers/branchFetchers'
import { moveMassMemberToSection } from 'fetchers/migrationFetchers'
import { getRegionsList } from 'fetchers/regionFetchers'
import { getSectionsListPrefiltred } from 'fetchers/sectionFetchers'
import useAuth from 'hooks/useAuth'
import useSearch from 'hooks/useSearch'
import { useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { moveMassSectionSchema } from 'schemas/unitChangeSchema'
import { errorRequires, errorToast, successToast } from 'tools/ToastHelpers'
import { mutationErrorHandler } from 'tools/errorHandler'
import { getLoadingHandler, setupSearchFilters, setupSearchMultiFilters } from 'tools/queryHelpers'
import { ICardReqError, IError } from 'types/cardRequest'
import { IMemberListing } from 'types/member'
import { ISectionMassMoveRequest } from 'types/section'

type Props = {
  closeModal: () => void
  members: IMemberListing[]
  refetch: () => void
}

export const MemberMassSectionModal = ({ closeModal, members, refetch }: Props) => {
  const [fetchErrors, setFetchErrors] = useState<ICardReqError[]>([])
  const [isSending, setIsSending] = useState(false)
  const [selectedBranch, setSelectedBranch] = useState('')
  const [selectedRegion, setSelectedRegion] = useState<string | undefined>()
  const [branchPerPage, setBranchPerPage] = useState(10)
  const [sectionPerPage, setSectionPerPage] = useState(10)

  const { userToken, userLoaded, isAllAdmin, isAllOD, unitContext } = useAuth()
  const { param } = useSearch({ simpleParams: ['name', 'sectionName'] })
  const isAdmin = isAllAdmin()
  const isBranch = isAllOD()
  const isValid = isAdmin || isBranch

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {
      items: members.map((member) => ({ id: member.id })),
      comment: '',
      sectionId: '',
      branchId: '',
      regionId: '',
    },
    resolver: yupResolver(moveMassSectionSchema),
  })
  const onValidationFailure = () => errorRequires(errors)
  // GET REGIONS
  const {
    data: regions,
    isLoading: regionsLoading,
    error: regionsError,
  } = useQuery({
    queryKey: [userToken, queryKeys.regionList, userLoaded],
    queryFn: () => {
      if (!isAdmin) return []
      return getRegionsList(1, 1000)
    },
  })
  const regionsList = regions?.items
    ? regions?.items?.map((item: any) => ({ label: item.name, value: item.id }))
    : []
  // END GET REGIONS

  // GET BRANCHES
  const { data: branch, isLoading: branchLoading } = useQuery({
    queryKey: [userToken, queryKeys.branch, unitContext?.id],
    queryFn: () => {
      if (isAdmin) return []
      return getBranch(unitContext?.id)
    },
  })

  const branchesFilters = selectedRegion
    ? setupSearchFilters([{ name: param?.single?.name || '' }]) +
      setupSearchMultiFilters([{ region: [selectedRegion] }])
    : ''
  // setupSearchMultiFilters([{ region: [regionId] }])
  const {
    data: branches,
    isLoading: branchesLoading,
    error: branchesError,
  } = useQuery({
    queryKey: [userToken, queryKeys.sectionsList, selectedRegion, branchPerPage, branchesFilters],
    queryFn: () => {
      if (!isAdmin) return []
      return getBranchesListPrefiltredGlobal(1, branchPerPage, branchesFilters)
    },
  })
  const branchesList = branches?.items
    ? branches?.items?.map((item: any) => ({ label: item.name, value: item.id }))
    : []
  // END GET BRANCHES

  const branchId = branch?.id || selectedBranch
  // GET SECTIONS
  const sectionFilters =
    setupSearchFilters([{ name: param?.single?.sectionName || '' }]) +
    setupSearchMultiFilters([{ branch: [branchId] }])

  const {
    data: sections,
    isLoading: sectionsLoading,
    error: sectionsError,
  } = useQuery({
    queryKey: [userToken, queryKeys.sectionsList, sectionFilters],
    queryFn: () => getSectionsListPrefiltred(1, sectionPerPage, sectionFilters),
  })
  const sectionsList = sections?.items
    ? sections?.items?.map((item: any) => ({ label: item.name, value: item.id }))
    : []

  const searchMoreBranchHandler = () => {
    const count =
      branches?.pagination?.countFiltered > 0
        ? branches?.pagination?.countFiltered
        : branches?.pagination?.count
    if (!branchesLoading && branches?.items?.length < count)
      setBranchPerPage((prev: number) => prev + 10)
  }

  const searchMoreSectionHandler = () => {
    const count =
      sections?.pagination?.countFiltered > 0
        ? sections?.pagination?.countFiltered
        : sections?.pagination?.count
    if (!sectionsLoading && sections?.items?.length < count)
      setSectionPerPage((prev: number) => prev + 10)
  }

  const loadingData = regionsLoading || branchesLoading || branchLoading

  // SEND MOVE REQUEST
  const moveSection = useMutation({
    mutationFn: (data: ISectionMassMoveRequest) => {
      return moveMassMemberToSection(data)
    },
    onSuccess: () => {
      successToast('Przypisano członka do nowej sekcji')
      closeModal()
    },
    onError: (error: any) => {
      setIsSending(false)
      console.error(error)
      setFetchErrors(error?.response?.data?.items || [])
      const message = 'Nie udało się przypisać członka do sekcji.'
      mutationErrorHandler(error, migrationErrors.mass, message)
    },
  })
  const onSubmit = async (data: ISectionMassMoveRequest) => {
    try {
      setFetchErrors([])
      const { regionId, branchId, ...form } = data
      setIsSending(true)
      moveSection.mutate(form)
    } catch (error) {
      setIsSending(false)
      console.error(error)
      errorToast('Nie udało się zmienić sekcji.')
    }
  }
  // END SEND MOVE REQUEST

  const columns = useMemo(
    () => [
      {
        Header: 'Imię',
        accessor: 'firstName',
      },
      {
        Header: 'Nazwisko',
        accessor: 'lastName',
      },
      {
        Header: 'Okręg',
        accessor: 'region',
        Cell: ({ row }: any) => row.original.region.name,
      },
      {
        Header: 'Oddział',
        accessor: 'branch',
        Cell: ({ row }: any) => row.original.branch.name,
      },
      {
        Header: 'Walidacja',
        accessor: 'valid',
        Cell: ({ row }: any) => {
          const currentError = fetchErrors.find((error) => error.id === row.original.id)
          const waiting = fetchErrors.length === 0
          return (
            <span
              className={clsx(
                'border-grey-500 rounded border border-neutral-600 p-1 px-2 text-center text-sm text-neutral-900',
                { 'bg-green-500': !currentError && !waiting },
                { 'bg-red-500': currentError },
                { 'bg-yellow-500': waiting },
              )}
            >
              {!currentError ? (waiting ? 'Oczekuje' : 'OK') : 'Konflikt'}
            </span>
          )
        },
      },
      {
        Header: 'Uwagi',
        accessor: 'comment',
        Cell: ({ row }: any) => {
          const currentError = fetchErrors.find((error) => error.id === row.original.id)
          if (!currentError) return null
          return (
            <ul className='list-inside list-disc'>
              {currentError.errors?.map((error: IError, index: number) => (
                <li key={`${row.original.id}-${index}`}>{error.error}</li>
              ))}
            </ul>
          )
        },
      },
    ],
    [fetchErrors],
  )
  const regionSetter = (value: string) => {
    setSelectedRegion(value)
    setValue('branchId', '')
    setValue('sectionId', '')
    setSelectedBranch('')
  }
  const branchSetter = (value: string) => {
    setSelectedBranch(value)
    setValue('sectionId', '')
  }
  const loadingHandler = getLoadingHandler(sectionsError, !userLoaded, !isValid)
  if (loadingHandler.show) return <LoadingModal {...loadingHandler} />
  return (
    <section>
      <Typography size='xl' weight='semibold'>
        Lista członków / Przypisz sekcję
      </Typography>
      <Card label='Przypisz członków do sekcji'>
        <Table columns={columns} data={members} maxColumnWidth='250px' />
        <form className='grid grid-cols-8' onSubmit={handleSubmit(onSubmit, onValidationFailure)}>
          <div className='col-start-2 col-end-8 mb-4'>
            {isAdmin ? (
              <div className='grid grid-cols-3 gap-4'>
                <SearchAndFetchInput
                  filterBox
                  externalChangeValue={regionSetter}
                  isLoading={regionsLoading}
                  name={'regionId'}
                  label={'Wyszukaj region'}
                  register={register}
                  options={regionsList}
                  control={control}
                />
                {selectedRegion && (
                  <SearchAndFetchInput
                    externalChangeValue={branchSetter}
                    searchMore={searchMoreBranchHandler}
                    isLoading={branchesLoading}
                    name={'branchId'}
                    label={'Wyszukaj oddział'}
                    register={register}
                    options={branchesList}
                    control={control}
                  />
                )}
                {selectedBranch && (
                  <SearchAndFetchInput
                    searchMore={searchMoreSectionHandler}
                    isLoading={sectionsLoading}
                    name={'sectionId'}
                    label={'Wyszukaj ognisko'}
                    register={register}
                    options={sectionsList}
                    control={control}
                    searchName='sectionName'
                  />
                )}
              </div>
            ) : (
              <SearchAndFetchInput
                searchMore={searchMoreSectionHandler}
                isLoading={sectionsLoading}
                name={'sectionId'}
                label={'Wyszukaj ognisko'}
                register={register}
                options={sectionsList}
                control={control}
              />
            )}
          </div>
          <div className='col-start-3 col-end-7'>
            <TextareaField
              error={errors.comment?.message}
              {...register('comment')}
              label='Powód przeniesienia'
              name='comment'
            />
          </div>
          <div className='col-span-8 mt-5 flex items-center justify-center gap-5'>
            <Button label='Wróć' variant='secondary' onClick={closeModal} />
            <Button disabled={isSending} type='submit' label='Przenieś' />
          </div>
        </form>
      </Card>
    </section>
  )
}

export default MemberMassSectionModal
