import { Button } from 'components/Atoms/Button'
import { Typography } from 'components/Atoms/Typography'
import { path } from 'constants/path'
import { useNavigate } from 'react-router-dom'

export const StatisticalObligation = () => {
  const navigate = useNavigate()
  return (
    <div>
      <Typography>Obowiązek statystyczny</Typography>
      <div className='mt-5 grid grid-cols-6 gap-5'>
        <Button
          label='Zarząd główny'
          onClick={() => navigate(path.statisticalObligation.mainManagment.main)}
        />
        <Button label='Okręg' onClick={() => navigate(path.statisticalObligation.region.main)} />
        <Button label='Oddział' onClick={() => navigate(path.statisticalObligation.branch.main)} />
        <Button label='Ognisko' onClick={() => navigate(path.statisticalObligation.section.list)} />
      </div>
    </div>
  )
}
