import { useQuery } from '@tanstack/react-query'
import { Card } from 'components/Atoms/Card'
import LoadingModal from 'components/Atoms/LoadingModal'
import { Typography } from 'components/Atoms/Typography'
import RodoAccessMemberForm from 'components/Rodo/RodoAccessMemberForm'
import { queryKeys } from 'constants/queryKeys'
import { getAccessMemberCriteria } from 'fetchers/rodoFetchers'
import useAuth from 'hooks/useAuth'
import { getLoadingHandler } from 'tools/queryHelpers'
import { IDict } from 'types/dictionary'

export const AccessMemberReq = () => {
  const { userToken, userLoaded, isAllAdmin, isAllOD } = useAuth()

  const { data, error, isPending } = useQuery({
    queryKey: [userToken, queryKeys.rodoAccessMembersCriteria],
    queryFn: () => getAccessMemberCriteria(),
  })

  const criteriaList = data?.items
    ? data?.items.map((item: IDict) => ({ value: item.id, label: item.name }))
    : []

  const isLoading = isPending || !userLoaded
  const isValid = isAllAdmin() || isAllOD()
  const loadingHandler = getLoadingHandler(error, isLoading, !isValid)
  if (loadingHandler.show) return <LoadingModal {...loadingHandler} />
  return (
    <div>
      <Typography size='xl' weight='semibold'>
        RODO / Nowe zapytanie o operacje na danych członków
      </Typography>
      <Card label='Formularz zapytania'>
        <RodoAccessMemberForm type='members' criteriaList={criteriaList} />
      </Card>
    </div>
  )
}

export default AccessMemberReq
