import { IMutationErrorType } from 'types/httpStatus.enum'

export const cardErrors: IMutationErrorType = {
  create: {
    message: 'Nie udało się wysłać wniosku',
    u406: 'Nie można utworzyć legitymacji dla danego członka.',
  },
  reqUpdate: {
    message: 'Nie udało się zaktualizować wniosku',
    u422: 'Część wniosków nie spełnia kryteriów zmiany statusu.',
  },
  type: {
    message: 'Nie udało się zaktualizować wniosku',
    u422: 'Nie można zmienić typu wniosku.',
  },
  print: {
    message: 'Nie udało się wydrukować legitymacji',
    u422: 'Niepoprawne dane.',
  },
}
