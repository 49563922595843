import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { publicBaseApiURL } from 'api'
import { RootState } from 'app/store'
import { endpoint } from 'constants/endpoints'
import { unionBy } from 'lodash'
import { HttpStatus } from 'types/httpStatus.enum'

const initialState: {
  combinedItems: {
    id: string
    name: string
    type: string
    subtype: string
    childrenCnt: number
  }[]
  status: HttpStatus
  items: {
    id: string
    name: string
    type: string
    subtype: string
    childrenCnt: number
  }[]
  pagination: {
    currentPage: number
    lastPage: number
    count: number
    countFiltered: number
    lastPageFiltered: number
  }
} = {
  status: HttpStatus.IDLE,
  items: [],
  combinedItems: [],
  pagination: {
    currentPage: 0,
    lastPage: 0,
    count: 0,
    countFiltered: 0,
    lastPageFiltered: 0,
  },
}

export const getSectionsAsync = createAsyncThunk(
  'structure/sections',
  async ({ page, count }: { page: number; count: number }) => {
    try {
      const response = await publicBaseApiURL.get(
        endpoint.structure.sectionsUser(page, count ? count : 100),
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'X-Auth-Token': `Bearer ${localStorage.getItem('token')}`,
          },
        },
      )
      return response.data
    } catch (err) {
      return err
    }
  },
)

export const structureSectionsSlice = createSlice({
  name: 'structure/sections',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getSectionsAsync.pending, (state) => {
        state.status = HttpStatus.PENDING
      })
      .addCase(getSectionsAsync.fulfilled, (state, action) => {
        state.status = HttpStatus.FULFILLED
        state.items = action.payload.items
        state.pagination = action.payload.pagination
        state.combinedItems = unionBy(state.combinedItems, action.payload.items, 'id')
      })
      .addCase(getSectionsAsync.rejected, (state) => {
        state.status = HttpStatus.REJECTED
      })
  },
})

export const getSections = (state: RootState) => state.structureSectionsReducer.items

export default structureSectionsSlice.reducer
