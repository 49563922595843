import { Button } from '../Button'

type Props = {
  handleCheckAll: () => void
  handleUnCheckAll: () => void
}

export const FilterActionMenu = ({ handleCheckAll, handleUnCheckAll }: Props) => {
  return (
    <div className='mb-2 mt-4 flex gap-4'>
      <Button label='zaznacz wszystkie' onClick={handleCheckAll} />
      <Button label='odznacz wszystkie' onClick={handleUnCheckAll} />
    </div>
  )
}

export default FilterActionMenu
