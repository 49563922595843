import { Button } from 'components/Atoms/Button'
import { Card } from 'components/Atoms/Card'
import { LabelTypography } from 'components/Atoms/LabelTypography'
import { TextField } from 'components/Atoms/TextField'
import { Dispatch, SetStateAction, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { personalMemberSchema } from 'schemas/memberSchema'
import { toast } from 'react-toastify'
import { Calendar } from 'components/Atoms/Calendar'
import { IMemberContact, IMemberPersonal } from 'types/member'
import useAuth from 'hooks/useAuth'
import clsx from 'clsx'
import { sexType } from 'constants/sex'

type Props = {
  data: IMemberContact
  personalData: IMemberPersonal
  isSending?: boolean
  isEdit?: boolean
  isReveal?: boolean
  setIsEdit: (val: boolean) => void
  onReveal: Dispatch<SetStateAction<boolean>>
  onSubmit: (data: any) => void
}

export const MemberContactData = ({
  data,
  isSending,
  isEdit,
  isReveal,
  personalData,
  setIsEdit,
  onReveal,
  onSubmit,
}: Props) => {
  const { isAllAdmin, isAllOD } = useAuth()
  const isEditAllow = isAllAdmin() || isAllOD()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      phone: data?.phone,
      email: data?.email,
      emailSecondary: data?.emailSecondary,
    },
    resolver: yupResolver(personalMemberSchema),
  })

  const handleVisibleEditField = () => {
    onReveal(true)
    setIsEdit(true)
  }

  const colsNum = isEditAllow ? 'grid-cols-3' : 'grid-cols-4'
  const contactCols = isEditAllow ? 'col-span-2' : ''
  const labelButtons = isEditAllow
    ? [
        {
          disabled: isSending || isEdit,
          label: 'Edytuj dane',
          handleClick: handleVisibleEditField,
        },
        {
          disabled: isEdit,
          type: 'button',
          label: isReveal ? 'Ukryj dane' : 'Pokaż dane',
          handleClick: () => onReveal((prev) => !prev),
        },
      ]
    : []
  if (isEdit) {
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card label='Dane osobowe' actionsButton={labelButtons}>
          <div className={clsx('grid gap-4', colsNum)}>
            <div>
              <LabelTypography label='Data urodzenia' text={personalData?.birthDate} />
            </div>
            <div>
              <TextField {...register('email')} label='Adres e-mail' />
            </div>
            <div>
              <TextField {...register('emailSecondary')} label='Adres e-mail (dodatkowy)' />
            </div>
            <div>
              <LabelTypography label='Płeć' text={personalData?.sex} />
            </div>
            {/* <div className='col-span-2'>
              <TextField {...register('secondEmail')} label='Dodatkowy Adred e-mail' />
            </div>
            <div></div> */}
            <div className={contactCols}>
              <TextField {...register('phone')} error={errors.phone?.message} label='Telefon' />
            </div>
          </div>
          <div className='mt-5 flex justify-center gap-5'>
            <Button label='Anuluj' variant='secondary' onClick={() => setIsEdit(false)} />
            <Button label='Zapisz' variant='primary' type='submit' />
          </div>
        </Card>
      </form>
    )
  }

  return (
    <Card label='Dane osobowe' actionsButton={labelButtons}>
      <div className={clsx('grid gap-4', colsNum)}>
        <div>
          <LabelTypography label='Data urodzenia' text={personalData?.birthDate} />
        </div>
        {!isEditAllow && (
          <LabelTypography label='Płeć' text={sexType[personalData?.sex as 'female' | 'male']} />
        )}
        <div>
          <LabelTypography label='Adres e-mail' text={data?.email} />
        </div>
        <div>
          <LabelTypography label='Adres e-mail (dodatkowy)' text={data?.emailSecondary} />
        </div>
        {isEditAllow && <LabelTypography label='Płeć' text={sexType[personalData?.sex as 'female' | 'male']} />}
        {/* <div className='col-span-2'>
              <LabelTypography
                label='Dodatkowy Adres e-mail'
                text='mieczyslawa.wielaaaa@gmail.com'
              />
          </div>
          <div></div> */}
        <div className={contactCols}>
          <LabelTypography label='Telefon' text={data?.phone} />
        </div>
      </div>
    </Card>
  )
}
