import { useQuery } from '@tanstack/react-query'
import { useAppSelector } from 'app/hooks'
import { Card } from 'components/Atoms/Card'
import { Typography } from 'components/Atoms/Typography'
import { path } from 'constants/path'
import { queryKeys } from 'constants/queryKeys'
import { Link, useNavigate } from 'react-router-dom'
import { LoadingSection } from 'components/Atoms/LoadingSection'
import SettingsBaseList from '../../../components/Settings/SettingsBaseList'
import ErrorCard from 'components/ErrorCard'
import { getEducationsList } from 'fetchers/educationFetchers'

export const MemberEducations = () => {
  const navigate = useNavigate()
  const cancelHandler = () => navigate(path.settings.main)
  const addNewTypeHandler = () => navigate(path.settings.education.create)
  const { userToken } = useAppSelector((state: any) => state.authReducer)

  const { data, isLoading, error } = useQuery({
    queryKey: [userToken, queryKeys.educationList],
    queryFn: () => getEducationsList(1, 100),
  })
  const educationsList = data?.items || []
  if (error) {
    return (
      <ErrorCard
        error={error}
        label={'Błąd pobierania danych'}
        message={'Wystąpił błąd podczas pobierania listy wykształceń'}
        onCancel={cancelHandler}
        buttonLabel={'Ustawienia'}
      />
    )
  }
  return (
    <section>
      <Typography size='xl' weight='semibold'>
        <Link to={path.settings.main}>Ustawienia</Link> / Wykształcenie
      </Typography>
      <Card
        label='Lista stopni wykształcenia'
        actionsButton={[
          {
            label: 'Dodaj nowe wykształcenie',
            handleClick: addNewTypeHandler,
          },
        ]}
      >
        <>
          {isLoading ? (
            <LoadingSection />
          ) : (
            <SettingsBaseList baseList={educationsList} type='education' />
          )}
        </>
      </Card>
    </section>
  )
}

export default MemberEducations
