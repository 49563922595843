import { TextField } from '../TextField'

type Props = {
  label: string
  name: string
  queryHandler: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export const FilterSearchInput = ({ label, name, queryHandler }: Props) => {
  return (
    <div className='flex gap-2'>
      <div className='flex-1'>
        <TextField label={label} type='text' name={name} onChange={queryHandler} />
      </div>
    </div>
  )
}

export default FilterSearchInput
