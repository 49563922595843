import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { sectionErrors } from 'error-data/section'
import { postArchiveSection } from 'fetchers/sectionFetchers'
import useArchive from 'hooks/useArchive'
import { useNavigate } from 'react-router-dom'
import { successToast } from 'tools/ToastHelpers'
import { mutationErrorHandler } from 'tools/errorHandler'

type Props = {
  refetch?: () => Promise<any>
  navigatePath?: string
}

export const useArchiveSection = ({ refetch, navigatePath }: Props) => {
  const navigate = useNavigate()

  const mutation = useMutation({
    mutationFn: (id: string) => postArchiveSection(id),
    onSuccess: () => {
      if (refetch) refetch()
      if (navigatePath) navigate(navigatePath)
      successToast('Ognisko zostało zarchiwizowane')
    },
    onError: (error: AxiosError) => {
      console.error(error)
      mutationErrorHandler(error, sectionErrors.archive, 'Nie udało się zarchiwizować ogniska')
    },
  })

  const archiveHandler = async (id: string) => {
    try {
      mutation.mutate(id)
    } catch (error) {
      console.error(error)
      mutationErrorHandler(null, null, 'Nie udało się zarchiwizować ogniska')
    }
  }

  const { showModalHandler, ArchiveModal } = useArchive({
    headerLabel: 'Archiwizuj ognisko',
    executeArchive: archiveHandler,
  })

  return {
    showModalHandler,
    ArchiveModal,
  }
}
