import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useAppSelector } from 'app/hooks'
import ErrorRoleModal from 'components/Atoms/ErrorRoleModal'
import { UpdateForm } from 'components/Region/UpdateForm'
import { path } from 'constants/path'
import { queryKeys } from 'constants/queryKeys'
import { getRegion, updateRegion } from 'fetchers/regionFetchers'
import useAuth from 'hooks/useAuth'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { errorQuery } from 'tools/queryHelpers'
import { ICreateRegion } from 'types/region'
import { LoadingSection } from 'components/Atoms/LoadingSection'
import { regionErrors } from 'error-data/region'
import { mutationErrorHandler } from 'tools/errorHandler'
import { successToast } from 'tools/ToastHelpers'

export const RegionUpdate = () => {
  const [isSending, setIsSending] = useState(false)
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { isAllAdmin, userLoaded, userToken, id: regionId } = useAuth()
  const adminAuth = isAllAdmin()

  const {
    data: region,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: [userToken, queryKeys.region, regionId],
    queryFn: () => getRegion(regionId),
    retry: errorQuery,
  })

  const mutation = useMutation({
    mutationFn: (data: ICreateRegion) => updateRegion(regionId, data),
    onSuccess: () => {
      successToast('Zaktualizowano okręg.')
      refetch()
      queryClient.invalidateQueries({ queryKey: [queryKeys.regionList] })
      navigate(path.region.list)
    },
    onError: (error: any) => {
      setIsSending(false)
      console.error(error)
      mutationErrorHandler(error, regionErrors.create, 'Nie udało się zaktualizować okręgu.')
    },
  })

  const onSubmit = async (updateData: ICreateRegion) => {
    try {
      setIsSending(true)
      mutation.mutate(updateData)
    } catch (error) {
      setIsSending(false)
      console.error(error)
      mutationErrorHandler(null, null, 'Nie udało się zaktualizować okręgu.')
    }
  }

  const initData = {
    name: region?.name ? region?.name : '',
    email: region?.contact?.email ? region?.contact?.email : '',
    phonePrimary: region?.contact?.phone ? region?.contact?.phone : '',
    phoneSecondary: region?.contact?.phoneSecondary ? region?.contact?.phoneSecondary : '',
    postalCode: region?.address?.postalCode ? region?.address?.postalCode : '',
    city: region?.address?.city ? region?.address?.city : '',
    streetName: region?.address?.streetName ? region?.address?.streetName : '',
    houseNr: region?.address?.houseNr ? region?.address?.houseNr : '',
    apartmentNr: region?.address?.apartmentNr ? region?.address?.apartmentNr : '',
    locationDescription: region?.locationDescription ? region?.locationDescription : '',
    // document:  region?.document? region?.document: ''
  }

  if (!userLoaded || isLoading) return <LoadingSection />
  if (!adminAuth) return <ErrorRoleModal />
  return (
    <UpdateForm label='Edytuj okręg' isSending={isSending} data={initData} onSubmit={onSubmit} />
  )
}
