import { useMutation, useQueryClient } from '@tanstack/react-query'
import SettingsFormIBasic from 'components/Settings/Forms/SettingsFormBasic'
import { successToast, universalError } from 'tools/ToastHelpers'
import { path } from 'constants/path'
import { queryKeys } from 'constants/queryKeys'
import { createEducation } from 'fetchers/educationFetchers'
import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { IBasicSettingForm, INameForm } from 'types/form'
import { educationErrors } from 'error-data/education'
import { mutationErrorHandler } from 'tools/errorHandler'

export const MemberEducationsCreate = () => {
  const [isSending, setIsSending] = useState(false)
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const listPath = path.settings.education.list

  const mutation = useMutation({
    mutationFn: (data: INameForm) => createEducation(data),
    onSuccess: () => {
      successToast('Dodano nowe wykształcenie')
      queryClient.invalidateQueries({ queryKey: [queryKeys.educationList] })
      navigate(listPath)
    },
    onError: (error: any) => {
      setIsSending(false)
      console.error(error)
      mutationErrorHandler(
        error,
        educationErrors.create,
        'Nie udało się dodać nowego wykształcenia.',
      )
    },
  })

  const onSubmit = async (data: IBasicSettingForm) => {
    try {
      setIsSending(true)
      mutation.mutate({ name: data.name })
    } catch (error) {
      setIsSending(false)
      universalError(error)
      mutationErrorHandler(null, null, 'Nie udało się dodać nowego wykształcenia.')
    }
  }

  const cancelHandler = () => navigate(listPath)
  const breadcrumbLink = (
    <>
      <Link to={listPath}>Wykształcenie</Link> / Dodaj
    </>
  )
  return (
    <SettingsFormIBasic
      cardLabel={'Dodaj nowy stopień wykształcenia'}
      initData={{ name: '' }}
      inputLabel={'Nazwa stopnia wykształcenia'}
      inputPlaceholder={'Wprowadź nowy stopień wykształcenia'}
      onSubmit={onSubmit}
      onCancelHandler={cancelHandler}
      breadcrumbLink={breadcrumbLink}
      isSending={isSending}
    />
  )
}

export default MemberEducationsCreate
