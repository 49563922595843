import { Button } from 'components/Atoms/Button'
import { TextField } from 'components/Atoms/TextField'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { IOption } from 'types/form'
import { ILegal } from 'types/legal'
import DatePicker from 'react-datepicker'
import { useState } from 'react'
import { legalForm } from 'schemas/legalSchema'
import { useNavigate } from 'react-router-dom'
import { path } from 'constants/path'
import LegalSelector from './LegalSelector'
import { errorRequires } from 'tools/ToastHelpers'

interface IProps {
  isAdmin?: boolean
  isEdit?: boolean
  isSending?: boolean
  onSubmit: (data: any) => void
  setTypeHandler?: (value: string) => void
  searchMore?: () => void
  data: ILegal
  divisionList?: IOption[]
  divisionLoading?: boolean
}

export const LegalForm: React.FC<IProps> = ({
  isAdmin,
  isEdit,
  searchMore,
  setTypeHandler,
  onSubmit,
  data,
  isSending,
  divisionList = [],
  divisionLoading,
}) => {
  const [docDate, setDocDate] = useState(data.dateEnactment)
  const [startDate, setStartDate] = useState(data.dateEffective)
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    getValues,
  } = useForm<ILegal>({
    resolver: yupResolver(legalForm),
    defaultValues: data,
  })
  const navigator = useNavigate()

  const onValidationFailure = () => errorRequires(errors)

  const handleDate = (type: 'dateEnactment' | 'dateEffective', date: Date) => {
    setValue(type, date)
    if (type === 'dateEnactment') {
      setDocDate(date)
    } else {
      setStartDate(date)
    }
  }

  const setUnit = (value: string) => {
    setValue('unit', value)
  }

  const allowSelector = isAdmin && searchMore && setTypeHandler
  return (
    <form onSubmit={handleSubmit(onSubmit, onValidationFailure)} className='mt-4'>
      {allowSelector && (
        <LegalSelector
          isEdit={isEdit}
          searchMore={searchMore}
          setUnit={setUnit}
          isLoading={divisionLoading}
          register={register}
          options={divisionList}
          control={control}
          setType={setTypeHandler}
        />
      )}
      <div className='mb-6 grid grid-cols-5 gap-5'>
        <TextField {...register('identifier')} label='Numer dokumentu' />
        <TextField {...register('name')} label='Nazwa dokumentu' />
      </div>
      <div className='grid grid-cols-5 gap-5'>
        <div>
          <p>Data publikacji</p>
          <DatePicker
            dateFormat='yyyy-MM-dd'
            {...register('dateEnactment')}
            selected={docDate}
            onChange={(date: Date) => handleDate('dateEnactment', date)}
            className='relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm'
          />
        </div>
        <div>
          <p>Data wejścia w życie</p>
          <DatePicker
            dateFormat='yyyy-MM-dd'
            {...register('dateEffective')}
            selected={startDate}
            onChange={(date: Date) => handleDate('dateEffective', date)}
            className='relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm'
          />
        </div>
      </div>
      <div className='flex gap-5'>
        <Button
          onClick={() => navigator(path.resolution.list)}
          type='button'
          variant='secondary'
          label='Anuluj'
          className='mt-4'
        />
        <Button type='submit' label='Wyślij' disabled={isSending} className='mt-4' />
      </div>
    </form>
  )
}
