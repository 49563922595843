import { Modal } from 'components/Atoms/Modal'
import { Button } from 'components/Atoms/Button'
import { Card } from 'components/Atoms/Card'
import { Typography } from '../Typography'
import { TextareaField } from '../TextareaField'
import { Calendar } from '../Calendar'
import { useState } from 'react'
import { XCircleIcon } from '@heroicons/react/24/outline'

interface IProps {
  isOpen: boolean
  handleClose: () => void
}

export const ConfirmDeleteMemberModal: React.FC<IProps> = ({ isOpen, handleClose }) => {
  const [startDate, setStartDate] = useState(new Date())

  return (
    <Modal isOpen={isOpen} handleClose={handleClose} className='w-1/2'>
      <Card
        label='Usuwanie członka'
        className='px-10 py-5'
        actionsButton={[{ icon: <XCircleIcon className='w-5' />, handleClick: handleClose }]}
      >
        <Typography>Czy napewno chcesz usunąć dane członka ?</Typography>

        <div className='my-5'>
          <TextareaField placeholder='Opisz pokrótce sytuację.' />
        </div>

        <div className='mb-10 flex justify-between'>
          <Typography>Wybierz datę wykreślenia</Typography>

          <Calendar date={startDate} handleDate={setStartDate} />
        </div>

        <div className='flex justify-end'>
          <div className='mr-5'>
            <Button label='Anuluj' variant='secondary' onClick={handleClose} />
          </div>
          <Button label='Zatwierdź' onClick={handleClose} />
        </div>
      </Card>
    </Modal>
  )
}
