type EducationType = { value: number; label: string }

export const education: EducationType[] = [
  {
    value: 1,
    label: 'Brak',
  },
  {
    value: 2,
    label: 'Podstawowe',
  },
  {
    value: 3,
    label: 'Średnie',
  },
]
