import { useNavigate } from 'react-router-dom'
import { PencilIcon, TrashIcon } from '@heroicons/react/24/outline'
import { wipMessage } from 'tools/ToastHelpers'
import { HoverIcon } from 'components/Atoms/HoverIcon'

type Props = {
  type: string
  id: string
}

export const SettingsActionCell = ({ type, id }: Props) => {
  const navigate = useNavigate()
  const editPathHandler = () => navigate(`/settings/${type}/${id}/update`)
  return (
    <div className='flex cursor-pointer'>
      <HoverIcon iconName='PencilIcon' title='Edytuj' onClick={editPathHandler} />
      <HoverIcon iconName='TrashIcon' title='Usuń' onClick={() => wipMessage()} />
    </div>
  )
}

export default SettingsActionCell
