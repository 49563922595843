import { useQuery } from '@tanstack/react-query'
import { useAppSelector } from 'app/hooks'
import { Button } from 'components/Atoms/Button'
import { Card } from 'components/Atoms/Card'
import ErrorRoleModal from 'components/Atoms/ErrorRoleModal'
import { Select } from 'components/Atoms/Select'
import { Table } from 'components/Atoms/Table'
import { Typography } from 'components/Atoms/Typography'
import { queryKeys } from 'constants/queryKeys'
import { getCensusList } from 'fetchers/censusFetchers'
import { useLastPage } from 'hooks/useLastPage'
import useSearchModule from 'hooks/useSearchModule'
import { path } from 'constants/path'
import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { errorForbiddenHandler } from 'tools/queryHelpers'
import { IOption } from 'types/form'
import useSearch from 'hooks/useSearch'
import { Pagination } from 'components/Atoms/Pagination'
import TableSkeleton from 'components/Atoms/Skeleton/TableSkeleton'
import { HoverIcon } from 'components/Atoms/HoverIcon'

const searchParams = {
  simpleParams: [],
  arrayParams: [],
}

const CensusList = () => {
  const { filters, currentPage } = useSearchModule({ archive: false })
  const { userToken } = useAppSelector((state: any) => state.authReducer)
  const { page, setLimit, changePage } = useSearch(searchParams)
  const [isModalOpen, setModalOpen] = useState(false)
  const [censusID, setCensusID] = useState<string | null>(null)

  const navigate = useNavigate()

  const openModal = () => setModalOpen(true)
  const closeModal = () => setModalOpen(false)

  const confirmArchiveCensusHandler = () => {
    if (censusID) {
      closeModal()
    }
  }

  const {
    data: censusList,
    isLoading: censusListLoading,
    error,
    refetch,
  } = useQuery({
    queryKey: [userToken, queryKeys.censusList, currentPage, filters],
    queryFn: () => getCensusList(currentPage, 10, filters),
  })

  const lastPage = useLastPage(censusList?.pagination, censusListLoading)

  const onLimitHandler = (e: IOption) => setLimit(e.value)

  const censusListData = censusList?.items || []

  const getStatusText = (status: string) => {
    switch (status) {
      case 'awaiting':
        return 'Oczekiwanie'
      case 'completed':
        return 'Gotowy do pobrania'
      case 'error':
        return 'Konflikt'
      default:
        return ''
    }
  }

  const columns = useMemo(() => {
    return [
      {
        Header: 'Nazwa',
        accessor: 'name',
      },
      {
        Header: 'Rozpoczęcie',
        accessor: 'dateFrom',
      },
      {
        Header: 'Zakończenie',
        accessor: 'dateTo',
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ row }: any) => {
          return getStatusText(row?.original?.status)
        },
      },
      {
        Header: 'Akcje',
        accessor: 'section',
        Cell: ({ row }: any) => {
          return (
            <div className='flex gap-2'>
              <HoverIcon
                iconName='EyeIcon'
                title='Zobacz szczegóły spisu'
                onClick={() => navigate(`/census/${row.original?.id}/show`)}
              />
            </div>
          )
        },
      },
    ]
  }, [])

  if (errorForbiddenHandler(error)) return <ErrorRoleModal />

  return (
    <Card
      label='Lista spisów'
      actionsButton={[
        {
          label: 'Utwórz spis',
          handleClick: () => navigate(path.census.create),
        },
      ]}
    >
      <div className='flex items-center justify-between gap-4'>
        {lastPage && (
          <>
            <div className='flex flex-col justify-end gap-4 md:flex-row'>
              <div className='flex items-center gap-4'>
                <Typography weight='semibold' className='text-sm'>
                  Liczba wierszy na stronę
                </Typography>
                <Select
                  handleSelect={onLimitHandler}
                  options={[
                    { label: '10', value: 10 },
                    { label: '20 ', value: 20 },
                  ]}
                />
              </div>
            </div>

            <span className='flex flex-col items-center gap-2 md:flex-row md:gap-4'>
              {lastPage > 1 && (
                <Pagination lastPage={lastPage} currentPage={page} handlePageChange={changePage} />
              )}
            </span>
          </>
        )}
      </div>
      {!censusListLoading && (
        <>
          <Table maxColumnWidth='300px' columns={columns} data={censusListData || []} />
        </>
      )}
      {censusListLoading && <TableSkeleton />}

      {isModalOpen && (
        <div className='fixed inset-0 z-50 flex items-center justify-center bg-black/80 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0'>
          <div className='rounded bg-white p-6 shadow-lg'>
            <h2 className='mb-4 text-xl'>Potwierdzenie archiwizacji</h2>
            <p>Czy na pewno chcesz zarchiwizować ten spis?</p>
            <div className='mt-4 flex justify-end space-x-4'>
              <button onClick={closeModal} className='rounded bg-gray-300 px-4 py-2'>
                Anuluj
              </button>
              <button
                onClick={confirmArchiveCensusHandler}
                className='rounded bg-red-500 px-4 py-2 text-white'
              >
                Archiwizuj
              </button>
            </div>
          </div>
        </div>
      )}
    </Card>
  )
}

export default CensusList
