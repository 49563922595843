import { Modal } from 'components/Atoms/Modal'
import { Button } from 'components/Atoms/Button'
import { Card } from 'components/Atoms/Card'
import { Typography } from '../Typography'
import {} from '../TextareaField'
import { Calendar } from '../Calendar'
import { useState } from 'react'
import { XCircleIcon } from '@heroicons/react/24/outline'

interface IProps {
  isOpen: boolean
  isSending?: boolean
  handleClose: () => void
  handleDelete: () => void
}

export const ConfirmDeleteNotifyModal: React.FC<IProps> = ({
  handleDelete,
  isOpen,
  isSending,
  handleClose,
}) => {
  return (
    <Modal isOpen={isOpen} handleClose={handleClose}>
      <div>
        <Card
          label='Usuń zgłoszenie'
          className='px-10 py-5'
          // actionsButton={[{ icon: <XCircleIcon className='w-5' />, handleClick: handleClose }]}
        >
          <Typography>Czy napewno chcesz usunąć zgłoszenie o zmianie danych członka?</Typography>

          <div className='mt-4 flex justify-center gap-5'>
            <Button label='Anuluj' variant='secondary' onClick={handleClose} />
            <Button disabled={isSending} label='Zatwierdź' onClick={handleDelete} />
          </div>
        </Card>
      </div>
    </Modal>
  )
}
