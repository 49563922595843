import { errorMessage } from 'constants/errorMessage'
import { emailRegex, phoneRegExp } from 'constants/regexs'
import * as yup from 'yup'

export const createSectionSchemaClub = yup.object({
  name: yup.string().required(errorMessage.requiredField).min(3, errorMessage.min3Chars),
  branch: yup.string().required(errorMessage.selectBranch),
  president: yup.string(),
  startDate: yup.string(),
  endDate: yup.string(),
  resolutionNumber: yup.string(),
  phonePrimary: yup.string().optional().matches(phoneRegExp, errorMessage.invalidPhone),
  phoneSecondary: yup.string().optional().matches(phoneRegExp, errorMessage.invalidPhone),
  email: yup.string().email(errorMessage.invalidEmail).nullable(), // .matches(emailRegex, errorMessage.invalidEmail),
})

export const createSectionSchemaGroup = yup.object({
  branch: yup.string().required(errorMessage.selectBranch),
  subtype: yup.string().required(errorMessage.selectSectionType),
  president: yup.string(),
  startDate: yup.string(),
  endDate: yup.string(),
  resolutionNumber: yup.string(),
  phonePrimary: yup.string().optional().matches(phoneRegExp, errorMessage.invalidPhone),
  phoneSecondary: yup.string().optional().matches(phoneRegExp, errorMessage.invalidPhone),
  email: yup.string().email(errorMessage.invalidEmail).nullable(), // .matches(emailRegex, errorMessage.invalidEmail),
})
