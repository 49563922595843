import { useQuery } from '@tanstack/react-query'
import { useAppSelector } from 'app/hooks'
import { Card } from 'components/Atoms/Card'
import { Typography } from 'components/Atoms/Typography'
import ErrorCard from 'components/ErrorCard'
import SettingsBaseList from 'components/Settings/SettingsBaseList'
import { path } from 'constants/path'
import { queryKeys } from 'constants/queryKeys'
import { getSubjectsList } from 'fetchers/subjectFetchers'
import { Link, useNavigate } from 'react-router-dom'
import { LoadingSection } from 'components/Atoms/LoadingSection'

export const MemberSubjects = () => {
  const navigate = useNavigate()
  const cancelHandler = () => navigate(path.settings.main)
  const addNewTypeHandler = () => navigate(path.settings.subject.create)

  const { userToken } = useAppSelector((state: any) => state.authReducer)

  const { data, isLoading, error } = useQuery({
    queryKey: [userToken, queryKeys.subjectsList],
    queryFn: () => getSubjectsList(1, 100),
  })

  const subjectTypes = data?.items || []

  if (error) {
    return (
      <ErrorCard
        error={error}
        label={'Błąd pobierania danych'}
        message={'Wystąpił błąd podczas pobierania listy przedmiotów'}
        onCancel={cancelHandler}
        buttonLabel={'Ustawienia'}
      />
    )
  }

  return (
    <section>
      <Typography size='xl' weight='semibold'>
        <Link to={path.settings.main}>Ustawienia</Link> / Przedmioty
      </Typography>
      <Card
        label='Lista przedmiotów'
        actionsButton={[
          {
            label: 'Dodaj nowy przedmiot',
            handleClick: addNewTypeHandler,
          },
        ]}
      >
        <>
          {isLoading ? (
            <LoadingSection />
          ) : (
            <SettingsBaseList baseList={subjectTypes} type='subject' />
          )}
        </>
      </Card>
    </section>
  )
}

export default MemberSubjects
