import { useState } from 'react'
import { Button } from 'components/Atoms/Button'
import { Card } from 'components/Atoms/Card'
import { TextareaField } from 'components/Atoms/TextareaField'
import { Typography } from 'components/Atoms/Typography'
import {
  IDeclaration,
  IRejectDeclarationRequest,
} from 'features/Member/declaration/declarationSlice'

interface IProps {
  rejectedDeclarationList: IDeclaration[]
  handleSubmit: (data: IRejectDeclarationRequest) => void
  handleCancel: () => void
}

export const DeclarationsReject = ({
  rejectedDeclarationList,
  handleSubmit,
  handleCancel,
}: IProps) => {
  const [reason, setReason] = useState('')

  const requestData = {
    items: rejectedDeclarationList.map(({ id }) => ({ id })),
    reason,
  }

  const handleChangeReason = (event: React.FocusEvent<HTMLTextAreaElement>) =>
    setReason(event.currentTarget.value)

  return (
    <Card label='Odrzucenie deklaracji'>
      <Typography>
        Czy napewno chcesz odrzucić deklaracje:{' '}
        <strong>
          {rejectedDeclarationList.map(({ firstName, lastName }) => `${firstName} ${lastName}`)}
        </strong>
      </Typography>
      <TextareaField placeholder='Opisz pokrótce sytuację.' onChange={handleChangeReason} />
      <div className='mt-10 flex justify-end'>
        <div className='mr-2'>
          <Button label='Anuluj' variant='secondary' onClick={handleCancel} />
        </div>
        <Button label='Zatwierdź' onClick={() => handleSubmit(requestData)} />
      </div>
    </Card>
  )
}
