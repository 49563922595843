import { secureBaseApiURL } from 'api'
import { endpoint } from 'constants/endpoints'
import {
  IAcceptDeclarationRequest,
  IRejectDeclarationRequest,
} from 'features/Member/declaration/declarationSlice'
import { IDeclarationForm } from 'types/declaration'
import { getInstitution } from './institutionFetchers'

export const getDeclarationsList = async (page: number, count: number, filters?: string) => {
  const res = await secureBaseApiURL.get(
    `${endpoint.declaration.list}?page=${page}&perPage=${count}&${filters || ''}`,
    {},
  )
  return res?.data
}

export const getDeclarationsListWithPlace = async (
  page: number,
  count: number,
  filters?: string,
) => {
  const res = await secureBaseApiURL.get(
    `${endpoint.declaration.list}?page=${page}&perPage=${count}&${filters || ''}`,
    {},
  )
  const items = res?.data.items
  const places: string[] = []
  if (items) {
    for (let i = 0; i < items.length; i++) {
      const place = await getInstitution(items[i].basePlace)
      places.push(place.composedName)
    }
  }
  return {
    ...res?.data,
    items: items.map((item: any, index: number) => ({
      ...item,
      basePlace: places[index],
    })),
  }
}

export const getDeclaration = async (id: string) => {
  const res = await secureBaseApiURL.get(endpoint.declaration.byId(id))
  return res?.data
}

export const createDeclaration = async (data: IDeclarationForm) => {
  const res = await secureBaseApiURL.post(endpoint.declaration.create, data)
  return res?.data
}

export const acceptDeclaration = async (data: IAcceptDeclarationRequest) => {
  const res = await secureBaseApiURL.post(endpoint.declaration.accept, data)
  return res?.data
}

export const rejectDeclaration = async (data: IRejectDeclarationRequest) => {
  const res = await secureBaseApiURL.post(endpoint.declaration.reject, data)
  return res?.data
}

export const updateDeclaration = async (id: string, data: IDeclarationForm) => {
  const res = await secureBaseApiURL.put(endpoint.declaration.update(id), data)
  return res?.data
}

export const addDeclarationDoc = async (file: any) => {
  const formData = new FormData()
  formData.append('file', file)
  const res = await secureBaseApiURL.post(endpoint.declaration.file.upload, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
  return res?.data
}

export const getFile = async (id: string) => {
  const res = await secureBaseApiURL.get(endpoint.declaration.file.byId(id), {
    responseType: 'blob',
  })
  return res?.data
}
