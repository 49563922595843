export const logout = () => {
  const token = localStorage.getItem('token')
  const user = localStorage.getItem('userInfo')

  if (token && user) {
    localStorage.removeItem('token')
    localStorage.removeItem('userInfo')

    window.location.href = '/login'
  }
  return true
}
