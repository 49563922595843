import { configureStore } from '@reduxjs/toolkit'
import counterReducer from 'features/counter/counterSlice'
import declarationReducer from 'features/Member/declaration/declarationSlice'
import authReducer from 'features/auth/authSlice'
import memberReducer from 'features/Member/dictionary/dictionarySlice'
import structureRegionsReducer from 'features/Structure/regions/slice'
import structureBranchesReducer from 'features/Structure/branches/slice'
import structureSectionsReducer from 'features/Structure/sections/slice'
import structureUnitsReducer from 'features/Structure/unit/slice'
import rolesReducer from 'features/Role/roleSlice'
import secureConnectionReducer from 'features/secureConnection/secureConnectionSlice'

const store = configureStore({
  reducer: {
    counterReducer,
    declarationReducer,
    authReducer,
    memberReducer,
    structureRegionsReducer,
    structureBranchesReducer,
    structureSectionsReducer,
    structureUnitsReducer,
    rolesReducer,
    secureConnectionReducer,
  },
  devTools: true,
})

export default store
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
