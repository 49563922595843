import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { Button } from 'components/Atoms/Button'
import { Calendar } from 'components/Atoms/Calendar'
import { Card } from 'components/Atoms/Card'
import { LabelTypography } from 'components/Atoms/LabelTypography'
import { Modal } from 'components/Atoms/Modal'
import { Select } from 'components/Atoms/Select'
import { Table } from 'components/Atoms/Table'
import { TableName } from 'components/Atoms/TableName'
import { queryKeys } from 'constants/queryKeys'
import { memberErrors } from 'error-data/member'
import {
  addMemberDecoration,
  getDecoratiosList,
  getMemberDecorations,
  getUserAvialableDecorations,
} from 'fetchers/membersFetchers'
import useAuth from 'hooks/useAuth'
import useSearch from 'hooks/useSearch'
import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { MultiSelect } from 'react-multi-select-component'
import { toast } from 'react-toastify'
import { mutationErrorHandler } from 'tools/errorHandler'
import { errorQuery } from 'tools/queryHelpers'
import { errorToast, successToast } from 'tools/ToastHelpers'
import { IAxiosErrors } from 'types/axios-errors'

type Props = {
  memberDecorations: any
  isLoading?: boolean
  refetch: () => void
}

const MemberDecoration = ({ memberDecorations, isLoading, refetch }: Props) => {
  const [isOpen, setIsOpen] = useState(false)
  const closeModal = () => setIsOpen(false)
  const [isSubmiting, setIsSubmiting] = useState(false)
  const [trainingDateStart, setTrainingDateStart] = useState('')
  const [chosenDecoration, setChosenDecoration] = useState<string>('')
  const { userToken, isAllOD, isAllOK, isAllAdmin, id } = useAuth()
  const cardId = id
  const queryClient = useQueryClient()
  const { control } = useForm()

  const {
    data: getDecorationsList,
    isLoading: getDecorationsListLoading,
    refetch: getDecorationsListRefetch,
  } = useQuery({
    queryKey: [userToken, queryKeys.getDecorationsList],
    queryFn: () => getUserAvialableDecorations(),
    retry: errorQuery,
  })

  const {
    data: getMemberDecorationsList,
    isLoading: getMemberDecorationsListLoading,
    refetch: getMemberDecorationsListRefetch,
  } = useQuery({
    queryKey: [userToken, queryKeys.memberDecorations, cardId],
    queryFn: () => getMemberDecorations(cardId),
    retry: errorQuery,
  })

  const userDecorationListItems = getMemberDecorationsList?.items
    ? getMemberDecorationsList?.items
    : []

  const optionsSelect =
    getDecorationsList?.items.map((item: any) => ({
      label: item.name,
      value: item.id,
    })) || []

  const mutation = useMutation({
    mutationFn: (data: { decoration: string; date: string }) => addMemberDecoration(cardId, data),
    onSuccess: () => {
      successToast('Pomyślnie dodano odznaczenie.')
      queryClient.invalidateQueries({ queryKey: [queryKeys.addMemberDecoration] })
      getMemberDecorationsListRefetch()
      closeModal()
      setIsSubmiting(false)
    },
    onError: (error: AxiosError<IAxiosErrors>) => {
      error.response?.data.errors.forEach((item: any) => {
        toast.warning(`${item.property}: ${item.error}`)
      })
      mutationErrorHandler(error, memberErrors.addDecoration, 'Nie udało się dodać odznaczenia.')
      setIsSubmiting(false)
    },
  })

  const submitDecoration = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!chosenDecoration.trim()) {
      errorToast('Wybierz odznaczenie')
      return
    }
    if (!trainingDateStart.trim()) {
      errorToast('Wybierz datę')
      return
    }
    const data = {
      decoration: chosenDecoration,
      date: trainingDateStart,
    }
    setIsSubmiting(true)
    mutation.mutate(data)
    setTrainingDateStart('')
  }

  if (isLoading) {
    return <div>Ładowanie...</div>
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'Typ odznaczenia',
        accessor: 'decoration.name',
      },
      {
        Header: 'Osoba nadająca',
        accessor: 'grantedBy.email',
      },
      {
        Header: 'Jednostka nadająca',
        accessor: 'grantingUnit.name',
      },
      {
        Header: 'Data nadania odznaczenia',
        accessor: 'date',
      },
    ],
    [],
  )

  const isAdmin = isAllAdmin()
  const isRegion = isAllOK()
  const isStructure = isAllOD()

  return (
    <Card
      label='Lista przyznanych odznaczeń członka'
      actionsButton={[
        {
          disabled: !isRegion && !isStructure,
          label: 'Dodaj odznaczenie',
          handleClick: () => {
            setIsOpen(true)
          },
        },
      ]}
    >
      <div className=''>
        <div className='mr-6'>
          {memberDecorations?.length > 0 ? (
            <Table data={userDecorationListItems || []} columns={columns} />
          ) : (
            <div className='mb-1 block text-sm font-medium text-gray-700'>Brak odznaczeń</div>
          )}
        </div>
      </div>
      <Modal isOpen={isOpen} handleClose={closeModal}>
        <Card label='Dodaj odznaczenie'>
          <div className='p-6'>
            <form onSubmit={submitDecoration}>
              <div className='flex flex-col gap-4'>
                <Select
                  handleSelect={(e: any) => {
                    setChosenDecoration(e.value)
                  }}
                  placeholder='Wybierz odznaczenie'
                  options={[{ value: '', label: '---' }, ...optionsSelect]}
                  label='Wybierz odznaczenie'
                />
                <div>
                  <Controller
                    name='dateStart'
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Calendar
                        date={value}
                        handleDate={(val) => {
                          onChange(val)
                          setTrainingDateStart(new Date(val).toISOString().split('T')[0])
                        }}
                        label='Data nadania'
                      />
                    )}
                  />
                </div>
              </div>
              <hr className='my-6' />
              <div className='mt-6 flex justify-center'>
                <Button
                  variant='secondary'
                  label='Anuluj'
                  className='mr-4'
                  onClick={(e) => {
                    e.preventDefault()
                    setIsSubmiting(false)
                    closeModal()
                  }}
                />
                <Button
                  variant={isSubmiting ? 'third' : 'primary'}
                  label={
                    isSubmiting ? (
                      <>
                        <span className='loader mr-1'></span>
                        Dodawanie odznaczenia
                      </>
                    ) : (
                      'Dodaj odznaczenie'
                    )
                  }
                />
              </div>
            </form>
          </div>
        </Card>
      </Modal>
    </Card>
  )
}

export default MemberDecoration
