import { secureBaseApiURL } from 'api'
import { useAppDispatch } from 'app/hooks'
import { AppDispatch } from 'app/store'
import { isSecure } from 'features/secureConnection/secureConnectionSlice'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

type Props = {
  children: React.ReactNode
}

const CheckSecureConnection = ({ children }: Props): JSX.Element => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    async function checkSecurity() {
      try {
        const response = await secureBaseApiURL.get('doc.json', {
          timeout: 5000,
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'X-Auth-Token': `Bearer ${localStorage.getItem('token')}`,
          },
        })

        // console.info('Response', response.status, response.status == 200)

        dispatch(isSecure({ secure: response.status == 200 ? true : false }))
      } catch (error) {
        dispatch(isSecure(false))
      }
    }

    checkSecurity()

    const int = setInterval(() => {
      checkSecurity()
    }, 1000 * 60)

    return () => {
      clearInterval(int)
    }
  }, [])

  return <>{children}</>
}

export default CheckSecureConnection
