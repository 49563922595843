import { IMutationErrorType } from 'types/httpStatus.enum'

export const tagErrors: IMutationErrorType = {
  create: {},
  createMember: {
    u409: 'Tag został już przypisany.',
  },
  delete: {
    u422: 'Nie znaleziono tagu.',
  },
  update: {
    u404: 'Tag nie istnieje.',
  },
}
