import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { publicBaseApiURL } from 'api'
import { RootState } from 'app/store'
import { endpoint } from 'constants/endpoints'

interface IDict {
  id: string
  name: string
}

const initialState: {
  education: { loading: string; items: IDict[] }
  position: { loading: string; items: IDict[] }
  institution: { loading: string; items: IDict[] }
} = {
  education: {
    items: [],
    loading: 'idle',
  },
  position: {
    items: [],
    loading: 'idle',
  },
  institution: {
    items: [],
    loading: 'idle',
  },
}

export const getEducationDict = createAsyncThunk('member/educationDictionary', async () => {
  try {
    const response = await publicBaseApiURL.get(endpoint.membership.educationDictionary, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-Auth-Token': `Bearer ${localStorage.getItem('token')}`,
      },
    })
    return response.data.items
  } catch (err) {
    return err
  }
})

export const getPositionDict = createAsyncThunk('member/positionDictionary', async () => {
  try {
    const response = await publicBaseApiURL.get(endpoint.membership.positionDictionary, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-Auth-Token': `Bearer ${localStorage.getItem('token')}`,
      },
    })
    return response.data.items
  } catch (err) {
    return err
  }
})

export const getInstitutionDict = createAsyncThunk('member/institutionDictionary', async () => {
  try {
    const response = await publicBaseApiURL.get(endpoint.membership.positionDictionary, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-Auth-Token': `Bearer ${localStorage.getItem('token')}`,
      },
    })
    return response.data.items
  } catch (err) {
    return err
  }
})

export const getSubjectDict = createAsyncThunk('member/subjectDictionary', async () => {
  try {
    const response = await publicBaseApiURL.get(endpoint.membership.subjectDictionary, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-Auth-Token': `Bearer ${localStorage.getItem('token')}`,
      },
    })
    return response.data.items
  } catch (err) {
    return err
  }
})

export const memberDictionarySlice = createSlice({
  name: 'member/dictionary',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getEducationDict.pending, (state) => {
        state.education.loading = 'pending'
      })
      .addCase(getEducationDict.fulfilled, (state, action) => {
        state.education.loading = 'fulfilled'

        state.education.items = action.payload
      })
      .addCase(getEducationDict.rejected, (state) => {
        state.education.loading = 'rejected'
        // state.loading = 'failed'
        state.education.items = []
      })
      .addCase(getInstitutionDict.pending, (state) => {
        state.institution.loading = 'pending'
      })
      .addCase(getInstitutionDict.fulfilled, (state, action) => {
        // state.loading = 'succeeded'
        state.institution.loading = 'fulfilled'

        state.institution.items = action.payload
      })
      .addCase(getInstitutionDict.rejected, (state) => {
        // state.loading = 'failed'
        state.institution.loading = 'rejected'
        state.institution.items = []
      })
      .addCase(getPositionDict.pending, (state) => {
        state.position.loading = 'pending'
      })
      .addCase(getPositionDict.fulfilled, (state, action) => {
        // state.loading = 'succeeded'
        state.position.loading = 'fulfilled'

        state.position.items = action.payload
      })
      .addCase(getPositionDict.rejected, (state) => {
        // state.loading = 'failed'
        state.position.loading = 'rejected'
        state.position.items = []
      })
      .addCase(getSubjectDict.pending, (state) => {
        state.position.loading = 'pending'
      })
      .addCase(getSubjectDict.fulfilled, (state, action) => {
        state.position.loading = 'fulfilled'
        state.position.items = action.payload
      })
      .addCase(getSubjectDict.rejected, (state) => {
        state.position.loading = 'rejected'
        state.position.items = []
      })
  },
})

export const getPositionDictionary = (state: RootState) => state.memberReducer.position.items
export const getEducationDictionary = (state: RootState) => state.memberReducer.education.items
export const getInstitutionDictionary = (state: RootState) => state.memberReducer.institution.items
export const getSubjectDictionary = (state: RootState) => state.memberReducer.institution.items

export default memberDictionarySlice.reducer
