import { useState } from 'react'
import { Typography } from 'components/Atoms/Typography'
import { Button } from 'components/Atoms/Button'
import { MemberEmployment } from 'components/Members/MemberEmployment'
import { RecordData } from 'components/Members/RecordData'
import { ConfirmDeleteMemberModal } from 'components/Atoms/ConfirmDeleteMemberModal'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  addMemberEmployment,
  addMemberToSection,
  getMemberContact,
  getMemberDecorations,
  getMemberEmployment,
  getMemberFunctions,
  getMemberSensitive,
  getMemberTags,
  getMembersDetails,
  getMembersList,
  moveMemberToSection,
  removeMemberFromSection,
  updateMemberContact,
  updateMemberEmployment,
  updateMemberFunction,
  updateMemberGeneralInfo,
  updateMemberSensitive,
} from 'fetchers/membersFetchers'
import useAuth from 'hooks/useAuth'
import { queryKeys } from 'constants/queryKeys'
import { errorToast, successToast, wipMessage } from 'tools/ToastHelpers'
import { MemberID } from 'components/Members/MemberID'
import MemberFunctions from 'components/Members/MemberFunctions'
import MemberNameEdit from './MemberNameEdit'
import { IMemberGeneral, IMemberMoveUnit } from 'types/member'
import { LoadingSection } from 'components/Atoms/LoadingSection'
import { MemberContactData } from 'components/Members/MemberContactData'
import { SensitivePersonalData } from 'components/Members/SensitivePersonalData'
import { Spinner } from 'components/Atoms/Spinner'
import { IJobElementDB, JobElement } from 'types/declaration'
import { IUnitPosition } from 'types/structure'
import { errorQuery, getLoadingHandler } from 'tools/queryHelpers'
import LoadingModal from 'components/Atoms/LoadingModal'
import { Link, useNavigate } from 'react-router-dom'
import CardMemberNew from 'components/CardMember/CardMemberNew'
import CardMemberCancel from 'components/CardMember/CardMemberCancel'
import { getAvailableTags } from 'fetchers/tagsFetchers'
import MemberTags from 'components/Members/MemberTags/MemberTags'
import { IListElement } from 'types/form'
import { Axios, AxiosError } from 'axios'
import { mutationErrorHandler } from 'tools/errorHandler'
import { memberErrors } from 'error-data/member'
import { unitTermErrors } from 'error-data/unit'
import { migrationErrors } from 'error-data/member-migration'
import TableLoader from 'components/Atoms/Loader/TableLoader'
import MemberDecoration from 'components/Members/MemberDecoration/MemberDecoration'

export const MemberDetails = () => {
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false)
  // GENERAL DATA
  const [generalIsReveal, setGeneralIsReveal] = useState(false)
  const [generalIsEdit, setGeneralIsEdit] = useState(false)
  const [generalIsSending, setGeneralIsSending] = useState(false)
  // CONTACT DATA
  const [contactIsReveal, setContactIsReveal] = useState(false)
  const [contactIsEdit, setContactIsEdit] = useState(false)
  const [contactIsSending, setContactIsSending] = useState(false)
  // SENSITIVE DATA
  const [sensitiveIsReveal, setSensitiveIsReveal] = useState(false)
  const [sensitiveIsEdit, setSensitiveIsEdit] = useState(false)
  const [sensitiveIsSending, setSensitiveIsSending] = useState(false)
  // FUNCTIONS DATA
  const [withArchived, setWithArchived] = useState(false)
  const [functionsIsSending, setFunctionsIsSending] = useState(false)
  const [functionsModalData, setFunctionsModalData] = useState<IUnitPosition | undefined>(undefined)
  // EMPLOYMENT DATA
  const [employmentIsSending, setEmploymentIsSending] = useState(false)
  const [employmentModalData, setEmploymentModalData] = useState<JobElement | undefined>(undefined)
  const { userToken, id, isAllAdmin, isAllOD, isAllOK, isPOG } = useAuth()
  // CARD MODALS
  const [newCardModal, setNewCardModal] = useState<boolean>(false)
  const [cancelCardModal, setCancelCardModal] = useState<boolean>(false)

  const queryClient = useQueryClient()
  const isEditAllow = isAllAdmin() || isAllOD()
  const isNotifyAllow = isPOG()

  // DATA GETTERS
  const handleVisibleEditField = () => {
    setGeneralIsEdit((prev) => !prev)
    setGeneralIsReveal(true)
  }
  const toggleArchivedFunctions = () => setWithArchived((s) => !s)
  const showGeneralData = () => setGeneralIsReveal((s) => !s)
  const navigate = useNavigate()
  const handleNotifyChange = () => navigate('notify-change')

  // FETCHERS
  const {
    data: member,
    isLoading: memberLoading,
    error,
    refetch: refetchGeneral,
  } = useQuery({
    queryKey: [userToken, queryKeys.member, id, generalIsReveal],
    queryFn: () => getMembersDetails(id, generalIsReveal),
    // retry: errorQuery,
  })

  const {
    data: contact,
    isLoading: contactLoading,
    error: contactError,
    refetch: refetchContact,
  } = useQuery({
    queryKey: [userToken, queryKeys.memberContact, id, contactIsReveal],
    queryFn: () => getMemberContact(id, contactIsReveal),
  })

  const {
    data: sensitive,
    isLoading: sensitiveLoading,
    error: sensitiveError,
  } = useQuery({
    queryKey: [userToken, queryKeys.memberSensitive, id, sensitiveIsReveal, isEditAllow],
    queryFn: () => {
      if (!isEditAllow) return null
      return getMemberSensitive(id, sensitiveIsReveal)
    },
  })

  const {
    data: functions,
    isLoading: functionsLoading,
    error: functionsError,
    refetch: refreshFunc,
  } = useQuery({
    queryKey: [userToken, queryKeys.memberFunctions, id, withArchived],
    queryFn: () => getMemberFunctions(id, withArchived),
    retry: false, // Disable automatic retries
  })

  const {
    data: memberTags,
    isLoading: memberTagsLoading,
    error: memberTagsError,
    refetch: memberTagsRefetch,
  } = useQuery({
    queryKey: [userToken, queryKeys.memberTags, id],
    queryFn: () => getMemberTags(id),
  })
  const memberTagsList = memberTags?.items
    ? memberTags.items.map((item: IListElement) => ({ label: item.name, value: item.id }))
    : []

  const {
    data: memberDecorations,
    isLoading: memberDecorationsLoading,
    refetch: refetchMemberDecorations,
  } = useQuery({
    queryKey: [userToken, queryKeys.memberDecorations, id],
    queryFn: () => getMemberDecorations(id),
  })

  const memberDecorationsList = memberDecorations?.items
    ? memberDecorations.items.map((item: any) => item)
    : []

  const {
    data: employment,
    isLoading: employmentLoading,
    error: employmentError,
    refetch: refetchEmployment,
  } = useQuery({
    queryKey: [userToken, queryKeys.memberEmployment, id],
    queryFn: () => getMemberEmployment(id),
  })

  // END DATA GETTERS

  // PREPARE  DATA
  const functionsData = functions?.items ? functions.items.map((item: any) => item) : []

  const initJobs = employment?.items
    ? employment?.items.map((j: IJobElementDB) => {
        return {
          id: j.id,
          institution: {
            value: j.institution?.id,
            label: j.institution?.name,
          },
          position: {
            value: j.position?.id,
            label: j.position?.name,
          },
          subject: {
            value: j.subject?.id,
            label: j.subject?.name,
          },
          isMain: j.isMain,
        }
      })
    : []
  // END PREPARE DATA

  // UPDATE GENERAL INFO
  const mutateGeneral = useMutation({
    mutationFn: (data: IMemberGeneral) => updateMemberGeneralInfo(id, data),
    onSuccess: () => {
      successToast('Zaktualizowano dane członka.')
      refetchGeneral()
      handleVisibleEditField()
      setGeneralIsSending(false)
    },
    onError: (error: AxiosError) => {
      setGeneralIsSending(false)
      console.error(error)
      mutationErrorHandler(error, memberErrors.update, 'Nie udało się zaktualizować danych.')
    },
  })
  const updateGeneralInfo = async (data: any) => {
    try {
      setGeneralIsSending(true)
      const formData = {
        sex: member?.sex || '',
        education: member?.education?.id || '',
        ...data,
      }
      mutateGeneral.mutate(formData)
    } catch (error) {
      setGeneralIsSending(false)
      console.error(error)
      mutationErrorHandler(null, null, 'Nie udało się zaktualizować danych.')
    }
  }
  // END UPDATE GENERAL INFO

  // UPDATE PERSONAL INFO
  const mutatePersonal = useMutation({
    mutationFn: (data: any) => updateMemberContact(id, data),
    onSuccess: () => {
      successToast('Zaktualizowano dane kontaktowe członka.')
      refetchContact()
      setContactIsEdit(false)
      setContactIsSending(false)
    },
    onError: (error: AxiosError) => {
      setContactIsSending(false)
      console.error(error)
      mutationErrorHandler(
        error,
        memberErrors.update,
        'Nie udało się zaktualizować danych kontaktowych.',
      )
    },
  })
  const updateContactInfo = async (data: any) => {
    try {
      mutatePersonal.mutate(data)
    } catch (error) {
      setContactIsSending(false)
      console.error(error)
      mutationErrorHandler(null, null, 'Nie udało się zaktualizować danych kontaktowych.')
    }
  }
  // END UPDATE PERSONAL INFO

  // UPDATE SENSITIVE INFO
  const mutateSensitive = useMutation({
    mutationFn: (data: any) => updateMemberSensitive(id, data),
    onSuccess: () => {
      successToast('Zaktualizowano dane wrażliwe członka.')
      setSensitiveIsEdit(false)
      setSensitiveIsSending(false)
    },
    onError: (error: AxiosError) => {
      setSensitiveIsSending(false)
      console.error(error)
      mutationErrorHandler(
        error,
        memberErrors.update,
        'Nie udało się zaktualizować danych wrażliwych.',
      )
    },
  })
  const updateSensitiveInfo = async (data: any) => {
    try {
      setSensitiveIsSending(true)
      mutateSensitive.mutate(data)
    } catch (error) {
      setSensitiveIsSending(false)
      console.error(error)
      mutationErrorHandler(null, null, 'Nie udało się zaktualizować danych wrażliwych.')
    }
  }

  // UPDATE EMPLOYMENT INFO
  const mutateEmployment = useMutation({
    mutationFn: (data: JobElement) => {
      const { id: employmentId, ...formData } = data
      if (!employmentId) return addMemberEmployment(id, formData)
      return updateMemberEmployment(id, String(employmentId), formData)
    },
    onSuccess: () => {
      successToast('Zaktualizowano dane o zatrudnieniu.')
      refetchEmployment()
      setEmploymentIsSending(false)
      setEmploymentModalData(undefined)
    },
    onError: (error: AxiosError) => {
      setEmploymentIsSending(false)
      console.error(error)
      mutationErrorHandler(
        error,
        memberErrors.employment,
        'Nie udało się zaktualizować danych o zatrudnieniu.',
      )
    },
  })
  const updateEmployment = async (data: any) => {
    setEmploymentIsSending(true)
    const formData = {
      ...data,
      subject: typeof data?.subject === 'string' ? data.subject : '',
    }
    try {
      mutateEmployment.mutate(formData)
    } catch (error) {
      setEmploymentIsSending(false)
      console.error(error)
      mutationErrorHandler(null, null, 'Nie udało się zaktualizować danych o zatrudnieniu.')
    }
  }
  // END UPDATE EMPLOYMENT INFO

  // UPDATE FUNCTIONS INFO
  const mutateFunction = useMutation({
    mutationFn: (data: any) => updateMemberFunction(data),
    onSuccess: () => {
      successToast('Zaktualizowano funkcję członka.')
      refreshFunc()
      setFunctionsIsSending(false)
      setFunctionsModalData(undefined)
    },
    onError: (error: AxiosError) => {
      setFunctionsIsSending(false)
      console.error(error)
      mutationErrorHandler(
        error,
        unitTermErrors.update,
        'Nie udało się zaktualizować funkcji członka.',
      )
    },
  })
  const updateFunction = async (data: any) => {
    setFunctionsIsSending(true)
    try {
      const formData = {
        ...data,
        memberId: id,
      }
      mutateFunction.mutate(formData)
    } catch (error) {
      setFunctionsIsSending(false)
      console.error(error)
      mutationErrorHandler(null, null, 'Nie udało się zaktualizować funkcji członka.')
    }
  }
  // END UPDATE FUNCTIONS INFO

  // DELETE SECTION
  const removeSection = useMutation({
    mutationFn: () => removeMemberFromSection(id, member?.sections[0].id),
    onSuccess: () => {
      successToast('Usunięto członka z sekcji/ogniska.')
      refetchGeneral().then(() => setGeneralIsSending(false))
    },
    onError: (error: AxiosError) => {
      setGeneralIsSending(false)
      console.error(error)
      mutationErrorHandler(
        error,
        migrationErrors.removeSection,
        'Nie udało się usunąć członka z sekcji/ogniska.',
      )
    },
  })
  const sectionRemover = async () => {
    try {
      setGeneralIsSending(true)
      removeSection.mutate()
    } catch (error) {
      setGeneralIsSending(false)
      console.error(error)
      mutationErrorHandler(null, null, 'Nie udało się usunąć członka z sekcji/ogniska.')
    }
  }

  // ADD SECTION
  const addSection = useMutation({
    mutationFn: (sectionId: string) => addMemberToSection(id, sectionId),
    onSuccess: () => {
      successToast('Dodano członka do sekcji/ogniska.')
      refetchGeneral().then(() => setGeneralIsSending(false))
    },
    onError: (error: AxiosError) => {
      setGeneralIsSending(false)
      console.error(error)
      mutationErrorHandler(
        error,
        migrationErrors.addSection,
        'Nie udało się dodać członka do sekcji/ogniska.',
      )
    },
  })

  const sectionAdder = async (sectionId: string) => {
    try {
      setGeneralIsSending(true)
      addSection.mutate(sectionId)
    } catch (error) {
      setGeneralIsSending(false)
      console.error(error)
      mutationErrorHandler(null, null, 'Nie udało się dodać członka do sekcji/ogniska.')
    }
  }

  // MOVE SECTION
  const moveSection = useMutation({
    mutationFn: (data: IMemberMoveUnit) => {
      const { comment, unitId } = data
      return moveMemberToSection(id, unitId, comment)
    },
    onSuccess: () => {
      successToast('Przeniesiono członka do sekcji/ogniska.')
      refetchGeneral().then(() => setGeneralIsSending(false))
    },
    onError: (error: AxiosError) => {
      setGeneralIsSending(false)
      console.error(error)
      errorToast('Nie udało się przenieść członka do sekcji/ogniska.')
      mutationErrorHandler(
        error,
        migrationErrors.moveSection,
        'Nie udało się przenieść członka do sekcji/ogniska.',
      )
    },
  })

  const sectionChanger = async (unitId: string, comment?: string) => {
    try {
      setGeneralIsSending(true)
      const formData = {
        unitId,
        comment: comment || '',
      }
      moveSection.mutate(formData)
    } catch (error) {
      setGeneralIsSending(false)
      console.error(error)
      mutationErrorHandler(null, null, 'Nie udało się przenieść członka do sekcji/ogniska.')
    }
  }

  const isRoleEditor = isAllAdmin() || isAllOD() || isAllOK()
  const isLoading = memberLoading
  const memberName = `${member?.firstName}${member?.secondName ? ` ${member?.secondName}` : ''}`
  const loadingSensitive = sensitiveLoading && isEditAllow
  const contactSection = isEditAllow ? 'grid grid-cols-2 gap-5' : ''
  const personalData = {
    birthDate: member?.birthDate,
    sex: member?.sex || '',
  }

  const loadingHandler = getLoadingHandler(error, isLoading)
  const memberAdmittedAt = member?.admittedAt
    ? new Date(member.admittedAt).toLocaleDateString()
    : '-'

  const initGeneral = {
    firstName: member?.firstName || '',
    secondName: member?.secondName || '',
    lastName: member?.lastName || '',
  }
  const educationUpdateData = {
    ...initGeneral,
    sex: member?.sex,
    education: member?.education?.id || '',
    educationLabel: member?.education?.name,
  }

  // MODAL HANDLERS
  const openNewModal = () => setNewCardModal(true)
  const closeNewModal = () => setNewCardModal(false)
  const openCancelModal = () => setCancelCardModal(true)
  const closeCancelModal = () => setCancelCardModal(false)
  // END MODAL HANDLERS

  if (loadingHandler.show) return <LoadingModal {...loadingHandler} />
  if (newCardModal) {
    return <CardMemberNew closeModal={closeNewModal} member={member} />
  }

  if (error) {
    const status = (error as AxiosError).response?.status
    if (status === 423) {
      return (
        <div>
          <p className='mt-6 text-base leading-7 text-gray-600'>
            Ten członek został zarchiwizowany i nie można edytować jego danych.
          </p>
          <div className='mt-4'>
            <Link to='/member' className='text-sm font-semibold leading-7 text-indigo-600'>
              <span aria-hidden='true'>←</span>
              Wróć do listy członków
            </Link>
          </div>
        </div>
      )
    }
    return <div>Błąd: {error.message}</div>
  }

  return (
    <>
      <div>
        <div className='flex justify-between'>
          <Typography size='xl' weight='semibold'>
            Dane członka / {memberName} {member?.lastName}
          </Typography>
          <Typography size='xl' weight='semibold'>
            Data wstąpienia: {memberAdmittedAt}
          </Typography>
        </div>
        <div className='mt-6 flex gap-5'>
          {isEditAllow && !generalIsEdit && (
            <>
              <Button label='Edytuj' onClick={handleVisibleEditField} />
              <Button
                label={generalIsReveal ? 'Ukryj dane' : 'Pokaż dane'}
                onClick={showGeneralData}
              />
              <Button label='Usuń członka' onClick={() => navigate(`/member/${id}/archive`)} />
            </>
          )}
          {isNotifyAllow && <Button label='Zgłoś zmianę' onClick={handleNotifyChange} />}
        </div>
        {generalIsEdit && (
          <MemberNameEdit
            initData={initGeneral}
            isSending={generalIsSending}
            closeHandler={handleVisibleEditField}
            onSubmit={updateGeneralInfo}
          />
        )}

        <MemberID
          card={member?.card}
          openNewModal={openNewModal}
          openCancelModal={openCancelModal}
        />
        {functionsLoading ? (
          <div className='mt-5'>
            <TableLoader rowLength={2} label='Przynależność i funkcje' />
          </div>
        ) : (
          <MemberFunctions
            isRoleEditor={isRoleEditor}
            functionsModalData={functionsModalData}
            setFunctionsModalData={setFunctionsModalData}
            generalIsSending={generalIsSending}
            isSending={functionsIsSending}
            submitHandler={updateFunction}
            member={member}
            withArchived={withArchived}
            toggleArchivedFunctions={toggleArchivedFunctions}
            sectionRemover={sectionRemover}
            sectionAdder={sectionAdder}
            sectionChanger={sectionChanger}
            functions={functionsData}
          />
        )}
        <div className={contactSection}>
          {contactLoading ? (
            <div className='mt-5'>
              <TableLoader rowLength={3} label='Dane osobowe' />
            </div>
          ) : (
            <MemberContactData
              data={contact}
              personalData={personalData}
              onReveal={setContactIsReveal}
              onSubmit={updateContactInfo}
              setIsEdit={setContactIsEdit}
              isEdit={contactIsEdit}
              isReveal={contactIsReveal}
              isSending={contactIsSending}
            />
          )}
          {loadingSensitive ? (
            <div className=''>
              <TableLoader rowLength={2} label='Dane wrażliwe' />
            </div>
          ) : (
            <SensitivePersonalData
              data={sensitive}
              onReveal={setSensitiveIsReveal}
              onSubmit={updateSensitiveInfo}
              setIsEdit={setSensitiveIsEdit}
              isEdit={sensitiveIsEdit}
              isReveal={sensitiveIsReveal}
              isSending={sensitiveIsSending}
            />
          )}
        </div>

        {employmentLoading ? (
          <div className=''>
            <TableLoader rowLength={2} label='Miejsce zatrudnienia' />
          </div>
        ) : (
          <MemberEmployment
            refetch={refetchGeneral}
            educationUpdateData={educationUpdateData}
            employmentModalData={employmentModalData}
            setEmploymentModalData={setEmploymentModalData}
            isSending={employmentIsSending}
            initJobs={initJobs}
            submitHandler={updateEmployment}
          />
        )}

        <MemberTags
          refetch={memberTagsRefetch}
          isLoading={memberTagsLoading}
          memberTags={memberTagsList}
        />

        {memberDecorationsLoading ? (
          <div className='mt-5'>
            <TableLoader rowLength={2} label='Lista przyznanych odznaczeń' />
          </div>
        ) : (
          <MemberDecoration
            refetch={refetchMemberDecorations}
            isLoading={memberDecorationsLoading}
            memberDecorations={memberDecorationsList}
          />
        )}

        <RecordData admittedAt={member?.admittedAt} />
      </div>

      <ConfirmDeleteMemberModal
        isOpen={isOpenDeleteModal}
        handleClose={() => setIsOpenDeleteModal(false)}
      />
    </>
  )
}
