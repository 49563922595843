export const dayFlex = (num: number) => {
  if (num === 1) {
    return '1 dzień'
  }
  return `${num} dni`
}

export const monthFlex = (num: number) => {
  if (num === 1) {
    return '1 miesiąc'
  }
  if (num % 10 >= 2 && num % 10 <= 4 && (num % 100 < 12 || num % 100 > 14)) {
    return `${num} miesiące`
  }
  return `${num} miesięcy`
}

export const yearFlex = (num: number) => {
  if (num === 1) {
    return '1 rok'
  }
  if (num % 10 >= 2 && num % 10 <= 4 && (num % 100 < 12 || num % 100 > 14)) {
    return `${num} lata`
  }
  return `${num} lat`
}

export const getTermFlex = (num: number, type: string) => {
  if (type === 'day') {
    return dayFlex(num)
  }
  if (type === 'month') {
    return monthFlex(num)
  }
  return yearFlex(num)
}

export const getTermLength = (type: string, num: number) => {
  if (type === 'month') {
    return monthFlex(num)
  }
  return yearFlex(num)
}

export const structureArrayToObject = (e: { type: string; name: string }[]) => {
  const result: { [key: string]: string } = {}

  e.forEach((item) => {
    result[item.type] = item.name
  })

  return result
}

export const roleById = (slug: string, roles: { role: string; name: string }[]) => {
  const role = roles.find((item) => item.role === slug)
  return role ? role.name : ''
}

export const commonArrayToObject = (e: { id: string; name: string }[]) => {
  const result: { [key: string]: string } = {}

  e.forEach((item) => {
    result[item.id] = item.name
  })

  return result
}
