import { useQuery } from '@tanstack/react-query'
import { useAppSelector } from 'app/hooks'
import { Button } from 'components/Atoms/Button'
import LoadingModal from 'components/Atoms/LoadingModal'
import CardMemberInfo from 'components/CardMember/CardMemberInfo'
import { queryKeys } from 'constants/queryKeys'
import { getCard, getCardReqStatusDict } from 'fetchers/cardFetchers'
import { useNavigate, useParams } from 'react-router-dom'
import { path } from 'constants/path'
import { IDict } from 'types/dictionary'
import CardMemberLifecycle from 'components/CardMember/CardMemberLifecycle'
import { Typography } from 'components/Atoms/Typography'
import { useState } from 'react'

const CardDetails = () => {
  const [showDetails, setShowDetails] = useState(false)
  const { id } = useParams()
  const navigate = useNavigate()
  const cardId = id as string
  const { userToken } = useAppSelector((state: any) => state.authReducer)
  const {
    data: cardDetail,
    isLoading,
    error,
    refetch,
  } = useQuery({
    queryKey: [userToken, queryKeys.getCard, cardId, showDetails],
    queryFn: () => getCard(cardId, showDetails),
  })

  // REQUEST STATUS
  const { data: reqStatus, isLoading: statusLoading } = useQuery({
    queryKey: [userToken, queryKeys.cardReqStatusDict],
    queryFn: () => getCardReqStatusDict(),
  })
  const statusList = reqStatus?.items || []
  const currentStatus = statusList.find((status: IDict) => status.id === cardDetail?.status)
  // END REQUEST STATUS

  const toggleDetails = () => setShowDetails((p) => !p)
  if (isLoading)
    return <LoadingModal route key={'cardDetails'} forbidden={false} isLoading={isLoading} />

  return (
    <section>
      <Typography size='xl' weight='semibold'>
        Szczegóły legitymacji
      </Typography>
      <CardMemberInfo
        showDetails={showDetails}
        toggleDetails={toggleDetails}
        data={cardDetail}
        type={currentStatus?.name}
        status={currentStatus?.name}
        branch={cardDetail?.branch?.name}
      />

      <div className='my-4 flex justify-center'>
        <Button label='Powrót' onClick={() => navigate(path.card.list)} />
      </div>
      <CardMemberLifecycle
        data={cardDetail.events}
        typeList={statusList}
        statusList={statusList}
        eventList={statusList}
      />
    </section>
  )
}

export default CardDetails
