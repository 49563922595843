import { IMutationErrorType } from 'types/httpStatus.enum'

export const branchErrors: IMutationErrorType = {
  archive: {
    u404: 'Oddział nie istnieje.',
    u422: 'Oddział ma aktywnych członków.',
  },
  create: {
    u422: 'Niepoprawne dane oddziału',
  },
  merge: {},
  update: {
    u404: 'Oddział nie istnieje.',
  },
}

export const branchMigrationErrors: IMutationErrorType = {
  accept: {
    u422: 'Nie można zaakceptować prośby o przeniesienie',
  },
  reject: {
    u422: 'Nie można odrzucić prośby o przeniesienie',
  },
  cancel: {
    u422: 'Nie można anulować prośby o przeniesienie',
  },
}
