import React, { useEffect, useState } from 'react'
import { Card } from 'components/Atoms/Card'
import { Typography } from 'components/Atoms/Typography'
import { Button } from 'components/Atoms/Button'
import { Table } from 'components/Atoms/Table'
import { Select } from 'components/Atoms/Select'
import { Pagination } from 'components/Atoms/Pagination'
import { useNavigate } from 'react-router-dom'
import { EyeIcon } from '@heroicons/react/24/outline'
import { Spinner } from 'components/Atoms/Spinner'
// import useGlobalHttpState from 'hooks/useGlobalHttpState'
import {
  errorQuery,
  getLoadingHandler,
  setupSearchFilters,
  setupSearchMultiFilters,
  slugify,
  userFilters,
} from 'tools/queryHelpers'
import { IBasicSettingObject, IPageHandler, IVoidObject } from 'types/form'
import { useQuery } from '@tanstack/react-query'
import { queryKeys } from 'constants/queryKeys'
import { getUsersList } from 'fetchers/userFetchers'
import useSearch from 'hooks/useSearch'
import { getRegionsList } from 'fetchers/regionFetchers'
import { IUnitSelectedUnits } from 'types/structure'
import useAuth from 'hooks/useAuth'
import { getBranchesListPrefiltred } from 'fetchers/branchFetchers'
import { getSectionsListPrefiltred } from 'fetchers/sectionFetchers'
import { useLastPage } from 'hooks/useLastPage'
import UserSearchBar from './UserSearchBar'
import { path } from 'constants/path'
import useRoles from 'hooks/useRoles'
import { ISingleUserData } from 'types/userlist'
import LoadingModal from 'components/Atoms/LoadingModal'
import { HoverIcon } from 'components/Atoms/HoverIcon'
interface IOption {
  label: string
  value: number | string
}

interface ISearch {
  [key: string]: string
}

interface IFilterItem {
  id: string
  name: string
  type: string
  subtype: string
  childrenCnt: number
  parent: {
    id: string
    name: string
  }
}

const initSearch = {
  region: '',
  branch: '',
  section: '',
  query: '',
}

const selectedUnitsInit: IUnitSelectedUnits = {
  region: [],
  branch: [],
  section: [],
  institution: [],
}

const searchParams = {
  simpleParams: ['name', 'region', 'branch', 'section', 'role'],
  arrayParams: ['region', 'branch', 'section', 'role'],
}

export const UsersList = () => {
  const { unitContext, userToken, userLoaded, isAllAdmin, isAllOD, isAllOK } = useAuth()
  const { roles } = useRoles()
  const navigate = useNavigate()
  const [regionSearch, setRegionSearch] = useState('')
  const [branchSearch, setBranchSearch] = useState('')
  const [sectionSearch, setSectionSearch] = useState('')
  const [rolesSearch, setRolesSearch] = useState('')
  // SELECTED UNITS
  const [selectedUnits, setSelectedUnits] = useState<IUnitSelectedUnits>(selectedUnitsInit)
  // Per page states
  const [regionPerPage, setRegionPerPage] = useState(10)
  const [branchPerPage, setBranchPerPage] = useState(10)
  const [sectionPerPage, setSectionPerPage] = useState(10)
  const { filters, param, perPage, page, setLimit, changePage } = useSearch({
    simpleParams: ['search', 'region', 'branch', 'section'],
    arrayParams: ['region', 'branch', 'section', 'role'],
  })
  const id = unitContext?.id
  const selectedRegions = selectedUnits.region.length
  const selectedBranches = selectedUnits.branch.length

  // const rolesList = flatten(Object.values(roles))
  const isAdmin = isAllAdmin()
  const isRegion = isAllOK()
  const isBranch = isAllOD()
  const isAllow = isAdmin || isRegion || isBranch
  // --------------
  // LOADER SECTION

  // REGIONS LOADER
  // Get regions list
  const {
    data: regions,
    isLoading: regLoading,
    error: regError,
    refetch: rfReg,
  } = useQuery({
    queryKey: [userToken, queryKeys.regionList, regionPerPage, regionSearch],
    queryFn: () => getRegionsList(1, regionPerPage, [{ name: regionSearch }]),
    retry: errorQuery,
  })
  // Regions list
  const regionsList = regions?.items
    ? regions.items.map((e: any) => ({ id: e.id, name: e.name }))
    : []
  // END REGIONS LOADER

  // BRANCHES LOADER
  // Get branches list
  const branchFilters =
    setupSearchFilters([{ name: branchSearch }]) +
    setupSearchMultiFilters([{ region: selectedUnits.region }])
  const {
    data: branches,
    isLoading: brLoading,
    error: brError,
    refetch: rfBr,
  } = useQuery({
    queryKey: [
      userToken,
      queryKeys.branchesList,
      branchPerPage,
      branchSearch,
      selectedRegions,
      branchFilters,
    ],
    queryFn: () => {
      if (selectedRegions === 0) {
        return { items: [] }
      }
      return getBranchesListPrefiltred(1, branchPerPage, branchFilters)
    },
    retry: errorQuery,
  })
  // Branches list
  const branchesList = branches?.items
    ? branches.items.map((e: any) => ({ id: e.id, name: e.name }))
    : []
  // END BRANCHES LOADER

  // SECTIONS LOADER
  // Get sections list
  const sectionFilters =
    setupSearchFilters([{ name: sectionSearch }]) +
    setupSearchMultiFilters([{ branch: selectedUnits.branch }])
  const {
    data: sections,
    isLoading: secLoading,
    error: secError,
    refetch: rfSec,
  } = useQuery({
    queryKey: [
      userToken,
      queryKeys.sectionsList,
      sectionPerPage,
      sectionSearch,
      selectedBranches,
      selectedRegions,
      sectionFilters,
    ],
    queryFn: () => {
      if (selectedBranches === 0) {
        return { items: [] }
      }
      return getSectionsListPrefiltred(1, sectionPerPage, sectionFilters)
    },
    retry: errorQuery,
  })
  // Sections list
  const sectionsList = sections?.items
    ? sections.items.map((e: any) => ({ id: e.id, name: e.name }))
    : []
  // END SECTIONS

  const { rolesOptions } = useRoles()

  // MEMBERS LOADER
  const filterData = [
    { search: param?.single?.search || '' },
    { section: param?.array?.section || [] },
    { branch: param?.array?.branch || [] },
    { region: param?.array?.region || [] },
    { role: param?.array?.role || [] },
  ]
  const { data: users, isLoading } = useQuery({
    queryKey: [userToken, queryKeys.membersList, page, perPage, filters, id],
    queryFn: () => {
      return getUsersList(page, perPage, filterData)
    },
    retry: errorQuery,
  })
  const usersList = users?.items
    ? users?.items.map((u: ISingleUserData) => {
        const hasContexts = u?.contexts && u?.contexts.length > 0
        const role = hasContexts
          ? u?.contexts
              .map((c: any) => {
                const currRole = roles?.[c?.role]?.name
                const unit = c?.unit?.name
                if (!unit) return currRole
                return `${currRole} (${unit})`
              })
              .join(', ')
          : ''
        const region = hasContexts
          ? u?.contexts
              .filter((c: any) => c.role.includes('REGION'))
              .map((b: any) => b.unit.name)
              .join(', ')
          : ''
        const branch = hasContexts
          ? u?.contexts
              .filter((c: any) => c.role.includes('BRANCH'))
              .map((b: any) => b.unit.name)
              .join(', ')
          : ''
        const section = hasContexts
          ? u?.contexts
              .filter((c: any) => c.role.includes('SECTION'))
              .map((b: any) => b.unit.name)
              .join(', ')
          : ''
        return {
          role: role,
          firstName: u?.firstName,
          lastName: u?.lastName,
          email: u?.email,
          id: u?.id,
          region: region,
          branch: branch,
          section: section,
        }
      })
    : []
  // Setup member loading state
  const usersLoading = isLoading || !userLoaded
  // Setup last page
  const lastPage = useLastPage(users?.pagination, usersLoading)
  // END MEMBERS LOADER

  const rolesFiltered = rolesOptions
    ? rolesOptions
        .filter((role) => role?.name.includes(rolesSearch))
        .map((role: any) => ({ name: role?.name, id: role?.role }))
    : []

  // OPTIONS BUNDLER
  const options: IBasicSettingObject = {
    regions: regionsList,
    branches: branchesList,
    sections: sectionsList,
    role: rolesFiltered,
  }
  // END OPTIONS BUNDLER

  // LOADING BUNDLER
  const loadings: any = {
    regions: regLoading,
    branches: brLoading,
    sections: secLoading,
    role: rolesOptions?.length === 0,
  }
  // REFETCH BUNDLER
  const refetch: IVoidObject = {
    regions: rfReg,
    branches: rfBr,
    sections: rfSec,
  }
  // END REFETCH BUNDLER

  // SEARCH MORE BUNDLER
  const searchMoreHandler = (unit: any, loading: boolean, setter: React.Dispatch<any>) => {
    const count =
      unit?.pagination?.countFiltered > 0
        ? unit?.pagination?.countFiltered
        : unit?.pagination?.count
    if (!loading && unit?.items?.length < count) setter((prev: number) => prev + 10)
  }
  const searchMore: IVoidObject = {
    regions: () => searchMoreHandler(regions, regLoading, setRegionPerPage),
    branches: () => searchMoreHandler(branches, brLoading, setBranchPerPage),
    sections: () => searchMoreHandler(sections, secLoading, setSectionPerPage),
  }
  // END SEARCH MORE BUNDLER

  // SET LIMIT BUNDLER
  const setBoxLimit: IPageHandler = {
    regions: (e: number) => setRegionPerPage(e),
    branches: (e: number) => setBranchPerPage(e),
    sections: (e: number) => setSectionPerPage(e),
  }
  // END SET LIMIT BUNDLER

  // QUERY BUNDLER
  const query: any = {
    regions: regionSearch,
    branches: branchSearch,
    sections: sectionSearch,
    role: rolesSearch,
  }
  // END QUERY BUNDLER

  // QUERY HANDLER BUNDLER
  const queryHandler = {
    regions: (e: string) => setRegionSearch(e),
    branches: (e: string) => setBranchSearch(e),
    sections: (e: string) => setSectionSearch(e),
    role: (e: string) => setRolesSearch(e),
  }
  // END QUERY HANDLER BUNDLER

  // PAGE LIMIT HANDLER
  const onLimitHandler = (e: IOption) => setLimit(e.value)
  // END PAGE LIMIT HANDLER

  const columns = React.useMemo(
    () => [
      {
        Header: 'Imię',
        accessor: 'firstName',
      },
      {
        Header: 'Nazwisko',
        accessor: 'lastName',
      },
      {
        Header: 'E-mail',
        accessor: 'email',
      },
      {
        Header: 'Rola',
        accessor: 'role',
      },
      {
        Header: 'Okręg',
        accessor: 'region',
      },
      {
        Header: 'Oddział',
        accessor: 'branch',
      },
      {
        Header: 'Ognisko',
        accessor: 'section',
      },
      {
        Header: 'Akcje',
        accessor: 'action',
        Cell: (row: any) => {
          return (
            <div className='flex'>
              <HoverIcon
                iconName='EyeIcon'
                title='Zobacz'
                onClick={() => {
                  navigate && navigate(`/user/${row.row.original.id}`)
                }}
              />
            </div>
          )
        },
      },
    ],
    [],
  )
  useEffect(() => {
    if (selectedRegions === 0) {
      setSelectedUnits({ ...selectedUnitsInit, branch: isBranch ? [id] : [] })
    }
  }, [selectedRegions, isBranch])
  useEffect(() => {
    if (selectedBranches === 0) {
      setSelectedUnits({ ...selectedUnits, section: [] })
    }
  }, [selectedBranches])
  useEffect(() => {
    if (isRegion) setSelectedUnits({ ...selectedUnitsInit, region: [id] })
    if (isBranch) setSelectedUnits({ ...selectedUnitsInit, branch: [id] })
    if (isAdmin) setSelectedUnits({ ...selectedUnitsInit })
  }, [isRegion, isBranch, isAdmin, userToken])
  const errorData = isAdmin ? regError : isRegion ? brError : secError
  const loadingHandler = getLoadingHandler(errorData, false, !isAllow)
  if (loadingHandler.show) return <LoadingModal {...loadingHandler} />
  return (
    <div>
      <Typography size='xl' weight='semibold'>
        Role użytkowników w systemie / lista
      </Typography>

      <div className='mt-5 flex justify-between'>
        <Button label='Przypisz użytkownika' onClick={() => navigate(path.user.update)} />
      </div>
      <UserSearchBar
        selectedUnits={selectedUnits}
        setSelectedUnits={setSelectedUnits}
        searchParams={searchParams}
        options={options}
        loadings={loadings}
        query={query}
        refetch={refetch}
        queryHandler={queryHandler}
        searchMore={searchMore}
        limitSetter={setBoxLimit}
      />

      <Card label='Użytkownicy'>
        <div className='flex justify-between'>
          <div className='flex items-center'>
            <Select
              handleSelect={onLimitHandler}
              options={[
                { label: '10', value: 10 },
                { label: '20', value: 20 },
              ]}
            />
          </div>
          {lastPage > 1 && (
            <Pagination lastPage={lastPage} currentPage={page} handlePageChange={changePage} />
          )}
        </div>
        <Table maxColumnWidth='300px' columns={columns} data={usersList} />
        {usersLoading && (
          <div className='justify-c enter flex p-4 align-middle'>
            <Spinner />
          </div>
        )}
        {lastPage > 1 && (
          <div className='flex justify-end'>
            <Pagination lastPage={lastPage} currentPage={page} handlePageChange={changePage} />
          </div>
        )}
      </Card>
    </div>
  )
}
