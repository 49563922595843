import { queryKeys } from 'constants/queryKeys'
import useSearchModule from './useSearchModule'
import { useAppSelector } from 'app/hooks'
import { getLegalList } from 'fetchers/legatFetchers'
import { useQuery } from '@tanstack/react-query'
import { useState } from 'react'
import { ILegalHook } from 'types/legal'

type Props = {
  isRegion?: boolean | undefined
}

export const useLegalFetcher = (isRegion?: boolean | undefined): ILegalHook => {
  const [unit, setUnit] = useState('')

  const { currentPage, setPerPage, filters } = useSearchModule({})
  const { userToken } = useAppSelector((state: any) => state.authReducer)

  const {
    data: legalData,
    isLoading: legalLoading,
    refetch,
  } = useQuery({
    queryKey: [userToken, queryKeys.declarationList, currentPage, filters, unit],
    queryFn: () => {
      if (!unit && !isRegion) return { items: [] }
      return getLegalList(currentPage, 10, [...filters, { unit: unit }])
    },
  })
  const legalList = (legalData?.items || []).map(
    (e: { name: string; identifier: string; id: string }) => {
      return {
        label: e.identifier + (e?.name ? ` (${e?.name})` : ''),
        value: e.id,
      }
    },
  )

  const searchMore = () => {
    const count =
      legalData?.pagination?.countFiltered > 0
        ? legalData?.pagination?.countFiltered
        : legalData?.pagination?.count
    if (!legalLoading && legalData?.items?.length < count) setPerPage((prev) => prev + 10)
  }
  return {
    setUnit,
    unit,
    legal: {
      search: searchMore,
      refetch,
      list: legalList,
      loading: legalLoading,
    },
  }
}

export default useLegalFetcher
