import { useMemo, useState } from 'react'
import { Button } from 'components/Atoms/Button'
import { Card } from 'components/Atoms/Card'
import { Typography } from 'components/Atoms/Typography'
import { Select } from 'components/Atoms/Select'
import { useNavigate, useParams } from 'react-router-dom'
import { Pagination } from 'components/Atoms/Pagination'
import { exportJson } from 'tools/jsonExport'
import useAuth from 'hooks/useAuth'
import { queryKeys } from 'constants/queryKeys'
import useSearch from 'hooks/useSearch'
import { useMutation, useQuery } from '@tanstack/react-query'
import { errorQuery } from 'tools/queryHelpers'
import {
  getMemberChangelog,
  getMemberChangelogDetails,
  getMembersDetails,
} from 'fetchers/membersFetchers'
import { Controller, useForm } from 'react-hook-form'
import { Checkbox } from 'components/Atoms/Checkbox'
import { IOption } from 'types/form'
import { useLastPage } from 'hooks/useLastPage'
import { Table } from 'components/Atoms/Table'
import { setupDate, setupTime } from 'tools/formTools'
import { Spinner } from 'components/Atoms/Spinner'
import { TMemberChangelogType } from 'types/member'
import { getUserById } from 'fetchers/userFetchers'
import { HoverIcon } from 'components/Atoms/HoverIcon'
import { toast } from 'react-toastify'
import { AxiosError } from 'axios'
import { mutationErrorHandler } from 'tools/errorHandler'

export const MemberHistoryDetails = () => {
  const [unveiled, setUnveiled] = useState<any>([])
  const [type, setType] = useState<TMemberChangelogType>('simple')
  const [isVisile, setIsVisible] = useState(false)
  const { filters, perPage, page, setLimit, changePage } = useSearch({ simpleParams: ['full'] })
  const { control } = useForm()
  const { userToken, id } = useAuth()
  const navigate = useNavigate()
  // GET CHANGES
  const visibleFilter = isVisile ? '&showValues=true' : ''
  const queryFilters = filters + visibleFilter
  const { data, isLoading, error, refetch } = useQuery({
    queryKey: [userToken, queryKeys.memberChangelog, page, perPage, queryFilters, type],
    queryFn: () => getMemberChangelog(page, perPage, id, queryFilters, type),
    retry: errorQuery,
  })
  const changesList = data?.items
    ? data.items.map((e: any) => ({
        id: e.id,
        issuer: e.user?.displayName || e.user?.email,
        user: e.user,
        date: setupDate(e.createdAt),
        time: setupTime(e.createdAt),
        changes: e.changes,
        fields: e.changes.map((item: any) => item.label),
        after: e.changes.map((item: any) => item.newValue || '-'),
        before: e.changes.map((item: any) => item.oldValue || '-'),
      }))
    : []
  const lastPage = useLastPage(data?.pagination, isLoading)

  // GET MEMBER DETAILS
  const { data: member, isLoading: memberIsLoading } = useQuery({
    queryKey: [userToken, queryKeys.member, id],
    queryFn: () => getMembersDetails(id),
    retry: errorQuery,
  })
  const memberName = `${member?.firstName}${member?.secondName ? ` ${member?.secondName}` : ''} ${
    member?.lastName
  }`
  // END OF GET MEMBER DETAILS

  // GET SINGLE CHANGE
  const mutateSingle = useMutation({
    mutationFn: (changeId: string) => getMemberChangelogDetails(id, changeId, type),
    onSuccess: (res, data) => {
      const alreadyUnveiled = unveiled.find((item: any) => item.id === data)
      if (!alreadyUnveiled) {
        setUnveiled((prev: any[]) => [...prev, { id: data, data: res }])
      }
    },
    onError: (error: AxiosError) => {
      console.error(error)
      mutationErrorHandler(null, null, 'Nie udało się odsłonić danych.')
    },
    retry: errorQuery,
  })
  const revealHandler = async (id: string) => {
    try {
      mutateSingle.mutate(id)
    } catch (error) {
      console.error(error)
      mutationErrorHandler(null, null, 'Nie udało się odsłonić danych.')
    }
  }
  // END OF GET SINGLE CHANGE
  const handleVisible = () => setIsVisible((prev) => !prev)
  const onLimitHandler = (e: IOption) => setLimit(e.value)

  const exportHandler = () => {
    exportJson(data)
  }
  const columns = useMemo(
    () => [
      {
        Header: 'Zmieniający',
        accessor: 'issuer',
        Cell: ({ row }: any) => {
          if (type === 'simple') return row.original.issuer
          const { data: user } = useQuery({
            queryKey: [userToken, queryKeys.user, row.original.user],
            queryFn: () => getUserById(row.original.user),
          })
          return user?.displayName || user?.email || '-'
        },
      },
      {
        Header: 'Data',
        accessor: 'date',
      },
      {
        Header: 'Czas',
        accessor: 'time',
      },
      {
        Header: 'Zmieniane pola',
        accessor: 'fields',
        Cell: ({ row }: any) => {
          return (
            <ul>
              {row.original.fields.map((item: any, idx: number) => (
                <li key={`${item}-${idx}-fields`}>{item}</li>
              ))}
            </ul>
          )
        },
      },
      {
        Header: 'Treść po zmianie',
        accessor: 'after',
        Cell: ({ row }: any) => {
          const revealed = unveiled.find((item: any) => item.id === row.original.id)
          const source =
            isVisile ||
            revealed?.data?.changes?.map((i: any) => i?.newValue || '-') ||
            row.original.before
          return (
            <ul className='flex flex-col'>
              {source.map((item: any, idx: number) => {
                const itemKey = item?.id || item
                const itemData = item?.name || item
                return <li key={`${itemKey}-${idx}-after`}>{itemData}</li>
              })}
            </ul>
          )
        },
      },
      {
        Header: 'Treść przed zmianą',
        accessor: 'before',
        Cell: ({ row }: any) => {
          const revealed = unveiled.find((item: any) => item.id === row.original.id)
          const source =
            isVisile ||
            revealed?.data?.changes?.map((i: any) => i?.oldValue || '-') ||
            row.original.before
          return (
            <ul className='flex flex-col'>
              {source.map((item: any, idx: number) => {
                const itemKey = item?.id || item
                const itemData = item?.name || item
                return <li key={`${itemKey}-${idx}-before`}>{itemData}</li>
              })}
            </ul>
          )
        },
      },
      {
        Header: 'Akcje',
        accessor: 'action',
        Cell: ({ row }: any) => {
          const isDisabled = unveiled.find((item: any) => item.id === row.original.id)
          return (
            <div className='flex'>
              {!isDisabled && (
                <HoverIcon
                  disabled={row.original?.archived}
                  iconName='LockOpenIcon'
                  title='Ujawnij dane'
                  onClick={() => revealHandler(row.original.id)}
                />
              )}
              <HoverIcon
                disabled={row.original?.archived}
                iconName='EyeIcon'
                title='Podejrzyj'
                onClick={() => navigate(`${row.original.id}/${type}`)}
              />
            </div>
          )
        },
      },
    ],
    [type, unveiled.length],
  )
  return (
    <div>
      <Typography size='xl' weight='semibold'>
        Historia zmian / {memberName}
      </Typography>
      <Card label={`Historia zmian - dane ${type === 'simple' ? 'podstawowe' : 'wrażliwe'}`}>
        <div className='mb-4 flex items-end justify-between'>
          <Select
            handleSelect={onLimitHandler}
            options={[
              { label: '10', value: 10 },
              { label: '20', value: 20 },
            ]}
          />
          <div className='flex items-center'>
            <Controller
              name='completeData'
              control={control}
              render={({ field: { onChange } }) => (
                <Checkbox
                  label='Pokazuj kompletne dane'
                  id='Pokazuj kompletne dane'
                  checked={isVisile}
                  onChange={(e) => {
                    onChange(e.target.checked)
                    handleVisible()
                    refetch()
                  }}
                />
              )}
            />
            <div className='ml-4 w-48'>
              <Select
                handleSelect={(val) => setType(val.value as TMemberChangelogType)}
                options={[
                  { label: 'Podstawowe', value: 'simple' },
                  { label: 'Wrażliwe', value: 'secure' },
                ]}
              />
            </div>
          </div>
        </div>
        <Table maxColumnWidth='300px' columns={columns} data={changesList} />
        {isLoading && (
          <div className='flex justify-center p-4 align-middle'>
            <Spinner />
          </div>
        )}
        <div className='flex justify-end'>
          {lastPage > 1 && (
            <Pagination lastPage={lastPage} currentPage={page} handlePageChange={changePage} />
          )}
        </div>
      </Card>
    </div>
  )
}
