import { Card } from 'components/Atoms/Card'
import { LabelTypography } from 'components/Atoms/LabelTypography'
import { useNavigate } from 'react-router-dom'
import { Typography } from 'components/Atoms/Typography'
import { IListElement } from 'types/form'

interface IProps {
  data: any
  showModal: (data: IListElement) => void
}

export const InstitutionBaseInfo: React.FC<IProps> = ({ data, showModal }) => {
  const navigate = useNavigate()
  const { address } = data
  const street = address
    ? `${address.streetName || '-'} ${address.houseNr || ''}${
        address.apartmentNr ? ` / ${address.apartmentNr}` : ''
      }`
    : '-'

  return (
    <Card
      label='Dane ogólne'
      actionsButton={[
        {
          label: 'Archiwizuj',
          handleClick: () => showModal(data),
        },
        {
          label: 'Edytuj',
          handleClick: () => navigate('update'),
        },
      ]}
    >
      <div className='mb-5 grid grid-cols-4 gap-5'>
        <LabelTypography label='Nazwa placówki' text={data?.name} />
        <LabelTypography label='Typ placówki' text={data?.type?.name} />
        <LabelTypography
          label='Prezes placówki'
          text={`${data?.headmaster?.firstName ? data.headmaster.firstName : ''} ${
            data?.headmaster?.lastName ? data?.headmaster?.lastName : ''
          }`}
        />
      </div>
      <div className='mb-5 grid grid-cols-4 gap-5'>
        <LabelTypography
          label='Telefon'
          text={`${data?.headmaster?.contact?.phone ? data.headmaster.contact.phone : ''}`}
        />
        <LabelTypography
          label='Email kontaktowy'
          text={`${data?.headmaster?.contact?.email ? data.headmaster.contact.email : ''}`}
        />
      </div>
      <Typography size='xl' className='mb-3 mt-5'>
        Dane adresowe
      </Typography>
      <div className='grid grid-cols-4 gap-5'>
        <LabelTypography label='Miejscowość' text={data?.address?.city} />
        <LabelTypography label='Województwo' text={data?.voivodeship?.name} />
        <LabelTypography label='Kod pocztowy' text={data?.address?.postalCode} />
        <LabelTypography label='Ulica' text={street} />
      </div>
    </Card>
  )
}

export default InstitutionBaseInfo
