export const fileUpload = async (res: any) => {
  try {
    const contentDisposition = res.headers['content-disposition']
    let filename = 'wydruk_legitymacji.pdf'

    if (contentDisposition) {
      const matches = contentDisposition.match(/filename="(.+?)"/)
      if (matches && matches.length > 1) {
        filename = matches[1]
      }
    }

    // Create Blob from ArrayBuffer
    const blob = new Blob([res.data], { type: 'application/pdf' })

    // Create a URL for the Blob
    const url = window.URL.createObjectURL(blob)

    // Create an anchor element to trigger download
    const a = document.createElement('a')
    a.href = url
    a.download = filename

    // Append anchor element to the body, trigger click, and remove it
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)

    // Revoke the Blob URL to free up resources
    window.URL.revokeObjectURL(url)
  } catch (error) {
    console.error('Error handling response:', error)
  }
}
