export const path = {
  dashboard: '/dashboard',
  // UŻYTKOWNICY
  user: {
    list: '/user',
    details: '/user/:id',
    history: '/user/:id/history',
    historyDetails: '/user/:id/history/:historyId',
    create: '/user/create',
    update: '/user/update',
  },
  // BAZA CZŁONKÓW
  member: {
    list: '/member',
    notifications: '/member/notifications',
    notificationsDetails: '/member/notifications/:id',
    notify: '/member/:id/notify-change',
    details: '/member/:id',
    migration: '/member/:id/migration',
    history: '/member/:id/history',
    historyDetails: '/member/:id/history/:historyId/:termId',
    create: '/member/create',
    copy: '/member/copy',
    archive: '/member/:id/archive',
  },
  // DEKLARACJE
  declaration: {
    base: '/declaration/*',
    list: '/declaration',
    details: '/declaration/:id',
    create: '/declaration/create',
    update: '/declaration/:id/update',
  },
  // OGNISKO
  section: {
    list: '/section',
    archive: '/section/archive',
    createClub: '/section/club/create',
    createGroup: '/section/group/create',
    merge: '/section/merge',
    updateClub: '/section/club/:id/update/',
    updateGroup: '/section/group/:id/update/',
    details: '/section/:id',
  },
  // OKRĘG
  region: {
    list: '/region',
    create: '/region/create',
    details: '/region/',
    update: '/region/update/',
  },
  // PLACÓWKA
  institution: {
    list: '/institution',
    details: '/institution/:id',
    create: '/institution/create',
    update: '/institution/:id/update/',
  },
  // ODDZIAŁ
  branch: {
    list: '/branch',
    archive: '/branch/archive',
    create: '/branch/create',
    migrations: '/branch/migrations',
    migration: '/branch/migrations/:id',
    merge: '/branch/:id/merge',
    details: '/branch/:id',
    update: '/branch/:id/update/',
  },
  // RESOLUTION
  resolution: {
    list: '/resolution',
    create: '/resolution/create',
    update: '/resolution/:id/update',
    details: '/resolution/:id',
  },
  // FUNKCJE W OKRĘGU
  regionFunctions: {
    list: '/region-functions',
    create: '/region-functions/:id/create',
    show: '/region-functions/id:/:memberId/:termId',
    update: '/region-functions/:id/:memberId/:termId/update',
  },
  // FUNKCJE W ODDZIALE
  branchFunctions: {
    list: '/branch-functions',
    create: '/branch-functions/:id/create',
    show: '/branch-functions/id:/:memberId/:termId',
    update: '/branch-functions/:id/:memberId/:termId/update',
  },
  // OBOWIĄZEK STATYSTYCZNY
  statisticalObligation: {
    list: '/statisticalObligation',
    section: {
      list: '/statisticalObligation/section/list',
    },
    mainManagment: {
      main: '/statisticalObligation/managment',
      list: '/statisticalObligation/managment/list',
      create: '/statisticalObligation/managment/create',
    },
    branch: {
      main: '/statisticalObligation/branch',
      confirm: '/statisticalObligation/branch/confirm',
    },
    region: {
      main: '/statisticalObligation/region',
    },
  },
  census: {
    list: '/census',
    create: '/census/create',
    details: '/census/:id/show',
    update: '/census/:id/update',
  },
  //
  card: {
    list: '/card/list',
    showCard: '/card/show/:id',
    request: {
      list: '/card-request/list',
      create: '/card-request/create',
      details: '/card-request/:id',
      update: '/card-request/:id/:termId',
    },
  },
  // RODO
  rodo_resource: {
    members: {
      list: '/rodo/resource/members',
      ask: '/rodo/resource/members/request',
    },
    declarations: {
      list: '/rodo/resource/declarations',
      ask: '/rodo/resource/declarations/request',
    },
  },
  rodo_access: {
    members: {
      list: '/rodo/operations/members',
      ask: '/rodo/operations/members/request',
    },
    declarations: {
      list: '/rodo/operations/declarations',
      ask: '/rodo/operations/declarations/request',
    },
  },
  // EKSPORT
  export: {
    list: '/export/list',
    request: '/export/request',
    details: '/export/:id',
  },

  // SZKOLENIA
  training: {
    list: '/training',
    details: '/training/:id',
    detailsUpdate: '/training/:id/update',
    create: '/training/create',
    addMember: '/training/add-member/:id/:memberId',
    removeMember: '/training/remove-member/:id/:memberId',
    listMembers: '/training/list-members/:id',
  },

  // POLECENIA
  orders: {
    receive: '/orders/received',
    receiveDetails: '/orders/received/:id',
    issued: '/orders/issued',
    issuedDetails: '/orders/issued/:id',
    create: '/orders/create',
  },

  // DOKUMENTY
  document: {
    list: '/document',
    template: '/document/template',
    create: '/document/create',
    details: '/document/:id/show',
  },

  // USTAWIENIA
  settings: {
    main: '/settings',
    tags: {
      create: '/settings/tags/create',
      list: '/settings/tags',
      update: '/settings/tags/:id/update',
    },
    institution: {
      create: '/settings/institution-type/create',
      list: '/settings/institution-type',
      update: '/settings/institution-type/:id/update',
    },
    institutionGroup: {
      create: '/settings/institution-group/create',
      list: '/settings/institution-group',
      update: '/settings/institution-group/:id/update',
    },
    education: {
      create: '/settings/education/create',
      list: '/settings/education',
      update: '/settings/education/:id/update',
    },
    position: {
      create: '/settings/position/create',
      list: '/settings/position',
      update: '/settings/position/:id/update',
    },
    subject: {
      create: '/settings/subject/create',
      list: '/settings/subject',
      update: '/settings/subject/:id/update',
    },
  },
  // Struktura ZNP
  structure: {
    unitPositions: {
      create: '/structure/unit-position/create',
      list: '/structure/unit-position',
      update: '/structure/unit-position/:id/update',
    },
  },
  // Secure connection – VPN
  vpn: {
    redirect: '/vpn',
  },
}
