import { INavigation } from 'types/navigation'
import { useSelector } from 'react-redux'
import { SidebarTab } from '../SidebarTab'
import { SidebarGroup } from '../SidebarGroup'
import useAuth from 'hooks/useAuth'

interface IProps {
  navigation: INavigation[]
  toggleSidebar: () => void
  isSidebarVisible: boolean
}

export const Sidebar: React.FC<IProps> = ({ navigation, toggleSidebar, isSidebarVisible }) => {
  const { userLoaded } = useAuth()
  const isSecure = useSelector((state: any) => state.secureConnectionReducer.secure)
  const buildDateTime = process.env.REACT_APP_BUILD_DATETIME

  const handleSidebarGroupClick = () => {
    // toggleSidebar()
    if (!isSidebarVisible) {
      toggleSidebar()
    }
  }

  if (!userLoaded) {
    return (
      <div className='flex h-screen min-h-0 flex-1 flex-col bg-zinc-50'>
        <div className={'flex h-full flex-1 flex-col pb-4 pt-5'}>
          <nav className='mt-5 flex-1 space-y-1 bg-zinc-50 px-2' aria-label='Sidebar'></nav>
        </div>
      </div>
    )
  }
  const navigationFiltered = navigation.filter((item) => {
    if (item?.limited && item?.limited.length > 0) {
      if (item?.limited.includes(userLoaded)) {
        return true
      }
      return false
    }
    return true
  })
  return (
    <div className=' flex h-screen min-h-0 flex-1 flex-col bg-zinc-50'>
      <div className='relative flex h-full flex-1 flex-col gap-y-6 p-3'>
        <div className='ml-1 flex items-center justify-between'>
          <a href='/' className={`${isSidebarVisible ? 'pt-0' : 'pt-1'}`}>
            <img className='rounded-full' width={32} height={32} src='/znp_logo.png' alt='' />
          </a>
          <button
            type='button'
            className={`p-2.5 ${
              isSidebarVisible ? 'block' : 'hidden'
            } rounded-md p-1 text-gray-600 transition-all duration-300 hover:bg-gray-200`}
            onClick={toggleSidebar}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              strokeWidth={1.5}
              fill='currentColor'
              className='h-5 w-5'
              viewBox='0 0 16 16'
            >
              <path d='M3.5 3a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1zm0 3a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1zM3 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5m.5 2.5a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1z' />
              <path d='M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm12-1v14h2a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zm-1 0H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h9z' />
            </svg>
          </button>
        </div>
        <nav className='flex-1 space-y-1 bg-zinc-50' aria-label='Sidebar'>
          {navigationFiltered.map((item) => {
            if (item?.children && item?.children.length > 0) {
              return (
                <SidebarGroup
                  key={item.name}
                  item={item}
                  isSidebarVisible={isSidebarVisible}
                  onPress={handleSidebarGroupClick}
                />
              )
            }
            return (
              <SidebarTab
                isSidebarVisible={isSidebarVisible}
                key={item.name}
                item={item}
                onPress={() => {
                  //
                }}
              />
            )
          })}
        </nav>
      </div>
      {isSidebarVisible && (
        <div className='flex flex-shrink-0 border-t border-gray-300 bg-zinc-50 p-3'>
          <div className='group block w-full flex-shrink-0'>
            <p className='text-xs font-medium text-gray-700'>Aktywne</p>
            <p className='text-xs font-medium text-gray-600'>
              {isSecure ? 'Bezpieczne połączenie' : 'Połączenie bez VPN'}
            </p>
            <p className='text-xs font-medium text-gray-600'>Build: {buildDateTime}</p>
          </div>
        </div>
      )}
    </div>
  )
}
