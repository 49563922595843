import { useState } from 'react'
import { Card } from 'components/Atoms/Card'
import { Checkbox } from 'components/Atoms/Checkbox'
import { Pagination } from 'components/Atoms/Pagination'
import { Item } from 'components/StatisticalObligation/Item'
import { AddMemberForm } from 'components/StatisticalObligation/AddMemberForm'
import { ApproveLeftInfo } from 'components/StatisticalObligation/ApproveLeftInfo'
import { ReadyToApprove } from 'components/StatisticalObligation/ReadyToApprove'
import { ReportSummary } from 'components/StatisticalObligation/ReportSummary'

const UserItem = [
  {
    firstName: 'Wisława',
    lastName: 'Moczulska',
    institution: 'Szkoła podstawowa nr 2',
    position: 'Nauczyciel miarowany',
    job: 'Pół etatu',
    isExists: false,
  },
  {
    firstName: 'Krystyna',
    lastName: 'Wielicka',
    institution: 'LO VII',
    position: 'Dyrektor',
    job: 'Full time',
    isExists: true,
  },
]

export const StatisticalObligationSectionList = () => {
  const [displaySummary, setDisplaySummary] = useState(false)

  if (displaySummary) return <ReportSummary setDisplaySummary={() => setDisplaySummary(false)} />
  return (
    <>
      <Card label='Wypełnianie statystyk'>
        <div className='flex items-center justify-between'>
          <div className='flex'>
            <div className='mr-5'>
              <Checkbox label='Pokaż wszystkich' id='all' />
            </div>
            <Checkbox label='Pokaż niepotwierdzonych' id='unverified' />
          </div>
          {/* <Pagination /> */}
        </div>
      </Card>

      {UserItem.map((item, index) => (
        <div key={index}>
          <Item {...item} />
        </div>
      ))}

      <AddMemberForm />
      <ApproveLeftInfo />
      <ReadyToApprove setDisplaySummary={() => setDisplaySummary(true)} />
    </>
  )
}
