import React from 'react'
import { useTable } from 'react-table'

interface IProps {
  columns: any[]
  data: any[]
  maxColumnWidth?: string
}

export const Table: React.FC<IProps> = ({ columns, data, maxColumnWidth }) => {
  const tableInstance = useTable({ columns, data })

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance

  return (
    <div className='my-4'>
      <div className='rounded-md border bg-white'>
        <div className='relative w-full overflow-auto rounded'>
          <table className='w-full caption-bottom text-sm' {...getTableProps()}>
            <thead className='bg-slate-100 [&_tr]:border-b'>
              {headerGroups.map((headerGroup, index) => (
                <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map((column, idx) => (
                    <th
                      {...column.getHeaderProps()}
                      key={idx}
                      className='text-muted-foreground h-12 whitespace-nowrap p-4 text-left align-middle text-xs font-normal text-slate-600 [&:has([role=checkbox])]:pr-0'
                      style={{
                        maxWidth: `${maxColumnWidth}`,
                      }}
                    >
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody className='' {...getTableBodyProps()}>
              {rows.map((row, index) => {
                prepareRow(row)
                return (
                  <tr
                    {...row.getRowProps()}
                    key={index}
                    className='border-b transition-colors last:border-0 hover:bg-slate-50'
                  >
                    {row.cells.map((cell, idx) => (
                      <td
                        {...cell.getCellProps()}
                        key={idx}
                        className='whitespace-nowrapp p-4 align-top text-sm font-medium text-gray-900'
                        style={{
                          maxWidth: `${maxColumnWidth}`,
                        }}
                      >
                        {cell.column.id === 'roles' || cell.column.id === 'units'
                          ? cell.value.map((item: any, i: number) => (
                              <div key={i}>
                                {item.role || `${item.unit.name} (${item.unit.id})`}
                              </div>
                            ))
                          : cell.render('Cell')}
                      </td>
                    ))}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
