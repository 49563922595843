import { IMutationErrorType } from 'types/httpStatus.enum'

export const rodoPasswordErrors: IMutationErrorType = {
  get: {
    message: 'Nie udało się pobrać hasła.',
  },
}

export const rodoFileErrors: IMutationErrorType = {
  get: {
    message: 'Nie udało się pobrać pliku.',
  },
}

export const rodoRequestErrors: IMutationErrorType = {
  get: {
    message: 'Nie udało się wysłać zapytania.',
  },
}
