import { yupResolver } from '@hookform/resolvers/yup'
import { useQuery } from '@tanstack/react-query'
import { Button } from 'components/Atoms/Button'
import { Card } from 'components/Atoms/Card'
import { Modal } from 'components/Atoms/Modal'
import SearchableDropdown from 'components/Atoms/SearchableDropdown/SearchableDropdown'
import { errorMessage } from 'constants/errorMessage'
import { queryKeys } from 'constants/queryKeys'
import { getInstitutionsList } from 'fetchers/institutionFetchers'
import { getPositionsList } from 'fetchers/positionFetchers'
import { getSubjectsList } from 'fetchers/subjectFetchers'
import useAuth from 'hooks/useAuth'
import { useForm } from 'react-hook-form'
import { updateMemberEmploymentSchema } from 'schemas/memberSchema'
import { JobElement } from 'types/declaration'

type Props = {
  job?: JobElement
  isOpen: boolean
  isSending?: boolean
  closeModal: () => void
  submitHandler: (data: JobElement) => void
}

export const MemberEmploymentModal = ({
  job,
  isOpen,
  isSending,
  closeModal,
  submitHandler,
}: Props) => {
  const { userToken, userLoaded, id } = useAuth()
  // DATA FETCHING
  // INSTITUTIONS
  const { data: institution, isLoading: institutionLoading } = useQuery({
    queryKey: [userToken, queryKeys.institutionsList, 1, 1000],
    queryFn: () => getInstitutionsList(1, 1000),
  })
  // POSITIONS
  const { data: position, isLoading: positionLoading } = useQuery({
    queryKey: [userToken, queryKeys.positionsList, 1, 1000],
    queryFn: () => getPositionsList(1, 1000),
  })
  // SUBJECTS
  const { data: subject, isLoading: subjectLoading } = useQuery({
    queryKey: [userToken, queryKeys.subjectsList, 1, 1000],
    queryFn: () => getSubjectsList(1, 1000),
  })
  // END DATA FETCHING

  const institutionList = institution?.items
    ? institution?.items?.map((item: any) => {
        return { label: item.name, value: item.id }
      })
    : []

  const positionList = position?.items
    ? position?.items?.map((item: any) => {
        return { label: item.name, value: item.id }
      })
    : []

  const subjectList = subject?.items
    ? subject?.items?.map((item: any) => {
        return { label: item.name, value: item.id }
      })
    : []
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm({
    defaultValues: job,
    resolver: yupResolver(updateMemberEmploymentSchema),
  })
  const defaultOption = { value: '', label: '' }
  const onSubmit = (data: JobElement) => {
    submitHandler(data)
  }
  return (
    <Modal isOpen={isOpen} handleClose={closeModal}>
      <Card label='Edytuj'>
        <form className='grid grid-cols-3'>
          <div className='mr-5'>
            <SearchableDropdown
              clearError={() => setError('institution', { message: '' })}
              name={'institution'}
              label='Miejsce pracy'
              register={register}
              defaultOption={job?.institution || defaultOption}
              options={institutionList}
              control={control}
            />

            <p className='mt-2 text-sm text-red-600'>
              {errors?.institution?.message && errorMessage.requiredField}
            </p>
          </div>
          <div className='mr-5'>
            <SearchableDropdown
              clearError={() => setError('position', { message: '' })}
              name={'position'}
              label={'Stanowisko'}
              register={register}
              defaultOption={job?.position || defaultOption}
              options={positionList}
              control={control}
            />

            <p className='mt-2 text-sm text-red-600'>
              {errors?.position?.message && errorMessage.requiredField}
            </p>
          </div>

          <div className='mr-5'>
            <SearchableDropdown
              clearError={() => setError('subject', { message: '' })}
              name={'subject'}
              label={'Przedmiot'}
              register={register}
              defaultOption={job?.subject || defaultOption}
              options={subjectList}
              control={control}
            />

            <p className='mt-2 text-sm text-red-600'>
              {errors?.subject?.message && errorMessage.requiredField}
            </p>
          </div>
          <div className='col-span-3 mt-5 flex justify-center gap-5'>
            <Button label='Anuluj' variant='secondary' onClick={closeModal} />
            <Button
              disabled={isSending}
              className='disabled:border disabled:border-slate-200 disabled:bg-slate-50 disabled:text-slate-500 disabled:shadow-none'
              label={isSending ? 'Zapisywanie...' : 'Zapisz'}
              variant='primary'
              onClick={handleSubmit(onSubmit)}
            />
          </div>
        </form>
      </Card>
    </Modal>
  )
}

export default MemberEmploymentModal
