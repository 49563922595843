import { Card } from 'components/Atoms/Card'
import TagFrame from 'components/Atoms/TagFrame'
import { useState } from 'react'
import { Tag } from 'react-tag-autocomplete'
import { Spinner } from 'components/Atoms/Spinner'

import MemberTagEdit from './MemberTagEdit'
import useAuth from 'hooks/useAuth'
import { Button } from 'components/Atoms/Button'
import { ShowMoreTagButton } from 'components/Atoms/ShowMoreTagButton'
import TableLoader from 'components/Atoms/Loader/TableLoader'
type Props = {
  memberTags: Tag[]
  isLoading?: boolean
  refetch: () => void
}

export const MemberTags = ({ memberTags, isLoading, refetch }: Props) => {
  const [fullList, setFullList] = useState(false)
  const [iseEditView, setIsEditView] = useState(false)
  const { isAllOD } = useAuth()
  const allowEdit = isAllOD()

  const labelButton = allowEdit
    ? [
        {
          label: iseEditView ? 'Zakończ edycję' : 'Edytuj tagi',
          handleClick: () => setIsEditView((p) => !p),
        },
      ]
    : []
  const tagsList = fullList ? memberTags : memberTags.slice(0, 10)
  const showTagToggler = memberTags.length > 10
  const restNum = memberTags.length - tagsList.length

  return (
    <Card label='Tagi użytkownika' actionsButton={labelButton}>
      {isLoading && (
        <div className='mt-5 flex h-full items-center justify-center'>
          <TableLoader rowLength={2} label='' />
        </div>
      )}
      {iseEditView ? (
        <MemberTagEdit memberTags={memberTags} refetch={refetch} />
      ) : (
        <ul className='flex flex-wrap items-center gap-2'>
          {tagsList.length > 0 ? (
            tagsList.map((tag) => {
              return <TagFrame key={`${String(tag.value)}`} tag={tag} />
            })
          ) : (
            <p className='mb-1 block text-sm font-medium text-gray-700'>Brak przypisanych tagów</p>
          )}
          {showTagToggler && (
            <li>
              <Button
                type='button'
                label={fullList ? 'Zwiń listę' : <ShowMoreTagButton restNum={restNum} />}
                onClick={() => setFullList((p) => !p)}
              />
            </li>
          )}
        </ul>
      )}
    </Card>
  )
}

export default MemberTags
