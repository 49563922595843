import { yupResolver } from '@hookform/resolvers/yup'
import { Button } from 'components/Atoms/Button'
import { Card } from 'components/Atoms/Card'
import { TextField } from 'components/Atoms/TextField'
import { Typography } from 'components/Atoms/Typography'
import { errorRequires } from 'tools/ToastHelpers'
import { path } from 'constants/path'
import { ReactNode, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { IBasicSettingForm } from 'types/form'
import * as yup from 'yup'

type Props = {
  initData: IBasicSettingForm
  inputLabel: string
  cardLabel: string
  inputPlaceholder: string
  onSubmit: (data: any) => void
  onCancelHandler: () => void
  isSending?: boolean
  breadcrumbLink: ReactNode
}

const baseSchema = yup.object({
  name: yup.string().required('Nazwa jest wymagana'),
  id: yup.string(),
})

export const SettingsFormBasic = ({
  onSubmit,
  onCancelHandler,
  initData,
  cardLabel,
  inputLabel,
  inputPlaceholder,
  isSending,
  breadcrumbLink,
}: Props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IBasicSettingForm>({
    defaultValues: initData,
    resolver: yupResolver(baseSchema),
  })

  const initDataLabel = initData.name ? `/ ${initData.name}` : ''
  const forceSend = (e: any) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      handleSubmit(onSubmit, () => errorRequires(errors))()
    }
  }
  return (
    <section>
      <Typography size='xl' weight='semibold'>
        <Link to={path.settings.main}>Ustawienia</Link> / {breadcrumbLink}
      </Typography>
      <Card label={`${cardLabel} ${initDataLabel}`}>
        <form
          onSubmit={handleSubmit(onSubmit, () => errorRequires(errors))}
          className='mx-auto mt-5 max-w-xl'
        >
          <TextField
            label={inputLabel}
            placeholder={inputPlaceholder}
            {...register('name')}
            error={errors.name?.message}
            onKeyDown={forceSend}
          />
          <div className='mt-10 flex justify-center'>
            <Button label='Anuluj' variant='secondary' className='mr-5' onClick={onCancelHandler} />
            <Button type='submit' disabled={isSending} label='Zatwierdź' />
          </div>
        </form>
      </Card>
    </section>
  )
}

export default SettingsFormBasic
