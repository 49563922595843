import { IMutationErrorType } from 'types/httpStatus.enum'

export const notificationErrors: IMutationErrorType = {
  remove: {
    u404: 'Zgłoszenie nie istnieje.',
    u422: 'Zgłoszenie zostało już usunięte.',
  },
  handle: {
    u404: 'Zgłoszenie nie istnieje.',
    u422: 'Zgłoszenie zostało już obsłużone.',
  },
  create: {},
}
