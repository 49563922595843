const roles = {
  admin: 'ROLE_SUPER_ADMIN', // Admin
  iod: 'ROLE_IOD', // IOD
  inspector: 'ROLE_SYSTEM_INSPECTOR', // IS

  regionPresident: 'ROLE_REGION_PRESIDENT', // POK
  regionBoard: 'ROLE_REGION_BOARD', // ZOK

  branchPresident: 'ROLE_BRANCH_PRESIDENT', // POD
  branchBoard: 'ROLE_BRANCH_BOARD', // ZOD

  sectionPresident: 'ROLE_SECTION_PRESIDENT', // POG
}

export const isSAD = (role: string) => role === roles.admin
export const isIOD = (role: string) => role === roles.iod
export const isInspector = (role: string) => role === roles.inspector
export const isPOK = (role: string) => role === roles.regionPresident
export const isZOK = (role: string) => role === roles.regionBoard
export const isPOD = (role: string) => role === roles.branchPresident
export const isZOD = (role: string) => role === roles.branchBoard
export const isPOG = (role: string) => role === roles.sectionPresident

export const adminRoles = [roles.admin, roles.iod, roles.inspector]
export const regionRoles = [roles.regionPresident, roles.regionBoard]
export const branchRoles = [roles.branchPresident, roles.branchBoard]

export const isAllAdmin = (role: string) => adminRoles.includes(role)
export const isAdmins = (role: string) => [roles.admin, roles.iod].includes(role)
export const isAllOK = (roleOK: string) => regionRoles.includes(roleOK)
export const isAllOD = (roleOD: string) => branchRoles.includes(roleOD)

export const verifyRole = {
  isSAD,
  isIOD,
  isInspector,
  isPOK,
  isZOK,
  isPOD,
  isZOD,
  isPOG,
  isAllAdmin,
  isAdmins,
  isAllOK,
  isAllOD,
  adminRoles,
  regionRoles,
  branchRoles,
}
