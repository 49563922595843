import { Table } from 'components/Atoms/Table'
import SettingsActionCell from './SettingsActionCell'
import { useMemo } from 'react'

type Props = {
  baseList: {
    id: string
    name: string
  }[]
  type: string
}

export const SettingsBaseList = ({ baseList, type }: Props) => {
  const columns = useMemo(
    () => [
      {
        Header: 'Nazwa',
        accessor: 'name',
      },
      {
        Header: 'Akcje',
        accessor: 'action',
        Cell: (row: any) => {
          const { subtype, id } = row.row.original
          return <SettingsActionCell type={type} id={id} />
        },
      },
    ],
    [],
  )

  return (
    <div className='mx-auto max-w-2xl'>
      <Table columns={columns} data={baseList} />
    </div>
  )
}

export default SettingsBaseList
