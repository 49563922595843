import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { Button } from 'components/Atoms/Button'
import { Card } from 'components/Atoms/Card'
import { Modal } from 'components/Atoms/Modal'
import { Spinner } from 'components/Atoms/Spinner'
import { Table } from 'components/Atoms/Table'
import { path } from 'constants/path'
import { rodoFileErrors, rodoPasswordErrors } from 'error-data/rodo'
import { getResourceFile, getResourcePassword } from 'fetchers/rodoFetchers'
import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { mutationErrorHandler } from 'tools/errorHandler'
import { fileUpload } from 'tools/fileUpload'
import { findDictValue } from 'tools/findDictValue'
import { setupDate, setupTime } from 'tools/formTools'
import { IDict } from 'types/dictionary'

type Props = {
  criteria: IDict[]
  list: any
  type: 'members' | 'declarations'
}

export const RodoResourceRequests = ({ criteria, list, type }: Props) => {
  const [isSending, setIsSending] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [passwordId, setPasswordId] = useState('')
  const closeModal = () => {
    setIsOpen(false)
    setPasswordId('')
  }

  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const password = useMutation({
    mutationFn: (id: any) => getResourcePassword(id),
    onSuccess: (req) => {
      setPasswordId(req.password)
      setIsSending(false)
    },
    onError: (error: AxiosError) => {
      setIsSending(false)
      console.error(error)
      mutationErrorHandler(error, null, rodoPasswordErrors.get.message)
    },
  })

  const file = useMutation({
    mutationFn: ({ id, filename }: { id: string; filename: string }) =>
      getResourceFile(id, filename),
    onSuccess: (res) => {
      setIsSending(false)
      fileUpload(res)
    },
    onError: (error: AxiosError) => {
      setIsSending(false)
      console.error(error)
      mutationErrorHandler(error, null, rodoFileErrors.get.message)
    },
  })

  const passwordHandler = (id: string) => {
    try {
      setIsOpen(true)
      setIsSending(true)
      password.mutate(id)
    } catch (error) {
      setIsSending(false)
      console.error('Error', error)
      mutationErrorHandler(null, null, rodoPasswordErrors.get.message)
    }
  }

  const fileHadler = ({ id, filename }: { id: string; filename: string }) => {
    try {
      setIsSending(true)
      file.mutate({ id, filename })
    } catch (error) {
      setIsSending(false)
      console.error('Error', error)
      mutationErrorHandler(null, null, rodoFileErrors.get.message)
    }
  }

  const getStatus = (status: string) => {
    switch (status) {
      case 'new':
        return 'Przetwarzanie'
      case 'completed':
        return 'Gotowe do pobrania'
      case 'error':
        return 'wystąpił błąd'
      default:
        return '-'
    }
  }
  const columns = useMemo(
    () => [
      {
        Header: 'Data zapytania',
        accessor: 'createdAt',
        Cell: ({ row }: any) => {
          console.log({ row })
          const date = row.original.createdAt
          return (
            <p>
              {setupDate(date)} {setupTime(date)}
            </p>
          )
        },
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ row }: any) => {
          const status = row.original.status || '-'
          return <p>{getStatus(status)}</p>
        },
      },
      {
        Header: 'Kryteria',
        accessor: 'criteria',
        Cell: ({ row }: any) => {
          const query = row.original.criteria || '-'
          return (
            <ul>
              {query.map((c: any) => (
                <li key={c}>
                  <span>{findDictValue(criteria, c.name)}: </span> {c.value}
                </li>
              ))}
            </ul>
          )
        },
      },
      {
        Header: 'Pobierz hasło',
        accessor: 'password',
        Cell: ({ row }: any) => {
          return (
            <Button
              disabled={row.original.status !== 'completed' || isSending}
              onClick={() => {
                passwordHandler(row.original?.resultsFile?.id)
              }}
              label='Pobierz hasło'
              variant='secondary'
            />
          )
        },
      },
      {
        Header: 'Pobierz plik',
        accessor: 'file',
        Cell: ({ row }: any) => {
          return (
            <Button
              disabled={row.original.status !== 'completed' || isSending}
              onClick={() => {
                fileHadler(row.original?.resultsFile)
              }}
              label='Pobierz'
              variant='secondary'
            />
          )
        },
      },
    ],
    [criteria],
  )
  const formHandler = () => {
    const pathname =
      type === 'members' ? path.rodo_resource.members.ask : path.rodo_resource.declarations.ask
    navigate(pathname)
  }
  return (
    <Card
      label='Lista'
      actionsButton={[
        {
          label: 'Nowe zapytanie',
          handleClick: formHandler,
        },
      ]}
    >
      <Table columns={columns} data={list} maxColumnWidth='8rem' />
      <Modal isOpen={isOpen} handleClose={closeModal}>
        <Card label={password.isPending ? 'Pobieranie hasła' : 'Hasło do pliku'}>
          <div className=''>
            <div className='pb-6 pt-4'>
              {password.isPending ? <Spinner /> : <p className='text-center'>{passwordId}</p>}
            </div>
            <div className='flex justify-center'>
              <Button label={'Zamknij'} onClick={closeModal} />
            </div>
          </div>
        </Card>
      </Modal>
    </Card>
  )
}

export default RodoResourceRequests
