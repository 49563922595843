import { IMutationErrorType } from 'types/httpStatus.enum'

export const unitTermErrors: IMutationErrorType = {
  create: {
    u409: 'Funcja została już przypisana.',
  },
  update: {},
}

export const unitPositionErrors: IMutationErrorType = {
  create: {
    u409: 'Funcja została już przypisana.',
  },
  update: {},
}
