import { publicBaseApiURL } from 'api'
import { endpoint } from 'constants/endpoints'
import { setupFilters } from 'tools/queryHelpers'
import { IFilters } from 'types/form'

export const getRegionsList = async (page: number, count: number, filters?: IFilters[]) => {
  const filterData = setupFilters(filters)
  const res = await publicBaseApiURL.get(endpoint.region.list(page, count, filterData), {})
  return res?.data
}

export const getRegion = async (id: string) => {
  const res = await publicBaseApiURL.get(endpoint.structure.singleRegionById(id), {})
  return res?.data
}

export const getRegionBranches = async (id: string) => {
  const res = await publicBaseApiURL.get(endpoint.structure.branchesListInRegion(id), {})
  return res?.data
}

export const getRegionUsers = async (regionId: string, page: number, count: number) => {
  const data: any = {
    region: [regionId],
  }
  // TODO: CHANGE ENDPOINT
  const res = await publicBaseApiURL.post(endpoint.users.listPaginated(page, count), data, {})
  return res?.data
}

export const createRegion = async (data: any) => {
  const res = await publicBaseApiURL.post(endpoint.region.create, data)
  return res?.data
}

export const updateRegion = async (id: string, data: any) => {
  const res = await publicBaseApiURL.put(endpoint.region.update(id), data)
  return res?.data
}
