type Props = {
  name: string
  parentName?: string
}

export const ArchiveModalContext = ({ name, parentName }: Props) => {
  return (
    <>
      <p className='text-center font-semibold'>{name}</p>
      {parentName && <p className='text-center'>{parentName}</p>}
    </>
  )
}

export default ArchiveModalContext
